import React, { Component } from "react";
import axios from "axios";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Select from "react-select";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import { PLATFORM_CONSTANTS } from "../../shared/utils/platform.constant";

import Header from "../layout/Header";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Flashmessages from "../flashMessagesComp/flashMessages";

import IncludedResearchArticles from "./IncludedResearchArticles";

import Loader from "../common/GlobalLoader";

import "react-toastify/dist/ReactToastify.css";

//const rawData = makeData();

class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allArticles: [],
      multiselectarr: [],
      articleLoading: false,
      errors: {},
      loading: false,
    };
  }

  componentDidMount() {
    this.getCurrentAllArticles();
  }

  _handleOnChange = (e) => {
    const {
      target: { value, name },
    } = e;

    this.setState({
      [name]: value,
    });
  };

  getCurrentAllArticles = async () => {
    try {
      this._handleArticleLoading(true);

      let resdata = await axios.get(
        API_CONSTANTS.ARTICLE.PLATFORM_ALL_INCLUSION_ARTICLE
      );

      if (resdata) {
        let result = resdata.data.data;
        console.log(`all Included Articles resdata`, result);

        if (result.length) {
          this._handleFlashMsg(
            `Included Article fetched successfully.`,
            "success"
          );
        } else {
          this._handleFlashMsg(`No Article Found.`, "info");
        }

        let data = await this.resetSelection(result);

        this.setState({
          allArticles: data,
        });
        this._handleArticleLoading(false);
      }
    } catch (error) {
      console.log("Included Article error is : " + error);
      this._handleArticleLoading(false);
      this._handleFlashMsg("Something went wrong.", "error");
    }
  };

  _checkSelectedAssignArticleType = (checkselected) => {
    if (checkselected === true) {
      return true;
    } else {
      return false;
    }
  };

  handleArticleSelection = (currentDoi, platform) => {
    const { allArticles } = this.state;
    console.log(
      "user selected current article Doi " +
        currentDoi +
        " and platform is : " +
        platform
    );

    let array = [...allArticles]; //make copy of all article

    let foundIndex = array.findIndex((element) => element.doi == currentDoi);

    array[foundIndex].selected = !array[foundIndex].selected; //change the targeted article object key selected set (true/false) acc to prev value

    this.setState({ allArticles: array });
  };

  handleMarkArticleGeneration = (updatedArticle) => {
    const { allArticles } = this.state;

    const array = [...allArticles]; //make copy of all article

    let foundIndex = array.findIndex(
      (element) => element.doi == updatedArticle.doi
    );

    updatedArticle.blurEffect = false;
    updatedArticle.selected = false;

    array[foundIndex] = updatedArticle; 

    this.setState({ allArticles: array });
  };

  _handleArticleLoading = (status) => {
    this.setState({ allArticlesLoading: status });
  };

  _handleFlashMsg = (msg, type) => {
    toast(msg, { type: type });
  };

  handleOverlaySelection = (data) => {
    this.setState({ allArticles: data });
  };

  resetSelection = async (data) => {
    for (let i = 0; i < data.length; i++) {
      data[i].selected = false;
      data[i].blurEffect =
        data[i].status === PLATFORM_CONSTANTS.ARTICLE_STATUS.Rejected
          ? true
          : false;
    }
    return data;
  };

  _handleLoading = (status) => {
    this.setState({ loading: status });
  };

  render() {
    const {
      allArticles,
      allArticlesLoading,
      multiselectarr,
      loading,
    } = this.state;

    return (
      <section className="wrapper">
        <Header />
        {/*} <Sidebar activeStatus={"organisation"} />*/}
        <section className="">
          <Flashmessages />
          <div className="container-fluid">
            <div className="spacetb25">
              <nav className="breadcrumb__list">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <Link to="/organisation">Organisation</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to="/articles">Articles</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Inclusion Articles
                  </li>
                </ol>
              </nav>

              <IncludedResearchArticles
                allArticles={allArticles}
                multiselectarr={multiselectarr}
                allArticlesLoading={allArticlesLoading}
                handleArticleSelection={this.handleArticleSelection}
                handleOverlaySelection={this.handleOverlaySelection}
                handleMarkArticleGeneration={this.handleMarkArticleGeneration}
                _handleFlashMsg={this._handleFlashMsg}
                _handleArticleLoading={this._handleArticleLoading}
                _handleLoading={this._handleLoading}
              />
            </div>
          </div>

          <Loader loadStatus={loading} />
        </section>
      </section>
    );
  }
}

Articles.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors, //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(Articles));
