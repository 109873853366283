const stringTrimmer = (str, len) => {
  let resultStr;
  if (str.length > len) {
    let trimResult = str.substr(0, len);
    resultStr = trimResult + "...";
  } else {
    resultStr = str;
  }
  return resultStr;
};

const introductionFinder = (introSection) => {
  let introCont = "";
  for (let i = 0; i < introSection.length; i++) {
    if (introSection[i].name === "p") {
      if (introSection[i].elements) {
        let intDesc = introSection[i].elements.filter(function(itm) {
          return itm.type == "text";
        });
        if (intDesc[0].text) {
          introCont = introCont
            ? introCont.trim().concat(". ", intDesc[0].text)
            : introCont.trim().concat(intDesc[0].text);
          // break;
        }
      }
    }
  }

  if (introCont) {
    return introCont;
  } else {
    return "";
  }
};

const abstractFinder = (abstractSection) => {
  let abstractCont = "";
  for (let i = 0; i < abstractSection.length; i++) {
    if (abstractSection[i].name === "p") {
      if (abstractSection[i].elements) {
        let absDesc = abstractSection[i].elements.filter(function(itm) {
          return itm.type == "text";
        });
        if (absDesc.length && absDesc[0].text) {
          abstractCont = abstractCont
            ? abstractCont.trim().concat(". ", absDesc[0].text)
            : abstractCont.trim().concat(absDesc[0].text);

          // break;
        }
      }
    }
  }

  if (abstractCont) {
    return abstractCont;
  } else {
    return "NA";
  }
};

module.exports.contributorGroupHelper = (contribGroup) => {
  let info = { author: [], roles: "" }
  for (let i = 0; i < contribGroup.length; i++) {
    if (contribGroup[i].name === "contrib") {
      let finalName = ""
      let roles = []

      if (contribGroup[i].elements[0].name === "name") {
        let givenName = "";
        let surName = "";

        if (contribGroup[i].elements[0].elements[1].elements[0]) {
          givenName = contribGroup[i].elements[0].elements[1].elements[0].text;
        }

        if (contribGroup[i].elements[0].elements[0].elements[0]) {
          surName = contribGroup[i].elements[0].elements[0].elements[0].text;
        }
        finalName = givenName + " " + surName;
        info.author.push(finalName);
      }
      contribGroup[i].elements.forEach((ele) => {
        if (ele.name == "role" && ele.elements[0].type == "text") {
          roles.push(ele.elements[0].text)
        }
      })

      info.roles += finalName
      if (roles.length) {
        info.roles += " - "
        info.roles += roles.join(", ")
      }
      info.roles += " . "
    }
  }

  return info;
};

module.exports.authorInformationHelper = (contribGroup) => {
  let roleInfo = [];
  for (let i = 0; i < contribGroup.length; i++) {
    if (contribGroup[i].name === "contrib") {
      for (let j = 0; j < contribGroup[i].elements.length; j++) {
        if (contribGroup[i].elements[j].name === "role") {
          if (contribGroup[i].elements[j].elements[0].text)
            roleInfo.push(contribGroup[i].elements[j].elements[0].text);
        }
      }
    }
    break;
  }

  let finalAuthorInfoStr = roleInfo.join(",");

  let finalValidStr = stringTrimmer(finalAuthorInfoStr, 1000);
  return finalValidStr;
};

module.exports.pubStatusHelper = (titleGroup) => {
  for (let i = 0; i < titleGroup.length; i++) {
    if (titleGroup[i].name === "fn-group") {
      if (titleGroup[i].elements[0].elements[0].elements[0].text) {
        return titleGroup[i].elements[0].elements[0].elements[0].text;
      } else {
        return "NA";
      }
    }
  }
};

module.exports.introductionHelper = (introSection, abstractSection) => {
  let introSecCont = "";

  introSecCont = introductionFinder(introSection);

  if (introSecCont) {
    console.log("intro found");
  } else if (abstractSection) {
    introSecCont = abstractFinder(abstractSection);
  } else {
    introSecCont = "NA";
  }

  let finalValidStr = stringTrimmer(introSecCont, 1000);
  return finalValidStr;
};

module.exports.descriptionHelper = (abstractSection) => {
  let finalDesc = abstractFinder(abstractSection);
  let finalValidStr = stringTrimmer(finalDesc, 1000);
  return finalValidStr;
};
