import React, { Component } from "react";
import axios from "axios";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import Header from "../layout/Header";

import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Flashmessages from "../flashMessagesComp/flashMessages";

import Loader from "../common/GlobalLoader";

class PublisherZones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      publisherZone: null
    }
  }

  componentDidMount() {
    this.loadPublisherZones()
  }

  loadPublisherZones() {
    this.setState({ loading: true })
    axios
      .get(`${API_CONSTANTS.COUNCIL.PUBLISHER_ZONES}`)
      .then((res) => {
        this.setState({
          publisherZone: res.data,
          loading: false
        })
        this.props.sendFlashMessage(
          "Publisher Zones fetched successfully !!",
          "alert-success"
        )
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000)
      })
      .catch((err) => {
        this.setState({ loading: false })
        this.props.sendFlashMessage(
          "Something went wrong !!",
          "alert-danger"
        )
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000)
      })
  }

  render() {
    return (
      <section class="wrapper">
        <Header />
        <section class="">
          <Flashmessages />
          <div class="container-fluid">
            <div class="spacetb25">
              <div class="row">
                  <div class="col-12">
                      <div class="card card__custom">                                
                          <div class="card-body">
                              <div class="table-box">
                                  <div class="table-responsive">
                                      <table class="table" style={{ minWidth: "700px" }}>
                                          <thead>
                                            <tr>
                                              <th style={{ width: "30%" }}>Publisher</th>
                                              <th style={{ width: "60%" }}>Zone</th>
                                              <th style={{ width: "10%" }}>Edit</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              (this.state.publisherZone || []).map((pz) => (
                                                <tr>                                                
                                                  <td>{ pz.publisher }</td>
                                                  <td>{ pz.zones }</td>
                                                  <td>
                                                    <Link to={`/publisher-zones/${pz.id}/edit`} className="btn btn-primary text-edit">
                                                      <span className="icon-edit"></span>
                                                    </Link>
                                                  </td>
                                                </tr>      
                                              ))
                                            }
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </section>

        <Loader loadStatus={this.state.loading} />
      </section>
    
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors, //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(PublisherZones));
