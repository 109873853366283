import React from "react";

const Pagination = ({
  currentPage,
  indexOfFirstArticle,
  indexOfLastArticle,
  articlePerPage,
  totalArticles,
  paginate,
  totalPages,
  articleLoading,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalArticles / articlePerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div
      className="row"
      style={{
        position: "fixed",
        width: "100%",
        bottom: 0,
        backgroundColor: "gainsboro",
      }}
    >
      <div className="col-12">
        <div className="text-center">
          <nav className="">
            <ul className="pagination d-flex justify-content-between">
              <li
                className={`page-item ${(indexOfFirstArticle === 0 ||
                  articleLoading) &&
                  "disabled"}`}
              >
                <a
                  className="page-link"
                  href="!#"
                  tabIndex="-1"
                  onClick={(e) => {
                    paginate(e, currentPage - 1);
                  }}
                >
                  {/* <i className="icon-angle-left"></i> */}
                  Previous
                </a>
              </li>
              <li className={`page-item`}>
                <a
                  className="page-link"
                  href="javascript:void(0)"
                  tabIndex="-1"
                >
                  {currentPage}/{totalPages}
                </a>
              </li>
              {/* {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`page-item ${currentPage === number && "active"}`}
                >
                  <a
                    onClick={(e) => {
                      paginate(e, number);
                    }}
                    href="!#"
                    className="page-link"
                  >
                    {number}
                  </a>
                </li>
              ))} */}
              <li
                className={`page-item ${(currentPage === totalPages ||
                  articleLoading) &&
                  "disabled"}`}
              >
                <a
                  className="page-link"
                  href="!#"
                  onClick={(e) => {
                    paginate(e, currentPage + 1);
                  }}
                >
                  {/* <i className="icon-angle-right"></i> */}
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
