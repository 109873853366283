import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

import { API_CONSTANTS } from "../../shared/constants/api.constants";

const acceptedFileTypes =
  "images/x-png,image/png,image/jpg,image/jpeg,image/gif";

function DropZoneManager({ history, ...props }) {
  const [loading, setLoading] = useState(false);

  const { fileId, fileUrl, _handleFileUrl } = props;

  const handleOnDropPicture = async (files, rejectedFiles) => {
    let file = files[0];
    console.log("accepted file are : ", file);
    try {
      setLoading(true);
      var fileExtension = file.name.split(".").pop();
      let todaydt = moment().format("X");

      let updData = {
        fileId: fileId,
        fileName: `${todaydt}.${fileExtension}`,
      };

      let resdataA = await Axios.post(
        `${API_CONSTANTS.ARTICLE.UPLOAD}`,
        updData
      );

      if (resdataA) {
        console.log("resdataA", resdataA);
        let presignedURL = resdataA.data.data.presignedURL;
        let originalUrl = resdataA.data.data.originalUrl;

        const Adapter = Axios.create();

        let resdataB = await Adapter.put(presignedURL, file, {
          headers: {
            "Content-Type": file.type,
          },
        });

        if (resdataB) {
          console.log("resdataB", resdataB.data);
          _handleFileUrl(originalUrl);
          setLoading(false);
          //toast("Cover Uploaded Successfully", { type: "success" });
        }
      } else {
        setLoading(false);
        toast("Something went wrong", { type: "error" });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast("Something went wrong", { type: "error" });
    }
  };

  return (
    <div>
      {loading ? (
        "Uploading..."
      ) : (
        <Dropzone
          onDrop={handleOnDropPicture}
          multiple={false}
          accept={acceptedFileTypes}
          //  maxSize={itemImageMaxSize}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <a
              {...getRootProps({
                className: "dropzone",
                onDrop: (event) => event.stopPropagation(),
              })}
            >
              <div class="mg-b-5">
                <input {...getInputProps()} />

                <button class="btn">
                  {fileUrl && <img src={fileUrl} alt="cover" width="100" />}
                  <i class="fa fa-upload"></i> Upload Cover{" "}
                </button>
              </div>
            </a>
          )}
        </Dropzone>
      )}
    </div>
  );
}

DropZoneManager.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(withRouter(DropZoneManager));
