import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Select from "react-select";

class EditOrganisationUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorgloading: false,
      chargesOptions: [],
      charges: [],
      showRates: false,
      showProductChargesSelect: true,
      ratesData: {
        productId: "",
        isoCountryCode: "",
        productName: "",
        currencyCode: "",
        term: "",
        rate: "",
        productDescription: "",
        productImage: "",
        taxRate: "",
        setDefault: false,
        taxInclusive: false,
        autoCollectTax: false,
        charges: [],
        tempCharges: [],
        displayRate: "",
        diasporaPlan: false,
      },
      ratesList: [],
      councilProductImage: null,
    };

    this.onRateChange = this.onRateChange.bind(this);
    this.onProductImageChange = this.onProductImageChange.bind(this);
    this.onRatesSubmit = this.onRatesSubmit.bind(this);
  }

  componentDidMount() {
    this.loadCharges();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.premiumPackageId !== prevProps.premiumPackageId) {
      this.getRatesByCouncilId();
    }
  }

  loadCharges() {
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_CHARGES}`)
      .then((res) => {
        if (res.data) {
          let charges = this.getValidChargesForDropdown(res.data);
          this.setState({ chargesOptions: charges, charges: res.data });
        }
      })
      .catch((err) => {
        console.log("CHARGES API error: " + err);
      });
  }

  getValidChargesForDropdown(charges, ratesChargeIds = []) {
    if (!(charges || []).length) return [];

    charges = charges.filter(
      (d) =>
        d.chargeType != 1 &&
        (d.chargeCode || "").length != 0 &&
        !ratesChargeIds.includes(d.id)
    );
    return charges.map((d) => {
      return { label: d.chargeCode, value: d.id };
    });
  }

  getPublisherZonesSelectOptions(data) {
    try {
      return (data || []).map((d) => ({
        label: d.publisher,
        value: d.publisher,
      }));
    } catch (error) {
      return [];
    }
  }

  onRateChange(e) {
    const state = this.state.ratesData;
    state[e.target.name] = e.target.value;
    this.setState({ ratesData: state });
  }

  onProductImageChange(e) {
    let productImageFormData = new FormData();
    productImageFormData.append("files", e.target.files[0]);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${API_CONSTANTS.COUNCIL.UPLOADS_MULTIPLE}`, productImageFormData)
      .then((res) => {
        if (res && res.data && res.data.length) {
          this.setState({ councilProductImage: res.data[0] });
        }
      })
      .catch((err) => {
        this.props.sendFlashMessage(
          "Something went wrong while uploading image!!",
          "alert-danger"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      });
  }

  getRatesByCouncilId = () => {
    let id = this.props.match.params.id;
    this.setState({ editorgloading: true });
    axios
      .get(
        `${API_CONSTANTS.COUNCIL.GET_PREMIUM_PACKAGE_RATES_BY_ID}/${id}?premiumPackageId=${this.props.premiumPackageId}`
      )
      .then((res) => {
        this.setState({ editorgloading: false });
        this.setState({ ratesList: res.data });
      })
      .catch((err) => {
        this.setState({ editorgloading: false });
        console.log("error ratesList is : " + err);
      });
  };
  fileInputRef = React.createRef();

  resetRates = (callback) => {
    this.setState(
      {
        ratesData: {
          productId: "",
          isoCountryCode: "",
          productName: "",
          currencyCode: "",
          term: "",
          rate: "",
          productDescription: "",
          productImage: "",
          taxRate: "",
          setDefault: false,
          taxInclusive: false,
          autoCollectTax: false,
          charges: [],
          selectCharges: [],
          tempCharges: [],
          displayRate: "",
          diasporaPlan: false,
        },
        councilProductImage: null,
      },
      () => callback && callback()
    );
    this.fileInputRef.current.value = null;
  };
  onRatesSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ editorgloading: true });

    let ratesData = this.state.ratesData;
    let councilId = this.props.match.params.id;

    let data = {
      councilId,
      productId: ratesData.productId,
      isoCountryCodes: ratesData.isoCountryCode,
      productName: ratesData.productName,
      currencyCode: ratesData.currencyCode,
      term: Number(ratesData.term),
      rate: ratesData.rate,
      productDescription: ratesData.productDescription,
      productImage: this.state.councilProductImage,
      taxRate: ratesData.taxRate,
      defaultCouncilProduct: ratesData.setDefault,
      taxInclusive: ratesData.taxInclusive,
      autoCollectTax: ratesData.autoCollectTax,
      charges: ratesData.tempCharges,
      displayRate: ratesData.displayRate,
      diasporaPlan: ratesData.diasporaPlan,
      premiumPackageId: this.props.premiumPackageId,
    };
    if (ratesData.id) {
      // EDIT
      axios
        .put(
          `${API_CONSTANTS.COUNCIL.EDIT_COUNCIL_RATES}/${ratesData.id}`,
          data
        )
        .then((res) => {
          this.props.sendFlashMessage(
            "Rates updated successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ editorgloading: false });
          this.getRatesByCouncilId();
        })
        .catch((err) => {
          this.setState({ editorgloading: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage(
            "Something went wrong !!",
            "alert-danger"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        });
    } else {
      // NEW
      axios
        .post(`${API_CONSTANTS.COUNCIL.CREATE_COUNCIL_RATES}`, data)
        .then((res) => {
          this.props.sendFlashMessage(
            "Rates created successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ editorgloading: false });
          this.getRatesByCouncilId();
          this.resetRates();
          this.editCouncilRate(null, null, res.data);
        })
        .catch((err) => {
          this.setState({ editorgloading: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage(
            "Something went wrong !!",
            "alert-danger"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        });
    }
  }

  handleRatesCheckboxClick = (e) => {
    const state = this.state.ratesData;
    state[e.target.name] = !state[e.target.name];
    this.setState({ ratesData: state });
  };

  removeProductCharge = (rateCharges, chargeId, productId) => {
    axios
      .post(
        `${API_CONSTANTS.COUNCIL.DELETE_PRODUCT_CHARGE(productId, chargeId)}`
      )
      .then((res) => {
        let charges = this.getValidChargesForDropdown(
          this.state.charges,
          rateCharges
        );
        this.setState({ chargesOptions: charges });
        this.props.sendFlashMessage(
          "Product charge deleted successfully !!",
          "alert-success"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      })
      .catch((err) => {
        console.log("REMOVE PRODUCT CHARGE API error: " + err);
      });
  };

  onProductChargeDeleteClick = (e, chargeId, productId) => {
    let rateCharges = this.state.ratesData.tempCharges;

    const index = rateCharges.indexOf(chargeId);
    if (index > -1) rateCharges.splice(index, 1);

    this.removeProductCharge(rateCharges, chargeId, productId);
  };

  editCouncilRate = (e, rateId, rateObj = null) => {
    this.resetRates(() => {
      let rate =
        rateObj || this.state.ratesList.find((rate) => rate.id == rateId);
      if (rate && rate.id) {
        let data = {
          id: rate.id,
          councilId: rate.councilId,
          productId: rate.productId,
          isoCountryCode: rate.isoCountryCodes,
          productName: rate.productName,
          currencyCode: rate.currencyCode,
          term: rate.term,
          rate: rate.rate,
          productDescription: rate.productDescription,
          productImage: rate.productImage,
          taxRate: rate.taxRate,
          setDefault: rate.defaultCouncilProduct,
          taxInclusive: rate.taxInclusive,
          autoCollectTax: rate.autoCollectTax,
          charges: rate.charges,
          selectCharges: [],
          tempCharges: JSON.parse(JSON.stringify(rate.charges)),
          displayRate: rate.displayRate,
          diasporaPlan: rate.diasporaPlan,
          premiumPackageId: this.props.premiumPackageId,
        };

        let charges = this.getValidChargesForDropdown(
          this.state.charges,
          rate.charges
        );

        this.setState({
          ratesData: data,
          councilProductImage: rate.productImage,
          chargesOptions: charges,
        });
      }
    });
  };

  addNewRates = () => {
    this.resetRates();
  };

  deleteCouncilRate = (e, rateId) => {
    axios
      .get(`${API_CONSTANTS.COUNCIL.DELETE_COUNCIL_RATES(rateId)}`)
      .then((res) => {
        this.props.sendFlashMessage(
          "Rates deleted successfully !!",
          "alert-success"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
        this.setState({ editorgloading: false });
        this.getRatesByCouncilId();
      })
      .catch((err) => {
        this.setState({ editorgloading: false });
        console.log("error is : " + err);
        this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      });
  };

  handleChargesChange = (e) => {
    const state = this.state.ratesData;
    state["tempCharges"] = [
      ...new Set(state["charges"].concat((e || []).map((x) => x["value"]))),
    ];
    state["selectCharges"] = e;
    this.setState({ ratesData: state });
  };

  render() {
    const {
      ratesData,
      ratesList,
      councilProductImage,
      showProductChargesSelect,
      charges,
    } = this.state;

    return (
      <>
        <div
          class="modal fade new__modal rate-currenices-modal"
          id="rateCurrenicesPackageModal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-xl our-custom-modal" role="document">
            <div class="modal-content">
              <div class="custom-modal-header">
                <div class="modal-header">
                  <h5 class="modal-title">Rate Currenices</h5>
                  <button
                    onClick={(e) => {
                      this.resetRates();
                    }}
                    type="button"
                    class="close closed__btn"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="icon-cross"></span>
                  </button>
                </div>
              </div>
              <div class="modal-body">
                <div className={`show-advertisement pd-t-20 mg-t-20`}>
                  <h5 class="mg-b-20 text-center">
                    <strong>Rates Currencies</strong>
                  </h5>
                  <form onSubmit={this.onRatesSubmit}>
                    <ul class="list-advertisement list-adds">
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">Product Id</label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={ratesData.productId}
                              name="productId"
                              onChange={this.onRateChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              ISO_Country_Code
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={ratesData.isoCountryCode}
                              name="isoCountryCode"
                              onChange={this.onRateChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Product Name
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={ratesData.productName}
                              name="productName"
                              onChange={this.onRateChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                            &nbsp;
                          </div>
                          <div class="col-sm-6">
                            <label class="custom-label-text">
                              <input
                                type="checkbox"
                                checked={ratesData.setDefault}
                                name="setDefault"
                                onChange={this.handleRatesCheckboxClick}
                              />
                              Is Default
                            </label>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                            &nbsp;
                          </div>
                          <div class="col-sm-6">
                            <label class="custom-label-text">
                              <input
                                type="checkbox"
                                checked={ratesData.autoCollectTax}
                                name="autoCollectTax"
                                onChange={this.handleRatesCheckboxClick}
                              />
                              Auto Collect Tax
                            </label>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Currency Code
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={ratesData.currencyCode}
                              name="currencyCode"
                              onChange={this.onRateChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">Term</label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={ratesData.term}
                              name="term"
                              onChange={this.onRateChange}
                            />
                          </div>
                        </div>
                      </li>
                      {true && (
                        <li>
                          <div class="row">
                            <div class="col-sm-4 text-left text-sm-right">
                              <label class="text-label pd-t-5">Rate</label>
                            </div>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                value={ratesData.rate}
                                name="rate"
                                onChange={this.onRateChange}
                              />
                            </div>
                          </div>
                        </li>
                      )}
                      {true && (
                        <li>
                          <div class="row">
                            <div class="col-sm-4 text-left text-sm-right">
                              <label class="text-label pd-t-5">
                                Product Description
                              </label>
                            </div>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                value={ratesData.productDescription}
                                name="productDescription"
                                onChange={this.onRateChange}
                              />
                            </div>
                          </div>
                        </li>
                      )}
                      {true && (
                        <li>
                          <div class="row">
                            <div class="col-sm-4 text-left text-sm-right">
                              <label class="text-label pd-t-5">
                                Product Image
                              </label>
                            </div>
                            <div class="col-sm-6">
                              <input
                                type="file"
                                id="exampleFormControlFile1"
                                name="newreconciliationfile"
                                onChange={this.onProductImageChange}
                                ref={this.fileInputRef}
                              />
                              {councilProductImage && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    height: "50%",
                                    width: "50%",
                                    objectFit: "cover",
                                  }}
                                >
                                  <img src={councilProductImage} />
                                </div>
                              )}
                            </div>
                          </div>
                        </li>
                      )}
                      {true && (
                        <li>
                          <div class="row">
                            <div class="col-sm-4 text-left text-sm-right">
                              <label class="text-label pd-t-5">Tax Rate</label>
                            </div>
                            <div class="col-sm-6">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                value={ratesData.taxRate}
                                name="taxRate"
                                onChange={this.onRateChange}
                              />
                            </div>
                          </div>
                        </li>
                      )}
                      {true && (
                        <li>
                          <div class="row">
                            <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                              &nbsp;
                            </div>
                            <div class="col-sm-6">
                              <label class="custom-label-text">
                                <input
                                  type="checkbox"
                                  checked={ratesData.taxInclusive}
                                  name="taxInclusive"
                                  onChange={this.handleRatesCheckboxClick}
                                />
                                Tax Inclusive
                              </label>
                            </div>
                          </div>
                        </li>
                      )}
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Display Rate
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={ratesData.displayRate}
                              name="displayRate"
                              onChange={this.onRateChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right d-none d-sm-block">
                            &nbsp;
                          </div>
                          <div class="col-sm-6">
                            <label class="custom-label-text">
                              <input
                                type="checkbox"
                                checked={ratesData.diasporaPlan}
                                name="diasporaPlan"
                                onChange={this.handleRatesCheckboxClick}
                              />
                              Diaspora Plan
                            </label>
                          </div>
                        </div>
                      </li>
                      {showProductChargesSelect && (
                        <li>
                          <div class="row">
                            <div class="col-sm-4 text-left text-sm-right">
                              <label class="text-label pd-t-5">Charges</label>
                            </div>
                            <div class="col-sm-6">
                              <Select
                                value={ratesData.selectCharges}
                                onChange={this.handleChargesChange}
                                options={this.state.chargesOptions}
                                isMulti={true}
                              />
                            </div>
                          </div>
                        </li>
                      )}
                      {ratesData && ratesData.id && (
                        <>
                          <li>
                            <div class="table-box">
                              <div class="table-responsive">
                                <table
                                  class="table"
                                  style={{ minWidth: "900px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th style={{ width: "30%" }}>
                                        Charge Code
                                      </th>
                                      <th style={{ width: "30%" }}>
                                        Charge Description
                                      </th>
                                      <th style={{ width: "30%" }}>
                                        Charge Percentage
                                      </th>
                                      <th
                                        class="text-center"
                                        style={{ width: "10%" }}
                                      >
                                        Delete
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(ratesData.tempCharges || []).map(
                                      (id, i) => {
                                        let charge = charges.find(
                                          (c) => c.id == id
                                        );

                                        return (
                                          <tr key={i}>
                                            <td>{charge.chargeCode}</td>
                                            <td>{charge.chargeDescription}</td>
                                            <td>{charge.chargePercentage}</td>
                                            <td class="text-center">
                                              <a
                                                href="javascript:void(0)"
                                                class="btn btn-primary text-edit"
                                                onClick={(e) =>
                                                  this.onProductChargeDeleteClick(
                                                    e,
                                                    charge.id,
                                                    ratesData.id
                                                  )
                                                }
                                              >
                                                <span class="icon-cross clos-icon"></span>
                                              </a>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </li>
                        </>
                      )}

                      <li>
                        <div className="text-center">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            onClick={this.addNewRates}
                          >
                            Add New Rates
                          </button>
                          <button type="submit" class="btn btn-secondary ml-1">
                            Submit Rates
                          </button>
                        </div>
                      </li>
                    </ul>
                  </form>

                  <div class="table-box">
                    <div class="table-responsive">
                      <table class="table" style={{ minWidth: "900px" }}>
                        <thead>
                          <tr>
                            <th style={{ width: "12%" }}>Product ID</th>
                            <th style={{ width: "12%" }}>ISO Country Code</th>
                            <th style={{ width: "12%" }}>Product Name</th>
                            <th style={{ width: "13%" }}>isDefault</th>
                            <th style={{ width: "12%" }}>Currency Code</th>
                            <th style={{ width: "12%" }}>Term</th>
                            <th class="text-center" style={{ width: "10%" }}>
                              Edit
                            </th>
                            <th class="text-center" style={{ width: "10%" }}>
                              Del
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {ratesList.map((rate, i) => (
                            <tr key={i}>
                              <td>{rate.productId}</td>
                              <td>{rate.isoCountryCodes}</td>
                              <td>{rate.productName}</td>
                              <td>{rate.defaultCouncilProduct.toString()}</td>
                              <td>{rate.currencyCode}</td>
                              <td>{rate.term}</td>
                              <td class="text-center">
                                <a
                                  href="javascript:void(0)"
                                  class="btn btn-primary text-edit"
                                  onClick={(e) =>
                                    this.editCouncilRate(e, rate.id)
                                  }
                                >
                                  <span class="icon-edit"></span>
                                </a>
                              </td>
                              <td class="text-center">
                                <a
                                  href="javascript:void(0)"
                                  class="btn btn-primary text-edit"
                                  onClick={(e) =>
                                    this.deleteCouncilRate(e, rate.id)
                                  }
                                >
                                  <span class="icon-cross"></span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

EditOrganisationUser.propTypes = {
  auth: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
  // packages: PropTypes.array.isRequired,
  premiumPackageId: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(EditOrganisationUser));
