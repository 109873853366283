import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";

import DefaultBackgroundbranding from "./img/patrick-tomasso-gMes5dNykus-unsplash.jpg";
import Flashmessages from "../flashMessagesComp/flashMessages";
import Loader from "../common/GlobalLoader";

import "./css/Loginstyl.css";
class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      Backgroundbranding: "",
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    //if isAuthenticated is TRUE so redirect the user to /dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/organisation");
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      username: this.state.username,
      password: this.state.password,
    };
    if (userData.username === "") {
      alert("Username Field cannot be empty!!");
    } else if (userData.password === "") {
      alert("Password Field cannot be empty!!");
    }
    //  console.log(user);

    if (userData.username && userData.password) {
      this.props.loginUser(userData, this.props.history); //here we call the loginUser action

      // alert("Hit Login Api Soon !!")
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors, Backgroundbranding } = this.state; //the errors state is set in componentWillReceiveProps from the redux error reducer at store and used in this Login Component

    const { userloginloading } = this.props.auth;

    let bgbrand = Backgroundbranding
      ? Backgroundbranding
      : DefaultBackgroundbranding;
    return (
      <section className="wrapper login__page">
        <div className="login__top">
          <div className="container-fluid">
            <a href="javascript:void(0)" className="login__heading">
              ENGAGEMENT PLATFORM
            </a>
          </div>
        </div>

        <div
          className="login__container"
          style={{
            // backgroundImage: `url(${bgbrand})`,
            backgroundColor: "#496269",
          }}
        >
          <Flashmessages />

          <div className="login__box">
            <div className="login__box--inner">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="input-group mb-2 mr-sm-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="User Name"
                      name="username"
                      type="text"
                      value={this.state.username}
                      onChange={this.onChange}
                      error={errors.username}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group mb-2 mr-sm-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="icon-key"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      type="password"
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                <div className="text-center pd-t-20">
                  {userloginloading ? (
                    <button
                      disabled
                      type="button"
                      className="btn btn-primary with-icons-btn dark__blue"
                    >
                      <span
                        className="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <input
                      disabled={!this.state.username || !this.state.password}
                      type="submit"
                      value="Log in"
                      className="btn btn-primary with-icons-btn dark__blue"
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <Loader loadStatus={userloginloading} />
      </section>
    );
  }
}

Login.propTypes = {
  //map the property of Login component via propTypes
  loginUser: PropTypes.func.isRequired, //loginUser is an action but also the property of this component
  auth: PropTypes.object.isRequired, //auth is also property of this component
  errors: PropTypes.object.isRequired, //errors is also property of this component
};

const mapStateToProps = (state) => ({
  //here map the auth (state.auth) from the root reducer (from store.js) where in combineReducer auth : authReducer similarly for errors
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
