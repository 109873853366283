import React, { Component } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import jwt_decode from "jwt-decode";
import LocalStorageService from "./shared/utils/LocalStorageService";

import { setCurrentUser, logoutUser } from "./actions/authActions";

import { Provider } from "react-redux";
import store from "./store";

import PrivateRoute from "./components/common/PrivateRoute";

import Component404 from "./components/errors/Component404";
import Login from "./components/auth/Login";

import Organisation from "./components/organisation/Organisation";

import AddCouncilUser from "./components/add-council-user/AddCouncilUser";

import AddEditPublisherZones from "./components/publisher-zones/AddEditPublisherZones";

import EditOrganisationUser from "./components/edit-organisation-user/EditOrganisationUser";

import Addgroupsubgroup from "./components/add-group-subgroup/Addgroupsubgroup";

import AssignLicences from "./components/assign-licence/AssignLicences";

import BulkuploadReconciliation from "./components/bulk-upload-reconciliation/BulkuploadReconciliation";

import Articles from "./components/articles/Articles";

import InclusionArticles from "./components/inclusion-articles/Articles";

import PublisherZones from "./components/publisher-zones/PublisherZones";

import VerificationForm from "./components/verification-form/VerificationForm";

import "./App.css";
import Charges from "./components/charges/Charges";

// LocalstorageService
const localStorageService = LocalStorageService.getService();

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers["X-Authorization"] = token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    // const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      !window.location.pathname.endsWith("/login")
    ) {
      //getting 401 and if route is not /login
      // router.push('/login');
      //to route to login in case of 401 except when on login page
      console.log(
        "unauthenticated at pathname " +
          window.location.pathname +
          " routing to login"
      );
      store.dispatch(logoutUser()); //remove token with set isAuthenticate=false in redux store
      //  window.location.href = "/login";
      return Promise.reject(error);
    }

    // if (error.response.status === 401 && !originalRequest._retry) {

    //     originalRequest._retry = true;
    //     const refreshToken = localStorageService.getRefreshToken();
    //     return axios.post('/auth/token',
    //         {
    //             "refresh_token": refreshToken
    //         })
    //         .then(res => {
    //             if (res.status === 201) {
    //                 localStorageService.setToken(res.data);
    //                 axios.defaults.headers.common['X-Authorization'] =  localStorageService.getAccessToken();
    //                 return axios(originalRequest);
    //             }
    //         })
    // }
    return Promise.reject(error);
  }
);

// Check for token for every single page request
//find jwttoken in chrome developer tool in Local Storage where key is jwtToken and value Bearer-----so on
if (localStorage.jwtToken) {
  // Set auth token header auth
  // var token = localStorage.getItem("jwtToken");
  // setAuthToken(token);

  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    //decoded.exp => in object its has exp value if this exp value is less then the currentTime so we logout the user and redirect user to the /login
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "/login";
  }
}

var NotFound = ({ location }) => (
  <Component404 pathnotfound={location.pathname} />
);

class App extends Component {
  constructor(props) {
    super();

    this.state = {
      isFull: false,
    };
  }

  goFull = () => {
    this.setState({ isFull: true });
  };

  render() {
    return (
      <React.Fragment>
        <Provider store={store}>
          <Router>
            <div className="App">
              <ToastContainer />
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                {/*} <PrivateRoute exact path="/dashboard" component={Dashboard} />*/}
                <PrivateRoute
                  exact
                  path="/organisation"
                  component={Organisation}
                />
                <PrivateRoute
                  exact
                  path="/add-council-user"
                  component={AddCouncilUser}
                />
                <PrivateRoute exact path="/charges" component={Charges} />
                <PrivateRoute
                  exact
                  path="/publisher-zones"
                  component={PublisherZones}
                />
                <PrivateRoute
                  exact
                  path="/publisher-zones/add"
                  component={AddEditPublisherZones}
                  data={{ add: true }}
                />
                <PrivateRoute
                  exact
                  path="/publisher-zones/:id/edit"
                  component={AddEditPublisherZones}
                  data={{ add: false }}
                />
                <PrivateRoute
                  exact
                  path="/verification-form"
                  component={VerificationForm}
                />
                <PrivateRoute
                  exact
                  path="/edit-organisation/:id"
                  component={EditOrganisationUser}
                />
                <PrivateRoute
                  exact
                  path="/add-group-subgroup/:id"
                  component={Addgroupsubgroup}
                />
                <PrivateRoute
                  exact
                  path="/assign-licences/:id"
                  component={AssignLicences}
                />
                <PrivateRoute
                  exact
                  path="/bulk-upload-reconciliation"
                  component={BulkuploadReconciliation}
                />

                <PrivateRoute exact path="/articles" component={Articles} />
                <PrivateRoute
                  exact
                  path="/inclusion-articles"
                  component={InclusionArticles}
                />
                <Route component={NotFound} />
              </Switch>
            </div>
          </Router>
        </Provider>
      </React.Fragment>
    );
  }
}

export default App;
