import React, { Component } from "react";
//import axios from "axios";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import $ from "jquery";

import "./css/myguestnav.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser(); //here we call the logoutUser action
  }

  handleSidebarToggle = () => {
    $(function() {
      $(".navBox").toggleClass("opened");
      $(".tab-overlay").toggleClass("show-overlay");
      $("body").toggleClass("tab-hidden");
    });
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;
    //const cartstockdtall = this.state.cartstockdtall;

    //console.log(testpurpose);

    const authLinks = (
      <header className="header">
        <div className="logo">
          <a href="javascript:void(0)" className="logo__brand">
            <img src="/assets/images/logo.png" alt="" />
            <span className="brand__text">WCEA PLATFORM</span>
          </a>
        </div>
        <div className="header__inner">
          <a
            href="javascript:void(0)"
            className="mob-tab"
            onClick={this.handleSidebarToggle}
          >
            <span className="icon-bar"></span>
          </a>

          <div className="d-none d-lg-inline-flex add__organize">
            <Link
              to="/add-council-user"
              className="btn btn-primary with-icons-btn"
            >
              <span className="icon-plus-fill"></span>Add Organisation
            </Link>
            <Link to="/articles" className="btn btn-primary ml-2">
              <span className="icon-plus-fill"></span> Add Content
            </Link>
            <Link to="/inclusion-articles" className="btn btn-primary ml-2">
              <span className="icon-plus-fill"></span> List Content
            </Link>
            {
              window.location.pathname == "/publisher-zones" ? (
                <Link to="/publisher-zones/add" className="btn btn-primary ml-2">
                  <span className="icon-plus-fill"></span> Add Publisher Zones
                </Link>
              ) : (
                <Link to="/publisher-zones" className="btn btn-primary ml-2">
                  <span className="icon-plus-fill"></span> Publisher Zones
                </Link>
              )
            }
            <Link to="/verification-form" className="btn btn-primary ml-2">
              <span className="icon-plus-fill"></span> Verification Form
            </Link>
          </div>

          <div className="dropdown profile__dropdown">
            <a
              className="btn dropdown-toggle"
              href="javascript:void(0)"
              role="button"
              id="profile-link"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="icon-user-fill"></span>
            </a>
            <div
              className="dropdown-menu dropdown__custom"
              aria-labelledby="profile-link"
            >
              <a
                className="dropdown-item text-success"
                href="javascript:void(0)"
              >
                {user.name ? (
                  <strong>{user.name}</strong>
                ) : (
                  <strong>WCEA ADMIN</strong>
                )}
              </a>
              <a
                className="dropdown-item"
                href="javascript:void(0)"
                onClick={this.onLogoutClick.bind(this)}
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      </header>
    );

    const guestLinks = <div className="myguestnav"></div>;

    return isAuthenticated ? authLinks : guestLinks;
  }
}

Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logoutUser,
})(Header);
