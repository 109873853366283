import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import Flashmessages from "../flashMessagesComp/flashMessages";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Loader from "../common/GlobalLoader";
import Select from "react-select";

class VerificationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationFormObj: {
        id: "",
        formName: "",
        formScript: "",
        formMessage: ""
      },
      errors: {},
      ulogo: "",
      ubackgroundImg: "",
      ucountry: "",
      allcountryarrobjs: null,
      verificationFormLoader: false,
      publisherZonesSelectOptions: [],
      defaultPaymentGatewayOptions: [
        { label: 'Stripe', value: 1 },
        { label: 'Remita', value: 2 }
      ],
      chargesOptions: [],
      charges: [],
      verificationFormOptions: [
        { label: 'verificationForm1', value: 1 },
        { label: 'verificationForm2', value: 2 }
      ],
      leadFieldOptions: [
        { label: 'leadField1', value: 1 },
        { label: 'leadField2', value: 2 }
      ],
      displayTypeOptions: [
        { label: 'Display Always (show previous value)', value: 1 },
        { label: 'Display Always (show no value)', value: 2 },
        { label: 'Display id NULL', value: 3 },
      ],
      collectAppPaymentOptions: [
        { label: 'Hard Stop', value: 0 },
        { label: 'Launch Payment Form', value: 1 },
        { label: 'Launch LMS with parameters according to the specs', value: 2 },
      ],
      showRates: false,
      showGateways: false,
      showProductChargesSelect: true,
      ratesData: {
          productId: '', 
          isoCountryCode: '',
          productName: '',
          currencyCode: '',
          term: '',
          rate: '',
          productDescription: '',
          productImage: '',
          taxRate: '',
          setDefault: false,
          taxInclusive: false,
          autoCollectTax: false,
          charges: [],
          tempCharges: []
      },
      verificationFormFieldObj: this.newVerificationFormFieldObj(),
      activeVerificationForm: 0,
      verificationFormList: [],
      verificationFormFieldList: [],
      verificationFormFieldByForm: {},
      leadFieldList: [],
      councilProductImage: null,
      inputRef: React.createRef()
    };

    this.onChange = this.onChange.bind(this);
    this.onRateChange = this.onRateChange.bind(this);
    this.onProductImageChange = this.onProductImageChange.bind(this);
    this.onVerificationFormFieldChange = this.onVerificationFormFieldChange.bind(this);
    this.onChangeImg = this.onChangeImg.bind(this);
    this.onVerificationFormSubmit = this.onVerificationFormSubmit.bind(this);
    this.onVerificationFormFieldsSubmit = this.onVerificationFormFieldsSubmit.bind(this);
  }

  componentDidMount() {
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_ALL_COUNTRIES}`)
      .then((res) => {
        this.setState({ allcountryarrobjs: res.data });
      })
      .catch((err) => {
        console.log("all country error is : " + err);
      });

    this.getVerificationFormList();
    this.getLeadFieldList();


    this.getRatesByCouncilId()
    // this.getGatewaysByCouncilId()
    this.loadPublisherZones()
    this.loadCharges()
  }

  newVerificationFormFieldObj = () => {
    return {
      verificationFormId: "",
      leadFieldId: "",
      displayType: "",
      controlId: "",
      fieldLabel: "",
      id: ""
    }
  }
  
  loadCharges() {
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_CHARGES}`)
      .then((res) => {
        if (res.data) {
          let charges = this.getValidChargesForDropdown(res.data)
          this.setState({ chargesOptions: charges, charges: res.data })
        }
      })
      .catch((err) => {
        console.log("CHARGES API error: " + err);
      });
  }

  loadPublisherZones() {
    axios
      .get(`${API_CONSTANTS.COUNCIL.PUBLISHER_ZONES}`)
      .then((res) => {
        this.setState({ publisherZonesSelectOptions: this.getPublisherZonesSelectOptions(res.data) })
      })
      .catch((err) => {
        console.log("PUBLISHER_ZONES API error: " + err);
      });
  }

  getValidChargesForDropdown(charges, ratesChargeIds = []) {
    if (!(charges || []).length) return []

    charges = charges.filter((d) => d.chargeType != 1 && (d.chargeCode || "").length != 0 && !ratesChargeIds.includes(d.id))
    return charges.map((d) => { return { label: d.chargeCode, value: d.id } })
  }

  getPublisherZonesSelectOptions(data) {
    try {
      return (data || []).map((d) => ({
          label: d.publisher, value: d.publisher
      }))
    } catch (error) {
      return []
    }
  }

  onChange(e) {
    const state = this.state.verificationFormObj;
    state[e.target.name] = e.target.value;
    this.setState({ verificationFormObj: state });
  }

  onRateChange(e) {
    const state = this.state.ratesData;
    state[e.target.name] = e.target.value;
    this.setState({ ratesData: state });
  }

  onProductImageChange(e) {
    let productImageFormData = new FormData();
    productImageFormData.append("files", e.target.files[0])

    const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
    }

    axios
      .post(`${API_CONSTANTS.COUNCIL.UPLOADS_MULTIPLE}`, productImageFormData)
      .then((res) => {
        if (res && res.data && res.data.length) {
          this.setState({ councilProductImage: res.data[0] })
        }
      })
      .catch((err) => {
        this.props.sendFlashMessage("Something went wrong while uploading image!!", "alert-danger")
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000)
      })
  }

  onVerificationFormFieldChange(e) {
    const state = this.state.verificationFormFieldObj;
    state[e.target.name] = e.target.value;
    this.setState({ verificationFormFieldObj: state });
  }

  handleVerificationFormChange = (event) => {
    const state = this.state.verificationFormFieldObj;
    state['verificationFormId'] = event.value;

    this.setState({ verificationFormFieldObj: state });
  };

  handleLeadFieldChange = (event) => {
    const state = this.state.verificationFormFieldObj;
    state['leadFieldId'] = event.value;

    this.setState({ verificationFormFieldObj: state });
  };

  handleDisplayTypeChange = (event) => {
    const state = this.state.verificationFormFieldObj;
    state['displayType'] = event.value;

    this.setState({ verificationFormFieldObj: state });
  };

  onChangeImg(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  getVerificationFormList = () => {
    this.setState({ verificationFormLoader: true });
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_VERIFICATION_FORMS}`)
      .then((res) => {
        let verificationFormOptions = res.data.map((form) => {
          return { value: form.id, label: form.formName }
        })

        this.setState({ verificationFormLoader: false, verificationFormList: res.data, verificationFormOptions });

        this.getVerificationFormFieldList();
      })
      .catch((err) => {
        this.setState({ verificationFormLoader: false });
        console.log("error is : " + err);
      });
  };

  getVerificationFormFieldList = () => {
    this.setState({ verificationFormLoader: true });
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_VERIFICATION_FORM_FIELDS}`)
      .then((res) => {
        this.setState({ verificationFormLoader: false });

        let verificationFormFieldList = res.data.map((field) => {
          let form = this.state.verificationFormList.find((form) => form.id == field.verificationFormId)
          if (form) field['verificationFormName'] = form.formName

          return field
        })

        let verificationFormFieldByForm = {}
        verificationFormFieldList.forEach((field) => {
          let key = field.verificationFormId
          if (!verificationFormFieldByForm[key]) verificationFormFieldByForm[key] = []
          verificationFormFieldByForm[key].push(field)
        })

        this.setState({ verificationFormFieldList, verificationFormFieldByForm });
      })
      .catch((err) => {
        this.setState({ verificationFormLoader: false });
        console.log("error is : " + err);
      });
  };

  getLeadFieldList = () => {
    this.setState({ verificationFormLoader: true });
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_LEAD_FORM_FIELDS}`)
      .then((res) => {
        this.setState({ verificationFormLoader: false });

        let leadFieldOptions = res.data.map((field) => {
          return { value: field.id, label: field.label }
        })

        this.setState({ leadFieldList: res.data, leadFieldOptions });
      })
      .catch((err) => {
        this.setState({ verificationFormLoader: false });
        console.log("error is : " + err);
      });
  };

  getRatesByCouncilId = () => {
    let id = this.props.match.params.id;
    console.log("param id is : " + id);
    this.setState({ verificationFormLoader: true });
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_COUNCIL_RATES_BY_ID}/${id}`)
      .then((res) => {
        this.setState({ verificationFormLoader: false });
        this.setState({ verificationFormList: res.data });
      })
      .catch((err) => {
        this.setState({ verificationFormLoader: false });
        console.log("error verificationFormList is : " + err);
      });
  };

  resetRates = () => {
    this.setState({ ratesData: {
      productId: '',
      isoCountryCode: '',
      productName: '',
      currencyCode: '',
      term: '',
      rate: '',
      productDescription: '',
      productImage: '',
      taxRate: '',
      setDefault: false,
      taxInclusive: false,
      autoCollectTax: false,
      charges: [],
      selectCharges: [],
      tempCharges: []
      },
    })
  }

  onVerificationFormSubmit(e) {
    e.preventDefault();
    e.stopPropagation()

    this.setState({ verificationFormLoader: true });

    let verificationFormObj = this.state.verificationFormObj

    let data = {
      formScript: verificationFormObj.formScript,
      formName: verificationFormObj.formName,
      formMessage: verificationFormObj.formMessage
    }

    if (verificationFormObj['id']) data['id'] = verificationFormObj.id

    if (verificationFormObj.id) { // EDIT
      axios
        .put(
          `${API_CONSTANTS.COUNCIL.UPDATE_VERIFICATION_FORM(verificationFormObj.id)}`,
          data
        )
        .then((res) => {
          this.props.sendFlashMessage(
            "Verification Form updated successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ verificationFormLoader: false })
          this.getVerificationFormList()
        })
        .catch((err) => {
          this.setState({ verificationFormLoader: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        })
    } else { // NEW
      axios
        .post(
          `${API_CONSTANTS.COUNCIL.POST_VERIFICATION_FORM}`,
          data
        )
        .then((res) => {
          this.props.sendFlashMessage(
            "Verification Form created successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ verificationFormLoader: false })
          this.getVerificationFormList()
        })
        .catch((err) => {
          this.setState({ verificationFormLoader: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        })
    }
  }

  onVerificationFormFieldsSubmit(e) {
    e.preventDefault();
    e.stopPropagation()

    this.setState({ verificationFormLoader: true });

    let verificationFormFieldObj = this.state.verificationFormFieldObj

    let data = {
      verificationFormId: verificationFormFieldObj.verificationFormId,
      leadFieldId: verificationFormFieldObj.leadFieldId,
      displayType: verificationFormFieldObj.displayType,
      controlId: verificationFormFieldObj.controlId,
      fieldLabel: verificationFormFieldObj.fieldLabel
    }

    if (verificationFormFieldObj['id']) { // EDIT
      data['id'] = verificationFormFieldObj.id
    } else { // NEW
      data['verificationFormId'] = this.state.activeVerificationForm
    }

    if (verificationFormFieldObj.id) { // EDIT
      axios
        .put(
          `${API_CONSTANTS.COUNCIL.UPDATE_VERIFICATION_FORM_FIELDS(verificationFormFieldObj.id)}`,
          data
        )
        .then((res) => {
          this.props.sendFlashMessage(
            "Verification Form Field updated successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ verificationFormLoader: false, verificationFormFieldObj: this.newVerificationFormFieldObj() })
          this.getVerificationFormFieldList()
        })
        .catch((err) => {
          this.setState({ verificationFormLoader: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        })
    } else { // NEW
      axios
        .post(
          `${API_CONSTANTS.COUNCIL.POST_VERIFICATION_FORM_FIELDS}`,
          data
        )
        .then((res) => {
          this.props.sendFlashMessage(
            "Verification Form Fields created successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ verificationFormLoader: false, verificationFormFieldObj: this.newVerificationFormFieldObj() })
          this.getVerificationFormFieldList()
          // this.getGatewaysByCouncilId()
        })
        .catch((err) => {
          this.setState({ verificationFormLoader: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        })
    }
  }

  // Callback~
  getFiles(myfile) {
    let file_size = myfile.file.size;

    //1 mb in bytes 1048576
    if (file_size > 1048576) {
      alert("Error: ulogo should be below 1 mb");
    } else {
      console.log("selected file is below :");
      console.log(myfile);

      this.setState({ ulogo: myfile });
    }
  }

  getbackgroundFiles(myfile) {
    let file_size = myfile.file.size;

    //1 mb in bytes 1048576
    if (file_size > 1048576) {
      alert("Error: background should be below 1 mb");
    } else {
      console.log("selected file is below :");
      console.log(myfile);

      this.setState({ ubackgroundImg: myfile });
    }
  }

  handlecountryChange = (country) => {
    this.setState({ ucountry: country });
    console.log(`updated Option selected:`, country);
  };

  handleAdvertisementCheckboxClick = (event) => {
    const state = this.state.verificationFormObj;
    state[event.target.name] = !state[event.target.name]
    this.setState({ verificationFormObj: state });
  }

  handleAdvertisementInputChange = (event) => {
    const state = this.state.verificationFormObj;
    state[event.target.name] = event.target.value;
    this.setState({ verificationFormObj: state });
  }

  handleCheckboxClick = (e) => {
    const state = this.state.verificationFormObj;
    state[e.target.name] = !state[e.target.name]
    this.setState({ verificationFormObj: state });
  }

  handleRatesCheckboxClick = (e) => {
    const state = this.state.ratesData;
    state[e.target.name] = !state[e.target.name]
    this.setState({ ratesData: state });
  }
  
  handleInputChange = (event) => {
    const state = this.state.verificationFormObj;
    state[event.target.name] = event.target.value;
    this.setState({ verificationFormObj: state });
  }

  onNewChargeClick = (e) => {
    this.setState({ showProductChargesSelect: true })
  }

  onRatesCancelClick = (e) => {
    this.setState({ ratesData: {
      productId: '',
      isoCountryCode: '',
      productName: '',
      currencyCode: '',
      term: '',
      rate: '',
      productDescription: '',
      productImage: '',
      taxRate: '',
      setDefault: false,
      taxInclusive: false,
      autoCollectTax: false,
      charges: [],
      selectCharges: [],
      tempCharges: []
    },
   })
  }

  onGatewaysCancelClick = (e) => {
    this.setState({ verificationFormFieldObj: {
      overrideGateway: '',
      overrideCountries: ''
    },
   })
  }

  removeProductCharge = (rateCharges, chargeId, productId) => {
    axios
      .post(`${API_CONSTANTS.COUNCIL.DELETE_PRODUCT_CHARGE(productId, chargeId)}`)
      .then((res) => {
        let charges = this.getValidChargesForDropdown(this.state.charges, rateCharges)
        this.setState({ chargesOptions: charges })
        this.props.sendFlashMessage(
          "Product charge deleted successfully !!",
          "alert-success"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      })
      .catch((err) => {
        console.log("REMOVE PRODUCT CHARGE API error: " + err);
      });
  }

  onProductChargeDeleteClick = (e, chargeId, productId) => {
    let rateCharges = this.state.ratesData.tempCharges

    const index = rateCharges.indexOf(chargeId)
    if (index > -1) rateCharges.splice(index, 1)

    this.removeProductCharge(rateCharges, chargeId, productId)
  }

  editCouncilRate = (e, rateId, rateObj = null) => {
    let rate = rateObj || this.state.verificationFormList.find((rate) => rate.id == rateId)

    if (rate && rate.id) {
      let data = {
        id: rate.id,
        councilId: rate.councilId,
        productId: rate.productId,
        isoCountryCode: rate.isoCountryCodes,
        productName: rate.productName,
        currencyCode: rate.currencyCode,
        term: rate.term,
        rate: rate.rate,
        productDescription: rate.productDescription,
        productImage: rate.productImage,
        taxRate: rate.taxRate,
        setDefault: rate.defaultCouncilProduct,
        taxInclusive: rate.taxInclusive,
        autoCollectTax: rate.autoCollectTax,
        charges: rate.charges,
        selectCharges: [],
        tempCharges: JSON.parse(JSON.stringify(rate.charges))
      }

      let charges = this.getValidChargesForDropdown(this.state.charges, rate.charges)

      this.setState({ ratesData: data, councilProductImage: rate.productImage, chargesOptions: charges })
    }
  }

  addNewRates = () => {
    this.resetRates()
  }

  deleteCouncilRate = (e, rateId) => {
    axios
      .get(
        `${API_CONSTANTS.COUNCIL.DELETE_COUNCIL_RATES(rateId)}`
      )
      .then((res) => {
        this.props.sendFlashMessage(
          "Rates deleted successfully !!",
          "alert-success"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
        this.setState({ verificationFormLoader: false })
        this.getRatesByCouncilId()
      })
      .catch((err) => {
        this.setState({ verificationFormLoader: false });
        console.log("error is : " + err);
        this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      })
  }

  editVerificationFormField = (e, formFieldId) => {
    let formField = this.state.verificationFormFieldList.find((formField) => formField.id == formFieldId)

    if (formField && formField.id) {
      let data = {
        id: formField.id,
        controlId: formField.controlId,
        displayType: formField.displayType,
        fieldLabel: formField.fieldLabel,
        leadFieldId: formField.leadFieldId,
        verificationFormId: formField.verificationFormId
      }

      this.setState({ verificationFormFieldObj: data })
    }
  }

  editVerificationForm = (e, formId) => {
    let formField = this.state.verificationFormList.find((formField) => formField.id == formId)

    if (formField && formField.id) {
      let data = {
        id: formField.id,
        formMessage: formField.formMessage,
        formName: formField.formName,
        formScript: formField.formScript
      }

      this.setState({ verificationFormObj: data })
    }
  }

  cloneVerificationForm = (e, formId) => {
    e.preventDefault()
    e.stopPropagation()

    this.setState({ verificationFormLoader: true })

    let verificationFormObj = this.state.verificationFormList.find((formField) => formField.id == formId)

    let data = {
      formScript: verificationFormObj.formScript,
      formName: `${verificationFormObj.formName} copy`,
      formMessage: verificationFormObj.formMessage
    }

    axios
      .post(
        `${API_CONSTANTS.COUNCIL.POST_VERIFICATION_FORM}`,
        data
      )
      .then(async (res) => {
        if (res.data && res.data.id && this.state.verificationFormFieldByForm[formId]) {
          await this.cloneVerificationFormFields(this.state.verificationFormFieldByForm[formId], res.data.id)
        }

        this.props.sendFlashMessage(
          "Verification Form cloned successfully !!",
          "alert-success"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
        this.setState({ verificationFormLoader: false })
        this.getVerificationFormList()
      })
      .catch((err) => {
        this.setState({ verificationFormLoader: false });
        console.log("error is : " + err);
        this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      })
  }

  cloneVerificationFormFields = async (fields, verificationFormId, index = 0) => { //this.getVerificationFormFieldList()
    if(!fields || !fields[index]) return true

    let data = fields[index]

    data = {
      verificationFormId,
      leadFieldId: data.leadFieldId,
      displayType: data.displayType,
      controlId: `${data.controlId} copy`,
      fieldLabel: data.fieldLabel
    }
    return new Promise((resolve, reject) => {
      axios
      .post(
        `${API_CONSTANTS.COUNCIL.POST_VERIFICATION_FORM_FIELDS}`,
        data
      )
      .then(async (res) => {
        await this.cloneVerificationFormFields(fields, verificationFormId, index + 1)
          resolve(true)
      })
      .catch((err) => {
        console.log("error is : " + err);
        reject(false)
      })
    })
  }

  deleteVerificationForm = (e, verificationFormId) => {
    this.setState({ verificationFormLoader: true })

    axios
      .get(`${API_CONSTANTS.COUNCIL.DELETE_VERIFICATION_FORMS(verificationFormId)}`)
      .then((res) => {
        this.props.sendFlashMessage(
          "Verification Form deleted successfully !!",
          "alert-success"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
        this.setState({ verificationFormLoader: false })
        this.getVerificationFormList()
      })
      .catch((err) => {
        console.log("REMOVE Verification Form API error: " + err);
      });
  }

  deleteVerificationFormField = (e, verificationFormFieldId) => {
    this.setState({ verificationFormLoader: true })

    axios
      .get(`${API_CONSTANTS.COUNCIL.DELETE_VERIFICATION_FORM_FIELDS(verificationFormFieldId)}`)
      .then((res) => {
        this.props.sendFlashMessage(
          "Verification Form deleted successfully !!",
          "alert-success"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
        this.setState({ verificationFormLoader: false })
        this.getVerificationFormList()
      })
      .catch((err) => {
        console.log("REMOVE Verification Form API error: " + err);
      });
  }

  verificationFormDetails = (e, verificationFormId) => {
    this.setState({ activeVerificationForm: verificationFormId, verificationFormFieldObj: this.newVerificationFormFieldObj() })

    this.state.inputRef.current.click()
  }

  handleChargesChange = (e) => {
    const state = this.state.ratesData
    state['tempCharges'] = [... new Set(state['charges'].concat((e || []).map((x) => x['value'])))]
    state['selectCharges'] = e
    this.setState({ ratesData: state })
  }

  render() {
    const {
      verificationFormObj,
      verificationFormLoader,
      verificationFormFieldObj,
      verificationFormList,
      verificationFormFieldList,
      verificationFormFieldByForm,
      activeVerificationForm,
      displayTypeOptions
    } = this.state;

    return (
      <>
        <section class="wrapper">
          <Header />
          <section class="">
            <Flashmessages />
            <div class="container-fluid">
              <div class="spacetb25">
                <div class="row">
                  <div class="col-xl-8 offset-xl-2">
                    <form onSubmit={this.onVerificationFormSubmit} encType="multipart/form-data">
                      <div class="card card__custom">
                        <div class="card-header">
                          <h5 class="card-title">*= Required Fields</h5>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-6">
                              <div class={`form-group textbox__label`}>
                                <label>Form Name</label>
                                  <input type="text" className="form-control" onChange={this.onChange} id="formName" placeholder="Enter form name" name="formName" value={verificationFormObj.formName || ""} />
                              </div>
                            </div>
                            <div class="col-sm-12">
                              <div class={`form-group textbox__label`}>
                                <label>Form Script</label>
                                <textarea class="form-control" rows="4" value={verificationFormObj.formScript} name = "formScript" onChange = {this.onChange}></textarea>
                              </div>
                            </div>
                            <div class="col-sm-12">
                              <div class={`form-group textbox__label`}>
                                <label>Form Message</label>
                                <textarea class="form-control" rows="4" value={verificationFormObj.formMessage} name = "formMessage" onChange = {this.onChange}></textarea>
                              </div>
                            </div>
                            <div class="col-sm-12">
                              <div className="text-center">
                                <button type="Submit" class="btn btn-secondary">Submit</button>
                              </div>
                            </div>
                          </div>
                          <div class="show-advertisement pd-t-20 mg-t-20" style={{ display: "none" }}>
                              <ul class="list-advertisement list-adds">
                                  <li>
                                      <div className="text-center hidden">
                                          <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#verificationFormFieldsModal" ref={ this.state.inputRef }>Verification Form Fields</button>
                                      </div>                                                                                   
                                  </li>
                              </ul>
                          </div>

                          <div className={ `show-advertisement pd-t-20 mg-t-20` }>                                        
                              <h5 class="mg-b-20 text-center"><strong>Verification Form</strong></h5>  
                              <div class="table-box">
                                  <div class="table-responsive">
                                      {
                                        
                                          <table class="table" style={{ minWidth: "900px" }}>
                                            <thead>
                                                <tr>
                                                    <th style= {{ width: "25%" }}>Form ID</th>
                                                    <th style= {{ width: "30%" }}>Form Name</th>
                                                    <th style= {{ width: "10%" }}>Details</th>
                                                    <th style= {{ width: "10%" }}>Edit</th>
                                                    <th style= {{ width: "10%" }}>Clone</th>
                                                    <th style= {{ width: "15%" }}>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    verificationFormList.map((verificationForm, i) => (
                                                        <tr key = { i }>                                                
                                                          <td>
                                                              { verificationForm.id }
                                                          </td>
                                                          <td>
                                                              { verificationForm.formName }
                                                          </td>
                                                          <td class="text-center">
                                                              <a href="javascript:void(0)" class="btn btn-primary text-edit" onClick={ (e) => this.verificationFormDetails(e, verificationForm.id) }>
                                                                  <span class="icon-search"></span>
                                                              </a>
                                                          </td>
                                                          <td class="text-center">
                                                              <a href="javascript:void(0)" class="btn btn-primary text-edit" onClick={ (e) => this.editVerificationForm(e, verificationForm.id) }>
                                                                  <span class="icon-edit"></span>
                                                              </a>
                                                          </td>
                                                          <td class="text-center">
                                                              <a href="javascript:void(0)" class="btn btn-primary text-edit" onClick={ (e) => this.cloneVerificationForm(e, verificationForm.id) }>
                                                                  <span class="icon-plus"></span>
                                                              </a>
                                                          </td>
                                                          <td class="text-center">
                                                              <a href="javascript:void(0)" class="btn btn-primary text-edit" onClick={ (e) => this.deleteVerificationForm(e, verificationForm.id) }>
                                                                  <span class="icon-cross clos-icon"></span>
                                                              </a>
                                                          </td>
                                                      </tr>
                                                    ))
                                                }
                                              </tbody>
                                          </table>
                                        
                                      }
                                  </div>                                        
                              </div>  
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Loader loadStatus={verificationFormLoader} />
        </section>


        <div class="modal fade new__modal rate-currenices-modal" id="verificationFormFieldsModal" tabindex="-1" role="dialog"  aria-hidden="true">
            <div class="modal-dialog modal-xl our-custom-modal" role="document">
              <div class="modal-content">
                <div class="custom-modal-header">
                    <div class="modal-header">
                        <h5 class="modal-title">Verification Form Fields</h5>        
                        <button type="button" class="close closed__btn" data-dismiss="modal" aria-label="Close">
                            <span class="icon-cross"></span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                  <div className={ `show-advertisement pd-t-20 mg-t-20` }>                                        
                      <h5 class="mg-b-20 text-center"><strong>Verification Form Fields</strong></h5>
                      <form onSubmit={this.onVerificationFormFieldsSubmit}>
                        <ul class="list-advertisement list-adds">
                          <li>
                            <div class="row">
                                <div class="col-sm-4 text-left text-sm-right">
                                    <label class="text-label pd-t-5">Lead Field</label>
                                </div>
                                <div class="col-sm-6">
                                    <Select
                                      value={ this.state.leadFieldOptions.filter(option => option.value == verificationFormFieldObj.leadFieldId) }
                                      onChange={this.handleLeadFieldChange}
                                      options={this.state.leadFieldOptions}
                                    />
                                </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                                <div class="col-sm-4 text-left text-sm-right">
                                    <label class="text-label pd-t-5">Display Type</label>
                                </div>
                                <div class="col-sm-6">
                                    <Select
                                      value={ this.state.displayTypeOptions.filter(option => option.value == verificationFormFieldObj.displayType) }
                                      onChange={this.handleDisplayTypeChange}
                                      options={this.state.displayTypeOptions}
                                    />
                                </div>
                            </div>
                          </li>
                          <li>
                              <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right">
                                      <label class="text-label pd-t-5">Control Id</label>
                                  </div>
                                  <div class="col-sm-6">
                                      <input type="text" class="form-control" placeholder="" value={verificationFormFieldObj.controlId} name = "controlId" onChange = {this.onVerificationFormFieldChange} />
                                  </div>
                              </div>
                          </li>
                          <li>
                              <div class="row">
                                  <div class="col-sm-4 text-left text-sm-right">
                                      <label class="text-label pd-t-5">Field Label</label>
                                  </div>
                                  <div class="col-sm-6">
                                      <input type="text" class="form-control" placeholder="" value={verificationFormFieldObj.fieldLabel} name = "fieldLabel" onChange = {this.onVerificationFormFieldChange} />
                                  </div>
                              </div>
                          </li>
                          <li>
                              <div className="text-center">
                                  <button type="submit" class="btn btn-secondary">
                                    Submit
                                  </button>
                              </div>                                                                                   
                          </li>
                        </ul>  
                      </form>
                      
                      <div class="table-box">
                          <div class="table-responsive">
                              <table class="table" style={{ minWidth: "900px" }}>
                                <thead>
                                    <tr>
                                        <th style= {{ width: "30%" }}>Display type</th>
                                        <th style= {{ width: "30%" }}>Control ID</th>
                                        <th style= {{ width: "30%" }}>Field Label</th>
                                        <th class="text-center" style= {{ width: "5%" }}></th>
                                        <th class="text-center" style= {{ width: "5%" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {
                                      activeVerificationForm && verificationFormFieldByForm[activeVerificationForm] && verificationFormFieldByForm[activeVerificationForm].map((verificationFormField, i) => (
                                          <tr key = { i }>                                                
                                            <td>
                                                { (displayTypeOptions.find((option) => option.value == verificationFormField.displayType)) && (displayTypeOptions.find((option) => option.value == verificationFormField.displayType).label) }
                                            </td>
                                            <td>
                                                { verificationFormField.controlId }
                                            </td>
                                            <td>
                                                { verificationFormField.fieldLabel }
                                            </td>
                                            <td class="text-center">
                                                <a href="javascript:void(0)" class="btn btn-primary text-edit" onClick={ (e) => this.editVerificationFormField(e, verificationFormField.id) }>
                                                    <span class="icon-edit"></span>
                                                </a>
                                            </td>
                                            <td class="text-center">
                                                <a href="javascript:void(0)" class="btn btn-primary text-edit" onClick={ (e) => this.deleteVerificationFormField(e, verificationFormField.id) }>
                                                    <span class="icon-cross clos-icon"></span>
                                                </a>
                                            </td>
                                        </tr>
                                      ))
                                  }
                                </tbody>
                              </table>
                          </div>                                        
                      </div>  
                  </div>
                </div>
              </div>
            </div>
        </div>
      </>
    );
  }
}

VerificationForm.propTypes = {
  auth: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(VerificationForm));
