import React from "react";
import Editor from "./Editor";

const DPOConfig = ({ value, name, onChange }) => {
  return (
    <div class="row">
      <div class="col-sm-4 text-left text-sm-right">
        <label class="text-label pd-t-5">DPO Config</label>
      </div>
      <div class="col-sm-6">
        <Editor json={value} name={name} onUpdate={onChange} />
      </div>
    </div>
  );
};

export default DPOConfig;
