const SessionStrorageService = (function() {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setOrgFilters(filterObj) {
    sessionStorage.setItem("orgFilter", filterObj);
  }
  function _getOrgFilters() {
    return sessionStorage.getItem("orgFilter"); //OrgFilters
  }
  return {
    getService: _getService,
    setOrgFilters: _setOrgFilters,
    getOrgFilters: _getOrgFilters,
  };
})();
export default SessionStrorageService;
