import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Select from "react-select";

import ArticleList from "./ArticleList";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import { PLATFORM_CONSTANTS } from "../../shared/utils/platform.constant";

import ProgressBar from "../common/ProgressBar";
import DropZoneManager from "../common/DropZoneManager";
import Topology from "./Topology";

import "./css/inclusionStyle.css";

class IncludedResearchArticles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndustry: {
        label: "Nurse",
        value: "91",
      },
      allSpecialtiesOptArr: null,
      systemAssets: null,
      allTopologyList: [],
      selectedLicenses: null,
      selectedSpecialties: null,
      selectedTaxonomyLevel1: null,
      allTaxonomyLevel2OptArr: [],
      selectedTaxonomyLevel2: null,
      allTaxonomyLevel3OptArr: [],
      selectedTaxonomyLevel3: null,
      selectedArticleStatus: 0,
      coverUrl: "",
      errors: {},
      loading: false,
    };
  }

  componentDidMount() {
    this.getCurrentSystemAllAssets();
  }

  componentWillUnmount() {}

  componentDidUpdate() {}

  getCurrentSystemAllAssets = async () => {
    const { selectedIndustry } = this.state;
    const { _handleFlashMsg } = this.props;
    try {
      this._handleLoading(true);

      let resdata = await this.getCurrentSystemAssetsByIndustry(
        selectedIndustry.label
      );

      console.log(`all system assets resdata`, resdata);

      this.setState({
        systemAssets: resdata,
      });

      this._handleLoading(false);
    } catch (error) {
      console.log("System Assets error is : " + error);
      this._handleLoading(false);
      _handleFlashMsg("System Assets are not fetching", "error");
    }
  };

  getCurrentSystemAssetsByIndustry = async (industry) => {
    try {
      let resdata = await axios.get(
        `${API_CONSTANTS.ARTICLE.PLATFORM_SYSTEM_ASSETS}?industry=${industry}`
      );

      return resdata.data;
    } catch (error) {
      console.log("System Assets error is : " + error);
    }
  };

  articleValidation = () => {
    const { allArticles } = this.props;
    let selectedStatus = false;

    for (let i = 0; i < allArticles.length; i++) {
      if (allArticles[i].selected) {
        selectedStatus = true;
        break;
      }
    }

    if (selectedStatus) {
      return true;
    } else {
      // _handleFlashMsg("Please Select Atleast 1 Article", "alert-error");
      return false;
    }
  };

  handleIndustries = async (data) => {
    this._handleLoading(true);

    const { systemAssets } = this.state;
    console.log("selected Industries is : ", data);
    this.setState({ selectedIndustry: data });

    let resdata = await this.getCurrentSystemAssetsByIndustry(data.label);

    let updatedSpec = resdata.specialties;

    let systemAssetsClone = systemAssets;
    systemAssetsClone.specialties = updatedSpec;

    console.log("systemAssetsClone", systemAssetsClone);
    this.setState({
      systemAssets: systemAssetsClone,
      selectedSpecialties: null,
    });
    this._handleLoading(false);
  };

  handleLicenses = (license) => {
    console.log("selected license is : ", license);

    this.setState({ selectedLicenses: license });
  };

  handleSpecialties = (data) => {
    console.log("selected Specialties is : ", data);

    this.setState({ selectedSpecialties: data });
  };

  handleArticleSorting = (status) => {
    console.log("selected status is : ", status);

    this.setState({ selectedArticleStatus: status });
  };

  handleArticleGeneration = async (e, data) => {
    e.preventDefault();
    const {
      handleMarkArticleGeneration,
      _handleLoading,
      _handleFlashMsg,
    } = this.props;
    try {
      _handleLoading(true);
      const validStatus = await this.validateCourseGeneration();
      if (validStatus) {
        const payload = await this._createCustomArticleSignature(data);
        console.log("payload is : ", payload);

        let resdata = await axios.post(
          API_CONSTANTS.ARTICLE.PLATFORM_CORE_ARTICLE_GENERATE,
          payload
        );

        if (resdata) {
          let result = resdata.data.data;
          console.log(`generated article resdata`, result);

          handleMarkArticleGeneration(result);
          _handleFlashMsg(`Article is generated successfully.`, "success");

          _handleLoading(false);
        }
      } else {
        _handleLoading(false);
        _handleFlashMsg(`Please select all the article configuration`, "error");
      }
    } catch (error) {
      console.log("Generation Article error is : ", error);

      _handleLoading(false);
      _handleFlashMsg(`${error}`, "error");
    }
  };

  _createCustomArticleSignature = async (data) => {
    const { selectedIndustry, coverUrl } = this.state;
    const authorsName = await this.getAuthorSignature(
      data.articleMeta.contribGroup
    );
    const specialtiesData = await this.getSpecialtiesSignature();
    const licenceData = await this.getLicencesSignature();
    const topologyData = await this.getTopologySignature();

    return {
      payload: {
        name: data.articleMeta.title,
        duration: 0,
        description: data.articleMeta.introduction,
        author_name: authorsName,
        author_information: data.articleMeta.authorInformation,
        scorm_version: "SCORM_1.3",
        scorm_is_https: 1,
        scorm_sends_completed_event: 1,
        star_rating: 0,
        specialties: specialtiesData,
        topology: topologyData,
        licenses: licenceData,
        objectives: data.articleMeta.objectives,
      },
      info: {
        platform: data.platform,
        doi: data.doi,
        industry: selectedIndustry,
        cover: coverUrl,
      },
    };
  };

  getAuthorSignature = async (authors) => {
    let result = authors.map((elem) => elem).join(",");
    return result;
  };

  getSpecialtiesSignature = async () => {
    const { selectedSpecialties } = this.state;
    let result = selectedSpecialties.map((e) => e.label).join(":::");
    return result;
  };

  getLicencesSignature = async () => {
    const { selectedLicenses } = this.state;
    let result = selectedLicenses.map((e) => e.value).join(":::");
    return result;
  };

  getTopologySignature = async () => {
    const { allTopologyList } = this.state;

    let result = allTopologyList.map((e) => {
      return {
        level1: e.selectedTaxonomyLevel1.label,
        level2: e.selectedTaxonomyLevel2.label,
        level3: e.selectedTaxonomyLevel3.label,
      };
    });

    return result;
  };

  handleAddTopology = async (e) => {
    e.preventDefault();
    const { systemAssets, allTopologyList } = this.state;

    if (systemAssets && systemAssets.taxonomies) {
      let validLevel1Data = systemAssets.taxonomies.filter(function(itm) {
        return itm.depth == 1;
      });

      let topologyItem = {
        allTaxonomyLevel1OptArr: validLevel1Data,
        allTaxonomyLevel2OptArr: [],
        allTaxonomyLevel3OptArr: [],
        selectedTaxonomyLevel1: null,
        selectedTaxonomyLevel2: null,
        selectedTaxonomyLevel3: null,
      };

      let array = [...allTopologyList, topologyItem];

      this._handleAllTopologyList(array);
    }
  };

  _handleAllTopologyList = (data) => {
    this.setState({ allTopologyList: data });
  };

  validateCourseGeneration = async () => {
    const {
      coverUrl,
      selectedIndustry,
      selectedSpecialties,
      selectedLicenses,
      allTopologyList,
    } = this.state;

    if (
      coverUrl &&
      selectedIndustry &&
      selectedSpecialties &&
      selectedLicenses &&
      allTopologyList.length
    ) {
      let topologyStatus = await this.validateTopologySelection();

      if (topologyStatus) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  validateTopologySelection = async () => {
    const { allTopologyList } = this.state;

    let status = true;

    for (let i = 0; i < allTopologyList.length; i++) {
      if (
        !allTopologyList[i].selectedTaxonomyLevel1 ||
        !allTopologyList[i].selectedTaxonomyLevel2 ||
        !allTopologyList[i].selectedTaxonomyLevel3
      ) {
        status = false;
        break;
      }
    }

    return status;
  };

  _handleCoverUrl = async (url) => {
    this.setState({ coverUrl: url });
  };

  _handleLoading = (status) => {
    this.setState({ loading: status });
  };

  render() {
    const {
      selectedIndustry,
      systemAssets,
      allTopologyList,
      selectedLicenses,
      selectedSpecialties,
      selectedArticleStatus,
      coverUrl,
      loading,
    } = this.state;

    const {
      allArticles,
      multiselectarr,
      handleArticleSelection,
      _handleFlashMsg,
      handleOverlaySelection,
      allArticlesLoading,
    } = this.props;

    let allArticleCont;
    let paginationCont;

    if (allArticles && allArticles.length > 0) {
      let allLicencesOpt;
      let systemAssetsloading;

      let allIndustriesOpt;
      let allIndustriesloading;

      let allSpecialtiesOpt;
      let allSpecialtiesloading;

      if (systemAssets === null) {
        systemAssetsloading = "";
      } else {
        if (systemAssets && Object.keys(systemAssets.licenses).length > 0) {
          allLicencesOpt = Object.entries(systemAssets.licenses).map(
            (item) => ({
              label: item[1],
              value: item[0],
            })
          );
        }

        if (systemAssets && Object.keys(systemAssets.industries).length > 0) {
          allIndustriesOpt = Object.entries(systemAssets.industries).map(
            (item) => ({
              label: item[1],
              value: item[0],
            })
          );
        }

        if (systemAssets && Object.keys(systemAssets.specialties).length > 0) {
          allSpecialtiesOpt = Object.entries(systemAssets.specialties).map(
            (item) => ({
              label: item[1].name,
              value: item[0],
            })
          );
        }
      }

      let filtered = allArticles;

      selectedArticleStatus.value &&
        filtered &&
        (filtered = filtered.filter(
          (article) =>
            article.status &&
            article.status
              .toString()
              .startsWith(selectedArticleStatus.value.toString())
        ));

      allArticleCont = (
        <div>
          <div class="pd-t-20">
            <div className="row justify-content-between flex-md-row-reverse">
              <div className="col-xl-4 col-lg-6 col-md-8 mg-b-15">
                <div className="inclusionSortDropDown mg-t-6">
                  <Select
                    value={selectedArticleStatus}
                    onChange={this.handleArticleSorting}
                    options={PLATFORM_CONSTANTS.articleSortingOptions}
                  />
                  {/* <button
                    type="button"
                    class="btn btn-warning generateCourseBtn"
                    onClick={() => {
                      _handleFlashMsg(`Feature is coming soon`, "info");
                    }}
                  >
                    Generate Article
                  </button> */}
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 mg-b-15">
                <DropZoneManager
                  fileId={"cover"}
                  fileUrl={coverUrl}
                  _handleFileUrl={this._handleCoverUrl}
                />
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 mg-b-15">
                <a
                  href="javascript:void(0)"
                  class="add-licence-select"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Select Configuration...
                  <span class="icon-plus addicons"></span>
                </a>
              </div>
            </div>
          </div>

          <div class="collapse" id="collapseExample">
            <div class="card card-body">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <h5 className="mg-b-10">
                    <strong>Select Industry</strong>
                  </h5>
                  <div className="form-select mg-b-20">
                    <Select
                      value={selectedIndustry}
                      onChange={this.handleIndustries}
                      options={allIndustriesOpt}
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6">
                  <h5 className="mg-b-10">
                    <strong>Select Specialities</strong>
                  </h5>
                  <div className="form-select mg-b-20">
                    {loading ? (
                      "Loading..."
                    ) : (
                      <Select
                        value={selectedSpecialties}
                        onChange={this.handleSpecialties}
                        options={allSpecialtiesOpt}
                        isMulti={true}
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <h5 className="mg-b-10">
                    <strong>Select Licenses</strong>
                  </h5>
                  <div className="form-select mg-b-20">
                    <Select
                      value={selectedLicenses}
                      onChange={this.handleLicenses}
                      options={allLicencesOpt}
                      isMulti={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <button
                    class="btn"
                    onClick={(e) => this.handleAddTopology(e)}
                  >
                    <i class="fa fa-plus"></i> Add Topology
                  </button>
                </div>
              </div>

              <Topology
                systemAssets={systemAssets}
                allTopologyList={allTopologyList}
                _handleAllTopologyList={this._handleAllTopologyList}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 10 }}>
            <div className="col-12">
              <ArticleList
                allArticles={allArticles}
                articles={filtered}
                multiselectarr={multiselectarr}
                handleArticleSelection={handleArticleSelection}
                handleOverlaySelection={handleOverlaySelection}
                handleArticleGeneration={this.handleArticleGeneration}
                allArticlesLoading={allArticlesLoading}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <br />

        {allArticlesLoading ? (
          <ProgressBar loadStatus={allArticlesLoading} />
        ) : (
          allArticleCont
        )}

        {paginationCont}
      </div>
    );
  }
}

IncludedResearchArticles.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors, //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(IncludedResearchArticles));
