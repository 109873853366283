import React, { Component } from "react";
import axios from "axios";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Select from "react-select";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import { PLATFORM_CONSTANTS } from "../../shared/utils/platform.constant";
import {
  contributorGroupHelper,
  authorInformationHelper,
  pubStatusHelper,
  introductionHelper,
  descriptionHelper,
} from "../../helpers/contributerGroup.helper";

import Header from "../layout/Header";
import Sidebar from "../layout/sidebar";
import ArticleList from "./ArticleList";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Flashmessages from "../flashMessagesComp/flashMessages";

import ArticleTypeModal from "./ArticleTypeModal";
import F1000Research from "./F1000Research";
import WellcomeOpenResearch from "./WellcomeOpenResearch";
import AAOSOR from "./AAOSOR";
import GOR from "./GOR";

import Pagination from "./Pagination";

import Loader from "../common/GlobalLoader";

import "react-toastify/dist/ReactToastify.css";

//const rawData = makeData();

class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allF1000Articles: [],
      allWORArticles: [],
      allAAOSORArticles: [],
      allGORArticles: [],
      start: 0,
      currentPage: 1,
      articlePerPage: 5,
      totalF1000Pages: 0,
      totalWORPages: 0,
      totalAAOSORPages: 0,
      totalGORPages: 0,
      wt: "json",
      loading: false,
      articleLoading: false,
      articleF1000Loading: false,
      articleWORLoading: false,
      articleAAOSORLoading: false,
      articleGORLoading: false,
      platform: null,
      searchKeyword: "",
      searchTitle: "",
      searchDoi: '',
      articleSearchStr: "",
      f1000multiselectarr: [],
      wormultiselectarr: [],
      aaosormultiselectarr: [],
      gormultiselectarr: [],
      articleTypes: [
        {
          label: "BRIEF_REPORT",
          value: "BRIEF_REPORT",
          operator: "",
          query: "R_TY:BRIEF_REPORT",
          selected: false,
        },
        {
          label: "CASE_REPORT",
          value: "CASE_REPORT",
          operator: "",
          query: "R_TY:CASE_REPORT",
          selected: false,
        },
        {
          label: "CLINICAL_PRACTICE_ARTICLE",
          value: "CLINICAL_PRACTICE_ARTICLE",
          operator: "",
          query: "R_TY:CLINICAL_PRACTICE_ARTICLE",
          selected: false,
        },
        {
          label: "CORRESPONDENCE",
          value: "CORRESPONDENCE",
          operator: "",
          query: "R_TY:CORRESPONDENCE",
          selected: false,
        },
        {
          label: "DATA_NOTE",
          value: "DATA_NOTE",
          operator: "",
          query: "R_TY:DATA_NOTE",
          selected: false,
        },
        {
          label: "EDITORIAL",
          value: "EDITORIAL",
          operator: "",
          query: "R_TY:EDITORIAL",
          selected: false,
        },
        {
          label: "METHOD_ARTICLE",
          value: "METHOD_ARTICLE",
          operator: "",
          query: "R_TY:METHOD_ARTICLE",
          selected: false,
        },
        {
          label: "RESEARCH_ARTICLE",
          value: "RESEARCH_ARTICLE",
          operator: "",
          query: "R_TY:RESEARCH_ARTICLE",
          selected: false,
        },
        {
          label: "REVIEW",
          value: "REVIEW",
          operator: "",
          query: "R_TY:REVIEW",
          selected: false,
        },
        {
          label: "RESEARCH_NOTE",
          value: "RESEARCH_NOTE",
          operator: "",
          query: "R_TY:RESEARCH_NOTE",
          selected: false,
        },
        {
          label: "STUDY_PROTOCOL",
          value: "STUDY_PROTOCOL",
          operator: "",
          query: "R_TY:STUDY_PROTOCOL",
          selected: false,
        },
        {
          label: "SYSTEMATIC_REVIEW",
          value: "SYSTEMATIC_REVIEW",
          operator: "",
          query: "R_TY:SYSTEMATIC_REVIEW",
          selected: false,
        },
        {
          label: "OPINION_ARTICLE",
          value: "OPINION_ARTICLE",
          operator: "",
          query: "R_TY:OPINION_ARTICLE",
          selected: false,
        },
        {
          label: "SOFTWARE_TOOLS",
          value: "SOFTWARE_TOOLS",
          operator: "",
          query: "R_TY:SOFTWARE_TOOLS",
          selected: false,
        },
        {
          label: "ANTIBODY_VALIDATION_ARTICLE",
          value: "ANTIBODY_VALIDATION_ARTICLE",
          operator: "",
          query: "R_TY:ANTIBODY_VALIDATION_ARTICLE",
          selected: false,
        },
        {
          label: "RETRACTION",
          value: "RETRACTION",
          operator: "",
          query: "R_TY:RETRACTION",
          selected: false,
        },
        {
          label: "CLINICAL_TRIAL",
          value: "CLINICAL_TRIAL",
          operator: "",
          query: "R_TY:CLINICAL_TRIAL",
          selected: false,
        },
        {
          label: "REGISTERED_REPORT",
          value: "REGISTERED_REPORT",
          operator: "",
          query: "R_TY:REGISTERED_REPORT",
          selected: false,
        },
      ],
      activeTab: "F1000Research",
      errors: {},
      compLoading: false,
    };
  }

  componentDidMount() {}

  _handleOnChange = (e) => {
    const {
      target: { value, name },
    } = e;

    this.setState({
      [name]: value,
    });
  };

  _handleGetArticles = async (e) => {
    e.preventDefault();

    this.resetComponentSearchState();

    this._handleSearchLoading(true);

    let statusValidation = await this.validateGetArticleCall();

    if (statusValidation) {
      let getStatusOfArticleType = await this._checkArticleTypeSelectStatus();
      if ((this.state.searchDoi || "").trim().length) {
        this._reSignaturedAllDoisWithPlatform(this.getSignatureAllDoisWithPlatformData(this.state.searchDoi))
        this._handleSearchLoading(false);
      } else {
        let allQueryArr = await this._allPlatformQueryBuilder(
          getStatusOfArticleType
        );
  
        console.log("final all query", allQueryArr);
        this.getCurrentArticles(allQueryArr);
      }
    } else {
      this._handleSearchLoading(false);
    }
  };

  _checkArticleTypeSelectStatus = async () => {
    const { articleTypes } = this.state;

    let data = {
      status: false,
      typesSelected: [],
      typesQuery: "",
    };

    for (let i = 0; i < articleTypes.length; i++) {
      if (articleTypes[i].selected) {
        data.status = true;
        await data.typesSelected.push(articleTypes[i].query);
      }
    }

    if (data.status) {
      data.typesQuery = data.typesSelected.join(" AND ");
    }

    return data;
  };

  _allPlatformQueryBuilder = async (getStatusOfArticleType) => {
    let allQuery = [];

    const {
      platform,
      searchKeyword,
      searchTitle,
      start,
      currentPage,
      articlePerPage,
      wt,
    } = this.state;

    let typeSubQuery = getStatusOfArticleType.typesQuery;

    let queryRes;

    for (let i = 0; i < platform.length; i++) {
      if (
        platform &&
        platform[i].value &&
        searchKeyword &&
        searchTitle &&
        typeSubQuery
      ) {
        console.log("query type 1");
        queryRes = `${platform[i].value}/extapi/search?q=R_ABS:${searchKeyword}&R_TI:${searchTitle}&${typeSubQuery}&start=${start}&page=${currentPage}&rows=${articlePerPage}&wt=${wt}`;
      } else if (
        platform &&
        platform[i].value &&
        searchKeyword &&
        typeSubQuery
      ) {
        console.log("query type 2");
        queryRes = `${platform[i].value}/extapi/search?q=R_ABS:${searchKeyword}&${typeSubQuery}&start=${start}&page=${currentPage}&rows=${articlePerPage}&wt=${wt}`;
      } else if (platform && platform[i].value && searchTitle && typeSubQuery) {
        console.log("query type 3");
        queryRes = `${platform[i].value}/extapi/search?q=R_TI:${searchTitle}&${typeSubQuery}&start=${start}&page=${currentPage}&rows=${articlePerPage}&wt=${wt}`;
      } else if (platform && platform[i].value && typeSubQuery) {
        console.log("query type 4");
        queryRes = `${platform[i].value}/extapi/search?q=${typeSubQuery}&start=${start}&page=${currentPage}&rows=${articlePerPage}&wt=${wt}`;
      } else if (platform && platform[i].value && searchKeyword) {
        console.log("query type 5");
        queryRes = `${platform[i].value}/extapi/search?q=R_ABS:${searchKeyword}&start=${start}&page=${currentPage}&rows=${articlePerPage}&wt=${wt}`;
      } else if (platform && platform[i].value && searchTitle) {
        console.log("query type 6");
        queryRes = `${platform[i].value}/extapi/search?q=R_TI:${searchTitle}&start=${start}&page=${currentPage}&rows=${articlePerPage}&wt=${wt}`;
      }

      allQuery.push({ platform: platform[i].value, query: queryRes });
    }

    return allQuery;
  };

  getCurrentArticles = async (allQueryArr) => {
    try {
      let allDois = [];

      for (let i = 0; i < allQueryArr.length; i++) {
        console.log("current platform: ", allQueryArr[i].platform);
        console.log("current platform query: ", allQueryArr[i].query);
        let payload = {
          platform: allQueryArr[i].platform,
          pQuery: allQueryArr[i].query,
        };
        let resdata = await axios.post(
          `${API_CONSTANTS.ARTICLE.PLATFORM_QUERY_SEARCH}`,
          payload
        );

        if (resdata) {
          console.log(`all Articles Doi resdata`, resdata.data.data);

          if (
            resdata.data.statusCode === 200 &&
            resdata.data.data.doi.length > 0
          ) {
            if (
              allQueryArr[i].platform ===
              PLATFORM_CONSTANTS.PLATFORM.F1000Research
            ) {
              this.setState({
                totalF1000Pages: resdata.data.data.totalNumberOfPages,
              });
            } else if (
              allQueryArr[i].platform === PLATFORM_CONSTANTS.PLATFORM.WOR
            ) {
              this.setState({
                totalWORPages: resdata.data.data.totalNumberOfPages,
              });
            } else if (
              allQueryArr[i].platform === PLATFORM_CONSTANTS.PLATFORM.AAOSOR
            ) {
              this.setState({
                totalAAOSORPages: resdata.data.data.totalNumberOfPages,
              });
            } else if (
              allQueryArr[i].platform === PLATFORM_CONSTANTS.PLATFORM.GOR
            ) {
              this.setState({
                totalGORPages: resdata.data.data.totalNumberOfPages,
              });
            }

            allDois.push({
              platform: allQueryArr[i].platform,
              doi: resdata.data.data.doi,
            });
          } else {
            this._handleFlashMsg(
              `No Article Found For Platform ${allQueryArr[i].platform}.`,
              "info"
            );
          }
        }
      }

      console.log("allDois with respective platform: ", allDois);

      this._reSignaturedAllDoisWithPlatform(allDois);

      this._handleSearchLoading(false);
      // let signaRes = await this._reSignaturedAllDoisWithPlatform(allDois);
      // console.log("signaRes", signaRes);
      // this._handleGetF1000ArticleByDoi(signaRes);
    } catch (error) {
      console.log("PLATFORM_QUERY_SEARCH error is : " + error);
      this._handleSearchLoading(false);
      this._handleFlashMsg("Something went wrong.", "error");
    }
  };

  _reSignaturedAllDoisWithPlatform = async (allDois) => {
    for (let i = 0; i < allDois.length; i++) {
      let finalPayloadSign = [];

      for (let j = 0; j < allDois[i].doi.length; j++) {
        finalPayloadSign.push({
          platform: allDois[i].platform,
          currentDoi: allDois[i].doi[j],
        });
      }

      this._getAllDoisWithPlatformData(allDois[i].platform, finalPayloadSign);
    }
  };

  getSignatureAllDoisWithPlatformData = (doi) => {
    let res = []
    let platform = '';
    (this.state.platform || []).forEach((p) => {
      if (p.value === PLATFORM_CONSTANTS.PLATFORM.F1000Research) {
        platform = p.value
      } else if (p.value === PLATFORM_CONSTANTS.PLATFORM.WOR) {
        platform = p.value
      } else if (p.value === PLATFORM_CONSTANTS.PLATFORM.AAOSOR) {
        platform = p.value
      } else if (p.value === PLATFORM_CONSTANTS.PLATFORM.GOR) {
        platform = p.value
      }
      res.push({ platform, doi: [doi] })
    })
    return res
  }

  _getAllDoisWithPlatformData = async (platform, finalPayloadSign) => {
    if (platform === PLATFORM_CONSTANTS.PLATFORM.F1000Research) {
      this._handleGetF1000ArticleByDoi(finalPayloadSign);
    } else if (platform === PLATFORM_CONSTANTS.PLATFORM.WOR) {
      this._handleGetWORArticleByDoi(finalPayloadSign);
    } else if (platform === PLATFORM_CONSTANTS.PLATFORM.AAOSOR) {
      this._handleGetAAOSORArticleByDoi(finalPayloadSign);
    } else if (platform === PLATFORM_CONSTANTS.PLATFORM.GOR) {
      this._handleGetGORArticleByDoi(finalPayloadSign);
    }

    return true;
  };

  _handleGetF1000ArticleByDoi = async (articleDois) => {
    this._handleF1000ArticleLoading(true);

    try {
      for (let i = 0; i < articleDois.length; i++) {
        let currentDoi = articleDois[i].currentDoi;
        let resdata = await axios.get(
          `${API_CONSTANTS.ARTICLE.PLATFORM_DOI_SEARCH}?platform=${articleDois[i].platform}&doi=${currentDoi}`
        );

        let resArticleData = resdata.data.data;
        if (resArticleData) {
          console.log("resArticleData", resArticleData);
          let data = await this._createCustomArticleSignature(
            articleDois[i].platform,
            currentDoi,
            resArticleData.articleJson,
            resArticleData.status
          );

          this.setState({
            allF1000Articles: this.state.allF1000Articles.concat([data]),
          });
        }
      }

      this._handleF1000ArticleLoading(false);

      this._handleFlashMsg(
        `F1000Research Article fetched successfully.`,
        "success"
      );
    } catch (error) {
      this._handleF1000ArticleLoading(false);
      console.log("PLATFORM_QUERY_SEARCH error is : " + error);

      this._handleFlashMsg("Something went wrong", "error");
    }
  };

  _handleGetWORArticleByDoi = async (articleDois) => {
    this._handleWORArticleLoading(true);

    try {
      for (let i = 0; i < articleDois.length; i++) {
        let currentDoi = articleDois[i].currentDoi;
        let resdata = await axios.get(
          `${API_CONSTANTS.ARTICLE.PLATFORM_DOI_SEARCH}?platform=${articleDois[i].platform}&doi=${currentDoi}`
        );

        let resArticleData = resdata.data.data;
        if (resArticleData) {
          console.log("resArticleData", resArticleData);
          let data = await this._createCustomArticleSignature(
            articleDois[i].platform,
            currentDoi,
            resArticleData.articleJson,
            resArticleData.status
          );
          this.setState({
            allWORArticles: this.state.allWORArticles.concat([data]),
          });
        }
      }

      this._handleWORArticleLoading(false);

      this._handleFlashMsg(
        "Wellcome Open Research Article fetched successfully.",
        "success"
      );
    } catch (error) {
      this._handleWORArticleLoading(false);
      console.log("PLATFORM_QUERY_SEARCH error is : " + error);

      this._handleFlashMsg("Something went wrong", "error");
    }
  };

  _handleGetAAOSORArticleByDoi = async (articleDois) => {
    this._handleAAOSORArticleLoading(true);

    try {
      for (let i = 0; i < articleDois.length; i++) {
        let currentDoi = articleDois[i].currentDoi;
        let resdata = await axios.get(
          `${API_CONSTANTS.ARTICLE.PLATFORM_DOI_SEARCH}?platform=${articleDois[i].platform}&doi=${currentDoi}`
        );

        let resArticleData = resdata.data.data;
        if (resArticleData) {
          console.log("resArticleData", resArticleData);
          let data = await this._createCustomArticleSignature(
            articleDois[i].platform,
            currentDoi,
            resArticleData.articleJson,
            resArticleData.status
          );
          this.setState({
            allAAOSORArticles: this.state.allAAOSORArticles.concat([data]),
          });
        }
      }

      this._handleAAOSORArticleLoading(false);

      this._handleFlashMsg(
        "African Academy of science open research Article fetched successfully.",
        "success"
      );
    } catch (error) {
      this._handleAAOSORArticleLoading(false);
      console.log("PLATFORM_QUERY_SEARCH error is : " + error);

      this._handleFlashMsg("Something went wrong", "error");
    }
  };

  _handleGetGORArticleByDoi = async (articleDois) => {
    this._handleGORArticleLoading(true);

    try {
      for (let i = 0; i < articleDois.length; i++) {
        let currentDoi = articleDois[i].currentDoi;
        let resdata = await axios.get(
          `${API_CONSTANTS.ARTICLE.PLATFORM_DOI_SEARCH}?platform=${articleDois[i].platform}&doi=${currentDoi}`
        );

        let resArticleData = resdata.data.data;
        if (resArticleData) {
          console.log("resArticleData", resArticleData);
          let data = await this._createCustomArticleSignature(
            articleDois[i].platform,
            currentDoi,
            resArticleData.articleJson,
            resArticleData.status
          );
          this.setState({
            allGORArticles: this.state.allGORArticles.concat([data]),
          });
        } else {
          console.log("resArticleData is not getting");
        }
      }

      this._handleGORArticleLoading(false);

      this._handleFlashMsg(
        "Gates open research Article fetched successfully.",
        "success"
      );
    } catch (error) {
      this._handleGORArticleLoading(false);
      console.log("PLATFORM_QUERY_SEARCH error is : " + error);

      this._handleFlashMsg("Something went wrong", "error");
    }
  };

  getArticleTitle = (resArticleData) => {
    // article-title array -> resArticleData.elements[1].elements[0].elements[1].elements[2].elements[0].elements
    let titleElements = resArticleData.elements[1].elements[0].elements[1].elements[2].elements[0].elements
    let txt = ""
    
    titleElements.forEach((ele) => {
      if (ele.type == "text" && ele.text) {
        txt += ele.text
      } else if (ele.type == "element" && ele.elements[0].type == "text" && ele.elements[0].text) {
        txt += ele.elements[0].text
      }
    })

    txt = txt.replace( /[\r\n]+/gm, "" ).replace(/\s+/g,' ').trim()
    if (txt.length > 1000) txt = (txt.substring(0, 997) + '...')
    return txt.length ? txt : "NA"
  }

  _createCustomArticleSignature = async (
    platform,
    currentDoi,
    resArticleData,
    status
  ) => {
    try {
      let { author, roles } = contributorGroupHelper(
        resArticleData.elements[1].elements[0].elements[1].elements[3].elements
      );

      // let authorInfo = authorInformationHelper(
      //   resArticleData.elements[1].elements[0].elements[1].elements[3].elements
      // );

      let blurEffectFlag;

      if (status === PLATFORM_CONSTANTS.ARTICLE_STATUS.Rejected) {
        blurEffectFlag = true;
      } else {
        blurEffectFlag = false;
      }

      let pubStatusInfo = pubStatusHelper(
        resArticleData.elements[1].elements[0].elements[1].elements[2].elements
      );

      console.log("pubStatusInfo", pubStatusInfo);

      let introductionInfo = introductionHelper(
        resArticleData.elements[1].elements[1].elements[0].elements,
        resArticleData.elements[1].elements[0].elements[1].elements[12].elements //abstract
      );

      console.log("introductionInfo", introductionInfo);

      let objDescInfo = descriptionHelper(
        resArticleData.elements[1].elements[0].elements[1].elements[12].elements
      );

      console.log("objDescInfo", objDescInfo);

      let finalSign = {
        platform: platform ? platform : "NA",
        currentDoi: currentDoi ? currentDoi : "NA",
        journalMeta: {
          publisherName: resArticleData.elements[1].elements[0].elements[0]
            .elements[3].elements[0].elements[0].text
            ? resArticleData.elements[1].elements[0].elements[0].elements[3]
                .elements[0].elements[0].text
            : "NA",
          publisherLoc: resArticleData.elements[1].elements[0].elements[0]
            .elements[3].elements[1].elements[0].text
            ? resArticleData.elements[1].elements[0].elements[0].elements[3]
                .elements[1].elements[0].text
            : "NA",
        },
        articleMeta: {
          id: resArticleData.elements[1].elements[0].elements[1].elements[0]
            .elements[0].text
            ? resArticleData.elements[1].elements[0].elements[1].elements[0]
                .elements[0].text
            : "NA",
          articleType: resArticleData.elements[1].attributes["article-type"]
            ? resArticleData.elements[1].attributes["article-type"]
            : "NA",
          title: this.getArticleTitle(resArticleData),
          pubStatus: pubStatusInfo,
          introduction: introductionInfo,
          contribGroup: author ? author : "NA",
          authorInformation: roles ? roles : "NA",
          volume: resArticleData.elements[1].elements[0].elements[1].elements[7]
            .elements[0].text
            ? resArticleData.elements[1].elements[0].elements[1].elements[7]
                .elements[0].text
            : "NA",
          elocation_id: resArticleData.elements[1].elements[0].elements[1]
            .elements[8].elements[0].text
            ? resArticleData.elements[1].elements[0].elements[1].elements[8]
                .elements[0].text
            : "NA",
          objectives: [
            {
              title: "Course Objective",
              description: objDescInfo,
            },
          ],
        },
        selected: false,
        blurEffect: blurEffectFlag,
        status: status,
      };
      console.log("finalSign for currentDoi: ", finalSign);
      return finalSign;
    } catch (error) {
      console.log("create signature error ", error);
    }
  };

  _handlePlatformOnChange = (platform) => {
    this.setState({ platform });
  };

  _checkSelectedAssignArticleType = (checkselected) => {
    if (checkselected === true) {
      return true;
    } else {
      return false;
    }
  };

  handleArticleTypesSelection = (value) => {
    const { articleTypes } = this.state;

    //now we have updated all selected or non selected article types
    const array = articleTypes.slice(); //make copy of all article types

    let foundIndex = array.findIndex((element) => element.value === value);

    array[foundIndex].selected = !array[foundIndex].selected; //change the targeted article types object key selected set (true/false) acc to prev value

    this.setState({ articleTypes: array });
  };

  handleArticleTypes = (data) => {
    this.setState({ articleTypes: data });
  };

  handleresetSelectedArticleType = () => {
    const { articleTypes } = this.state;
    //here we selected to false for every licences
    const prevarrayreset = articleTypes.slice(); //make copy of all current licences

    for (var rl = 0; rl < prevarrayreset.length; rl++) {
      prevarrayreset[rl]["selected"] = false; //reset selected to false for each licences
    }
    return prevarrayreset;
  };

  handleArticleType = (resetArticleTypeSelectedData) => {
    this.setState({
      articleTypes: resetArticleTypeSelectedData,
    });
  };

  validateGetArticleCall = async () => {
    const { platform } = this.state;

    if (platform) {
      if (platform.length) {
        this._SwitchPlatformTab(platform[0].value);
      }

      return true;
    } else {
      this._handleFlashMsg("Please Select Platform", "error");
      return false;
    }
  };

  _handleSearchLoading = (status) => {
    this.setState({ loading: status });
  };
  _handleCompLoading = (status) => {
    this.setState({ compLoading: status });
  };

  _handleF1000ArticleLoading = (status) => {
    this.setState({ articleF1000Loading: status });
  };
  _handleWORArticleLoading = (status) => {
    this.setState({ articleWORLoading: status });
  };

  _handleAAOSORArticleLoading = (status) => {
    this.setState({ articleAAOSORLoading: status });
  };

  _handleGORArticleLoading = (status) => {
    this.setState({ articleGORLoading: status });
  };

  _SwitchPlatformTab = (platform) => {
    if (platform === PLATFORM_CONSTANTS.PLATFORM.F1000Research) {
      this._handleCurrentActiveTab("F1000Research");
    } else if (platform === PLATFORM_CONSTANTS.PLATFORM.WOR) {
      this._handleCurrentActiveTab("WOR");
    } else if (platform === PLATFORM_CONSTANTS.PLATFORM.AAOSOR) {
      this._handleCurrentActiveTab("AAOSOR");
    } else if (platform === PLATFORM_CONSTANTS.PLATFORM.GOR) {
      this._handleCurrentActiveTab("GOR");
    }
  };

  _handleCurrentActiveTab = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  resetComponentSearchState = () => {
    //call the child function
    this.f1000Child.resetF1000PaginationConfig();
    this.worChild.resetWORPaginationConfig();
    this.aaosorChild.resetAAOSORPaginationConfig();
    this.gorChild.resetGORPaginationConfig();

    this.setState({
      allF1000Articles: [],
      allWORArticles: [],
      allAAOSORArticles: [],
      allGORArticles: [],
      totalF1000Pages: 0,
      totalWORPages: 0,
      totalAAOSORPages: 0,
      totalGORPages: 0,
      articleF1000Loading: false,
      articleWORLoading: false,
      articleAAOSORLoading: false,
      articleGORLoading: false,
      loading: false,
      activeTab: "F1000Research",
    });
  };

  handlePlatformArticleSelection = (currentDoi, platform) => {
    const {
      allF1000Articles,
      allWORArticles,
      allAAOSORArticles,
      allGORArticles,
    } = this.state;
    console.log("user selected current article Doi ", currentDoi);

    let array;

    if (platform === PLATFORM_CONSTANTS.PLATFORM.F1000Research) {
      array = [...allF1000Articles]; //make copy of all article
    } else if (platform === PLATFORM_CONSTANTS.PLATFORM.WOR) {
      array = [...allWORArticles]; //make copy of all article
    } else if (platform === PLATFORM_CONSTANTS.PLATFORM.AAOSOR) {
      array = [...allAAOSORArticles]; //make copy of all article
    } else if (platform === PLATFORM_CONSTANTS.PLATFORM.GOR) {
      array = [...allGORArticles]; //make copy of all article
    }

    let foundIndex = array.findIndex(
      (element) => element.currentDoi == currentDoi
    );

    array[foundIndex].selected = !array[foundIndex].selected; //change the targeted article object key selected set (true/false) acc to prev value

    this.setPlatformArticles(platform, array);
  };

  setPlatformArticles = (currentPlatform, data) => {
    if (currentPlatform) {
      if (currentPlatform === PLATFORM_CONSTANTS.PLATFORM.F1000Research) {
        this.setState({ allF1000Articles: data });
      } else if (currentPlatform === PLATFORM_CONSTANTS.PLATFORM.WOR) {
        this.setState({ allWORArticles: data });
      } else if (currentPlatform === PLATFORM_CONSTANTS.PLATFORM.AAOSOR) {
        this.setState({ allAAOSORArticles: data });
      } else if (currentPlatform === PLATFORM_CONSTANTS.PLATFORM.GOR) {
        this.setState({ allGORArticles: data });
      }
    }
  };

  handleIncludeSelectedArticles = async (allSelectedDois, platform) => {
    try {
      this._handleCompLoading(true);

      let payload = {
        platform: platform,
        dois: allSelectedDois,
      };
      console.log("payload is ", payload);

      let resdata = await axios.post(
        `${API_CONSTANTS.ARTICLE.PLATFORM_ARTICLE_INCLUSION}`,
        payload
      );

      if (resdata) {
        if (platform === PLATFORM_CONSTANTS.PLATFORM.F1000Research) {
          this.handleF1000ArticleMarkInclusion(resdata.data.data, platform);
        } else if (platform === PLATFORM_CONSTANTS.PLATFORM.WOR) {
          this.handleWorArticleMarkInclusion(resdata.data.data, platform);
        } else if (platform === PLATFORM_CONSTANTS.PLATFORM.AAOSOR) {
          this.handleAaosorArticleMarkInclusion(resdata.data.data, platform);
        } else if (platform === PLATFORM_CONSTANTS.PLATFORM.GOR) {
          this.handleGorArticleMarkInclusion(resdata.data.data, platform);
        }
      }
    } catch (error) {
      console.log("error", error);
      this._handleCompLoading(false);
      this._handleFlashMsg(
        "Something went wrong during article inclusion",
        "error"
      );
    }
  };

  handleF1000ArticleMarkInclusion = (allSelectedDois, platform) => {
    const { allF1000Articles } = this.state;

    const array = [...allF1000Articles]; //make copy of all article

    for (let i = 0; i < allSelectedDois.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (allSelectedDois[i].doi == array[j].currentDoi) {
          array[j].selected = false;
          array[j].status = allSelectedDois[i].status; // must be 1
        }
      }
    }

    this.setState({ allF1000Articles: array });
    this._handleCompLoading(false);
    this._handleFlashMsg(
      `Article Inclusion Successfully For Platform ${platform}.`,
      "success"
    );
  };

  handleWorArticleMarkInclusion = (allSelectedDois, platform) => {
    const { allWORArticles } = this.state;

    const array = [...allWORArticles]; //make copy of all article

    for (let i = 0; i < allSelectedDois.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (allSelectedDois[i].doi == array[j].currentDoi) {
          array[j].selected = false;
          array[j].status = allSelectedDois[i].status; // must be 1
        }
      }
    }

    this.setState({ allWORArticles: array });
    this._handleCompLoading(false);
    this._handleFlashMsg(
      `Article Inclusion Successfully For Platform ${platform}.`,
      "success"
    );
  };

  handleAaosorArticleMarkInclusion = (allSelectedDois, platform) => {
    const { allAAOSORArticles } = this.state;

    const array = [...allAAOSORArticles]; //make copy of all article

    for (let i = 0; i < allSelectedDois.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (allSelectedDois[i].doi == array[j].currentDoi) {
          array[j].selected = false;
          array[j].status = allSelectedDois[i].status; // must be 1
        }
      }
    }

    this.setState({ allAAOSORArticles: array });
    this._handleCompLoading(false);
    this._handleFlashMsg(
      `Article Inclusion Successfully For Platform ${platform}.`,
      "success"
    );
  };

  handleGorArticleMarkInclusion = (allSelectedDois, platform) => {
    const { allGORArticles } = this.state;

    const array = [...allGORArticles]; //make copy of all article

    for (let i = 0; i < allSelectedDois.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (allSelectedDois[i].doi == array[j].currentDoi) {
          array[j].selected = false;
          array[j].status = allSelectedDois[i].status; // must be 1
        }
      }
    }

    this.setState({ allGORArticles: array });
    this._handleCompLoading(false);
    this._handleFlashMsg(
      `Article Inclusion Successfully For Platform ${platform}.`,
      "success"
    );
  };

  handleRejectSelectedArticles = async (allSelectedDois, platform) => {
    try {
      this._handleCompLoading(true);

      let payload = {
        platform: platform,
        dois: allSelectedDois,
      };

      console.log("payload is ", payload);

      let resdata = await axios.post(
        `${API_CONSTANTS.ARTICLE.PLATFORM_ARTICLE_REJECTION}`,
        payload
      );

      if (resdata) {
        if (platform === PLATFORM_CONSTANTS.PLATFORM.F1000Research) {
          this.handleF1000ArticleMarkRejection(resdata.data.data, platform);
        } else if (platform === PLATFORM_CONSTANTS.PLATFORM.WOR) {
          this.handleWorArticleMarkRejection(resdata.data.data, platform);
        } else if (platform === PLATFORM_CONSTANTS.PLATFORM.AAOSOR) {
          this.handleAaosorArticleMarkRejection(resdata.data.data, platform);
        } else if (platform === PLATFORM_CONSTANTS.PLATFORM.GOR) {
          this.handleGorArticleMarkRejection(resdata.data.data, platform);
        }
      }
    } catch (error) {
      console.log("error", error);
      this._handleCompLoading(false);
      this._handleFlashMsg(
        "Something went wrong during article inclusion",
        "error"
      );
    }
  };

  handleF1000ArticleMarkRejection = (allSelectedDois, platform) => {
    const { allF1000Articles } = this.state;

    const array = [...allF1000Articles]; //make copy of all article

    for (let i = 0; i < allSelectedDois.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (allSelectedDois[i].doi == array[j].currentDoi) {
          array[j].selected = false;
          array[j].status = allSelectedDois[i].status; // must be 2
        }
      }
    }

    this.setState({ allF1000Articles: array });
    this._handleCompLoading(false);
    this._handleFlashMsg(
      `Article Rejected Successfully For Platform ${platform}.`,
      "success"
    );
  };
  handleWorArticleMarkRejection = (allSelectedDois, platform) => {
    const { allWORArticles } = this.state;

    const array = [...allWORArticles]; //make copy of all article

    for (let i = 0; i < allSelectedDois.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (allSelectedDois[i].doi == array[j].currentDoi) {
          array[j].selected = false;
          array[j].status = allSelectedDois[i].status; // must be 2
        }
      }
    }

    this.setState({ allWORArticles: array });
    this._handleCompLoading(false);
    this._handleFlashMsg(
      `Article Rejected Successfully For Platform ${platform}.`,
      "success"
    );
  };
  handleAaosorArticleMarkRejection = (allSelectedDois, platform) => {
    const { allAAOSORArticles } = this.state;

    const array = [...allAAOSORArticles]; //make copy of all article

    for (let i = 0; i < allSelectedDois.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (allSelectedDois[i].doi == array[j].currentDoi) {
          array[j].selected = false;
          array[j].status = allSelectedDois[i].status; // must be 2
        }
      }
    }

    this.setState({ allAAOSORArticles: array });
    this._handleCompLoading(false);
    this._handleFlashMsg(
      `Article Rejected Successfully For Platform ${platform}.`,
      "success"
    );
  };
  handleGorArticleMarkRejection = (allSelectedDois, platform) => {
    const { allGORArticles } = this.state;

    const array = [...allGORArticles]; //make copy of all article

    for (let i = 0; i < allSelectedDois.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (allSelectedDois[i].doi == array[j].currentDoi) {
          array[j].selected = false;
          array[j].status = allSelectedDois[i].status; // must be 2
        }
      }
    }

    this.setState({ allGORArticles: array });
    this._handleCompLoading(false);
    this._handleFlashMsg(
      `Article Rejected Successfully For Platform ${platform}.`,
      "success"
    );
  };

  handleOverlaySelection = (platform, data) => {
    if (platform === PLATFORM_CONSTANTS.PLATFORM.F1000Research) {
      this.setState({ allF1000Articles: data });
    } else if (platform === PLATFORM_CONSTANTS.PLATFORM.WOR) {
      this.setState({ allWORArticles: data });
    } else if (platform === PLATFORM_CONSTANTS.PLATFORM.AAOSOR) {
      this.setState({ allAAOSORArticles: data });
    } else if (platform === PLATFORM_CONSTANTS.PLATFORM.GOR) {
      this.setState({ allGORArticles: data });
    }
  };

  _handleFlashMsg = (msg, type) => {
    console.log("msg recieved", msg);

    toast(msg, { type: type });

    // this.props.sendFlashMessage(msg, type);
    // setTimeout(() => {
    //   this.props.clearcurrentFlashMessage();
    // }, 2000);
  };

  render() {
    const {
      activeTab,
      allF1000Articles,
      allWORArticles,
      allAAOSORArticles,
      allGORArticles,
      totalF1000Pages,
      totalWORPages,
      totalAAOSORPages,
      totalGORPages,
      platform,
      searchKeyword,
      searchTitle,
      articleTypes,
      articleSearchStr,
      wt,
      searchDoi,
      f1000multiselectarr,
      wormultiselectarr,
      aaosormultiselectarr,
      gormultiselectarr,
      articleF1000Loading,
      articleWORLoading,
      articleAAOSORLoading,
      articleGORLoading,
      compLoading,
      loading,
    } = this.state;

    //let allArticleCont;
    let finalArticleTypeCont;

    // Select options for platformOpt
    const platformOpt = [
      {
        label: "F1000Research",
        value: PLATFORM_CONSTANTS.PLATFORM.F1000Research,
      },
      {
        label: "Wellcome Open Research",
        value: PLATFORM_CONSTANTS.PLATFORM.WOR,
      },
      {
        label: "African Academy of Sciences Open Research",
        value: PLATFORM_CONSTANTS.PLATFORM.AAOSOR,
      },
      {
        label: "Gates Open Research",
        value: PLATFORM_CONSTANTS.PLATFORM.GOR,
      },
    ];

    let filteredArticleTypes = articleTypes;

    articleSearchStr &&
      (filteredArticleTypes = filteredArticleTypes.filter((data) =>
        data.value.toUpperCase().startsWith(articleSearchStr.toUpperCase())
      ));

    if (articleTypes) {
      let articleTypeData = filteredArticleTypes.map((data, index) => {
        return (
          <li key={index}>
            <label>
              {data.label}
              <span className="custom-checkbox">
                <input
                  type="checkbox"
                  name="group2"
                  checked={this._checkSelectedAssignArticleType(data.selected)}
                  value={this.state.f1000multiselectarr[index]}
                  onChange={(e) => this.handleArticleTypesSelection(data.value)}
                />
                <span className="checkbox-check"></span>
              </span>
            </label>
          </li>
        );
      });

      finalArticleTypeCont = articleTypeData;
    }

    return (
      <section className="wrapper">
        <Header />
        {/*} <Sidebar activeStatus={"organisation"} />*/}
        <section className="">
          <Flashmessages />
          <div className="container-fluid">
            <div className="spacetb25">
              <nav className="breadcrumb__list">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <Link to="/organisation">Organisation</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Articles
                  </li>
                </ol>
              </nav>
              <form onSubmit={this._handleGetArticles}>
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-4">
                    <h5 className="mg-b-10">
                      <strong>Select Platform</strong>
                    </h5>
                    <div className="form-select mg-b-20">
                      <Select
                        name="platform"
                        value={platform}
                        onChange={this._handlePlatformOnChange}
                        options={platformOpt}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-4">
                    <h5 className="mg-b-10">
                      <strong>Select Articles Types</strong>
                    </h5>
                    <div className="form-group mg-b-20">
                      <a
                        href="!#"
                        className="add-licence-select"
                        data-toggle="modal"
                        data-target="#articleTypeMultiSelectModal"
                      >
                        Article Types...
                        <span className="icon-plus addicons"></span>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-4">
                    <h5 className="mg-b-10">
                      <strong>Search Abstract Keyword</strong>
                    </h5>
                    <div className="search__group mg-b-20">
                      <input
                        name="searchKeyword"
                        type="text"
                        value={searchKeyword}
                        className="form-control"
                        placeholder="Search"
                        onChange={this._handleOnChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-4">
                    <h5 className="mg-b-10">
                      <strong>Search Title</strong>
                    </h5>
                    <div className="search__group mg-b-20">
                      <input
                        name="searchTitle"
                        type="text"
                        value={searchTitle}
                        className="form-control"
                        placeholder="Search"
                        onChange={this._handleOnChange}
                      />
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-icon-xs"
                        disabled={
                          articleF1000Loading ||
                          articleWORLoading ||
                          articleAAOSORLoading ||
                          articleGORLoading ||
                          loading
                        }
                      >
                        {loading ? (
                          <span
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          >
                            Loading...
                          </span>
                        ) : (
                          <span className="d-none d-md-block">Search</span>
                        )}

                        <i className="icon-search d-block d-md-none"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-4">
                      <h5 className="mg-b-10">
                        <strong>Search DOI</strong>
                      </h5>
                      <div className="search__group mg-b-20">
                        <input
                          name="searchDoi"
                          type="text"
                          value={searchDoi}
                          className="form-control"
                          placeholder="Search"
                          onChange={this._handleOnChange}
                        />
                      </div>
                    </div>
                </div>
              </form>

              <div className="row">
                <div className="col-12">
                  <div
                    class="nav nav-tabs artice-tabs"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      class={`nav-item nav-link ${
                        activeTab === "F1000Research" ? "active" : ""
                      }`}
                      id="F1000-tab"
                      data-toggle="tab"
                      href="#F1000Research"
                      role="tab"
                      aria-controls="F1000Research"
                      aria-selected="true"
                      onClick={(e) => {
                        e.preventDefault();
                        this._handleCurrentActiveTab("F1000Research");
                      }}
                    >
                      F1000Research{" "}
                      <span class="badge badge-pill badge-success">
                        {allF1000Articles.length}
                      </span>
                    </a>
                    <a
                      class={`nav-item nav-link ${
                        activeTab === "WOR" ? "active" : ""
                      }`}
                      id="WOR-tab"
                      data-toggle="tab"
                      href="#wor"
                      role="tab"
                      aria-controls="wor"
                      aria-selected="false"
                      onClick={(e) => {
                        e.preventDefault();
                        this._handleCurrentActiveTab("WOR");
                      }}
                    >
                      Wellcome Open Research{" "}
                      <span class="badge badge-pill badge-success">
                        {allWORArticles.length}
                      </span>
                    </a>
                    <a
                      class={`nav-item nav-link ${
                        activeTab === "AAOSOR" ? "active" : ""
                      }`}
                      id="AAOSOR-tab"
                      data-toggle="tab"
                      href="#aaosor"
                      role="tab"
                      aria-controls="aaosor"
                      aria-selected="false"
                      onClick={(e) => {
                        e.preventDefault();
                        this._handleCurrentActiveTab("AAOSOR");
                      }}
                    >
                      African Academy Sciences of Open Research{" "}
                      <span class="badge badge-pill badge-success">
                        {allAAOSORArticles.length}
                      </span>
                    </a>
                    <a
                      class={`nav-item nav-link ${
                        activeTab === "GOR" ? "active" : ""
                      }`}
                      id="GOR-tab"
                      data-toggle="tab"
                      href="#gor"
                      role="tab"
                      aria-controls="gor"
                      aria-selected="false"
                      onClick={(e) => {
                        e.preventDefault();
                        this._handleCurrentActiveTab("GOR");
                      }}
                    >
                      Gates Open Research{" "}
                      <span class="badge badge-pill badge-success">
                        {allGORArticles.length}
                      </span>
                    </a>
                  </div>
                  <div class="tab-content" id="nav-tabContent">
                    <div
                      class={`tab-pane fade ${
                        activeTab === "F1000Research" ? "show active" : ""
                      }`}
                      id="F1000Research"
                      role="tabpanel"
                      aria-labelledby="F1000-tab"
                    >
                      {/* Note that you need to give a value to the ref parameter, in this case child*/}

                      <F1000Research
                        onRef={(ref) => (this.f1000Child = ref)}
                        allF1000Articles={allF1000Articles}
                        totalF1000Pages={totalF1000Pages}
                        _checkArticleTypeSelectStatus={
                          this._checkArticleTypeSelectStatus
                        }
                        searchKeyword={searchKeyword}
                        searchTitle={searchTitle}
                        wt={wt}
                        getCurrentArticles={this.getCurrentArticles}
                        f1000multiselectarr={f1000multiselectarr}
                        handlePlatformArticleSelection={
                          this.handlePlatformArticleSelection
                        }
                        handleIncludeSelectedArticles={
                          this.handleIncludeSelectedArticles
                        }
                        handleRejectSelectedArticles={
                          this.handleRejectSelectedArticles
                        }
                        handleOverlaySelection={this.handleOverlaySelection}
                        _handleFlashMsg={this._handleFlashMsg}
                        articleF1000Loading={articleF1000Loading}
                      />
                    </div>

                    <div
                      class={`tab-pane fade ${
                        activeTab === "WOR" ? "show active" : ""
                      }`}
                      id="wor"
                      role="tabpanel"
                      aria-labelledby="WOR-tab"
                    >
                      <WellcomeOpenResearch
                        onRef={(ref) => (this.worChild = ref)}
                        allWORArticles={allWORArticles}
                        totalWORPages={totalWORPages}
                        _checkArticleTypeSelectStatus={
                          this._checkArticleTypeSelectStatus
                        }
                        searchKeyword={searchKeyword}
                        searchTitle={searchTitle}
                        wt={wt}
                        getCurrentArticles={this.getCurrentArticles}
                        wormultiselectarr={wormultiselectarr}
                        handlePlatformArticleSelection={
                          this.handlePlatformArticleSelection
                        }
                        handleIncludeSelectedArticles={
                          this.handleIncludeSelectedArticles
                        }
                        handleRejectSelectedArticles={
                          this.handleRejectSelectedArticles
                        }
                        handleOverlaySelection={this.handleOverlaySelection}
                        _handleFlashMsg={this._handleFlashMsg}
                        articleWORLoading={articleWORLoading}
                      />
                    </div>
                    <div
                      class={`tab-pane fade ${
                        activeTab === "AAOSOR" ? "show active" : ""
                      }`}
                      id="aaosor"
                      role="tabpanel"
                      aria-labelledby="AAOSOR-tab"
                    >
                      <AAOSOR
                        onRef={(ref) => (this.aaosorChild = ref)}
                        allAAOSORArticles={allAAOSORArticles}
                        totalAAOSORPages={totalAAOSORPages}
                        _checkArticleTypeSelectStatus={
                          this._checkArticleTypeSelectStatus
                        }
                        searchKeyword={searchKeyword}
                        searchTitle={searchTitle}
                        wt={wt}
                        getCurrentArticles={this.getCurrentArticles}
                        aaosormultiselectarr={aaosormultiselectarr}
                        handlePlatformArticleSelection={
                          this.handlePlatformArticleSelection
                        }
                        handleIncludeSelectedArticles={
                          this.handleIncludeSelectedArticles
                        }
                        handleRejectSelectedArticles={
                          this.handleRejectSelectedArticles
                        }
                        handleOverlaySelection={this.handleOverlaySelection}
                        _handleFlashMsg={this._handleFlashMsg}
                        articleAAOSORLoading={articleAAOSORLoading}
                      />
                    </div>

                    <div
                      class={`tab-pane fade ${
                        activeTab === "GOR" ? "show active" : ""
                      }`}
                      id="gor"
                      role="tabpanel"
                      aria-labelledby="GOR-tab"
                    >
                      <GOR
                        onRef={(ref) => (this.gorChild = ref)}
                        allGORArticles={allGORArticles}
                        totalGORPages={totalGORPages}
                        _checkArticleTypeSelectStatus={
                          this._checkArticleTypeSelectStatus
                        }
                        searchKeyword={searchKeyword}
                        searchTitle={searchTitle}
                        wt={wt}
                        getCurrentArticles={this.getCurrentArticles}
                        gormultiselectarr={gormultiselectarr}
                        handlePlatformArticleSelection={
                          this.handlePlatformArticleSelection
                        }
                        handleIncludeSelectedArticles={
                          this.handleIncludeSelectedArticles
                        }
                        handleRejectSelectedArticles={
                          this.handleRejectSelectedArticles
                        }
                        handleOverlaySelection={this.handleOverlaySelection}
                        _handleFlashMsg={this._handleFlashMsg}
                        articleGORLoading={articleGORLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ArticleTypeModal
            articleSearchStr={articleSearchStr}
            _handleOnChange={this._handleOnChange}
            finalArticleTypeCont={finalArticleTypeCont}
            handleresetSelectedArticleType={this.handleresetSelectedArticleType}
            handleArticleTypes={this.handleArticleTypes}
          />
          <Loader loadStatus={compLoading} />
        </section>
      </section>
    );
  }
}

Articles.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors, //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(Articles));
