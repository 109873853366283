import React, { useEffect, useState } from "react";

const Editor = ({ json, onUpdate, name }) => {
  const [input, setInput] = useState();
  const [err, setErr] = useState(false);

  useEffect(() => {
    if (json) {
      try {
        const parsedJson = JSON.parse(json);
        setInput(JSON.stringify(parsedJson, null, 2)); // Set stringified JSON as input
      } catch (error) {
        setErr(true);
        console.error("Invalid JSON input:", error);
      }
    }
    if (json == "") {
      setInput("");
      setErr(false);
    }
  }, [json]);

  const handleInputChange = (event) => {
    setErr(false);
    setInput(event.target.value);
  };

  const handleUpdate = () => {
    try {
      const parsedJson = JSON.parse(input);
      console.log({ input, parsedJson });
      onUpdate({
        target: {
          name,
          value: JSON.stringify(parsedJson), // Save the parsed JSON as a string
        },
      });
    } catch (error) {
      setErr(true);
      console.error("Invalid JSON input:", error);
    }
  };

  return (
    <div>
      <textarea
        className="form-control"
        value={input}
        placeholder="{}"
        onChange={handleInputChange}
        onBlur={handleUpdate}
        rows={10}
        cols={50}
      />
      {err && <p className="text-danger">Invalid JSON input</p>}
    </div>
  );
};

export default Editor;
