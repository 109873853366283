import React, { Component } from "react";
import axios from "axios";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";

import Header from "../layout/Header";

import isEmpty from "../../validation/is-empty";

import Loader from "../common/GlobalLoader";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Flashmessages from "../flashMessagesComp/flashMessages";

class Addgroupsubgroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allcouncilarrobjs: null,
      councilnameoptions: null,
      councilname: null,
      allgroupsarrobjs: null,
      onlygroups: null,
      groupoptions: null,
      groups: null,
      newgroup: "",
      groupssubgroups: "",
      onlysubgroups: null,
      newsubgroup: "",
      subgroupoptions: null,
      subgroups: null,
      multiselectarr: [],
      finalmultiselectarr: [],
      role: "wceaadmin",
      files: [],
      errors: {},
      selectedOption: null,
      loading: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.handleGetCouncils();
    /* axios.get("/api/v1/wcea/groups",config).then(res => {
            this.setState({allgroupsarrobjs:res.data})
             var onlygroupsData =  this.state.allgroupsarrobjs.filter(function(grp) {
                return grp.parentId === null;
              });
              console.log(onlygroupsData)
              this.setState({onlygroups:onlygroupsData})
            }).catch(err=>{
              console.log("error is : "+err)
          //   alert("Something went wrong!!")
            })
      */
    /*var onlygroupsData =  this.state.allgroupsarrobjs.filter(function(grp) {
              return grp.parentId === null;
            });
     console.log(onlygroupsData)
      this.setState({onlygroups:onlygroupsData})*/
  }

  handleGetCouncils = async () => {
    try {
      this.setState({ loading: true });
      let resdata = await axios.get(
        `${API_CONSTANTS.COUNCIL.GET_ALL_COUNCILS}`
      );
      this.setState({ allcouncilarrobjs: resdata.data, loading: false });
      this.handlePreSelectedCouncil();
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  handlePreSelectedCouncil = () => {
    const { allcouncilarrobjs } = this.state;
    let id = this.props.match.params.id;

    var councilData = allcouncilarrobjs.filter(function(grp) {
      return grp.id == id;
    });
    let councilPreSelected = {
      label: councilData[0].name,
      value: councilData[0].id,
    };

    this.handlecouncilnameChange(councilPreSelected);
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { councilname, groups, groupssubgroups } = this.state;

    let isValidcouncilname = isEmpty(councilname); //if it return true its mean object is empty in case of false not empty
    console.log("councilname validate value is : " + isValidcouncilname);

    let isValidgroups = isEmpty(groups); //if it return true its mean object is empty in case of false not empty
    console.log("groups validate value is : " + isValidgroups);

    if (isValidcouncilname) {
      this.props.sendFlashMessage(
        "Please Select Organisation Name !!",
        "alert-danger"
      );
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);
    } else if (isValidgroups && !groupssubgroups) {
      this.props.sendFlashMessage(
        "Please Select Group or Enter Group/Subgroup inside text field !!",
        "alert-danger"
      );
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);
    } else {
      console.log("final Data is below : ");

      const CreategroupsubData = {
        councilId: councilname.value,
        groupId: isValidgroups ? null : groups.value, //if groups obj is empty then assign null otherwise group id
        groupName: groupssubgroups, //value comes from textfield
      };
      console.log(CreategroupsubData);

      this.setState({ loading: true });

      axios
        .post(
          `${API_CONSTANTS.COUNCIL.ADD_GROUPS}/${councilname.value}/groups`,
          CreategroupsubData
        )
        .then((res) => {
          this.setState({
            councilname: null,
            groups: null,
            groupssubgroups: null,
            loading: false,
          });

          this.props.sendFlashMessage(
            "Organisation Group created!!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        })
        .catch((err) => {
          console.log("error is : " + err);
          this.props.sendFlashMessage(
            "Something went wrong !!",
            "alert-danger"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);

          this.setState({ loading: false });
          //   alert("Something went wrong!!")
        });
    }
  }

  _handlemultiselectgroup = (data) => {
    const { finalmultiselectarr } = this.state;

    //here we make multiselected array of user id

    //first we check inside array if user id is found then we remove from the array
    //otherwise we will push the user id in array
    console.log("selected licence data id is : " + data.id);
    //here we first check inside array of object if in any object
    //there id found so we will splice object otherwise we insert new object with id and name
    let idfound = true;
    for (var i = 0; i < finalmultiselectarr.length; i++) {
      if (finalmultiselectarr[i].id === data.id) {
        console.log("id found inside array of object");

        finalmultiselectarr.splice(i, 1);

        idfound = false;
      }
    }

    if (idfound) {
      console.log("new entry");

      let newentry = {
        id: data.id,
        name: data.name,
      };
      finalmultiselectarr.unshift(newentry);
    }
  };

  // Callback~
  getFiles(file) {
    this.setState({ councillogo: file });
  }

  handlecouncilnameChange = async (councilname) => {
    const { groups } = this.state;
    // this.setState({
    //   allgroupsarrobjs: null,
    //   onlygroups: null,
    //   groups: null,
    //   onlysubgroups: null,
    // });
    this.setState({ councilname });

    let grpData = await this.handleGetGroups(councilname.value);

    if (grpData) {
      var onlygroupsData = grpData.subgroups.filter(function(grp) {
        return grp.parentId === null;
      });
      console.log("onlygroupsData", onlygroupsData);
      this.setState({
        allgroupsarrobjs: grpData.subgroups,
        onlygroups: onlygroupsData,
      });

      if (groups === null) this.handlegroupsChange(onlygroupsData[0]);
    }
  };

  handleGetGroups = async (councilname) => {
    try {
      let resdata = await axios.get(
        `${API_CONSTANTS.COUNCIL.GET_GROUPS}/${councilname}/groups/sub`
      );
      return resdata.data;
    } catch (error) {
      console.log(error);
    }
  };

  handlegroupsChange = (groups) => {
    // e.preventDefault();
    this.setState({ groups });
    console.log(`selected group :`, groups);

    this.handlesubgroupsChange(groups.id);
    // let groupid=groups.value
    //this.handlesetsubgroup(groupid)
  };

  handlesubgroupsChange = (groupid) => {
    const { allgroupsarrobjs } = this.state;
    var onlysubgroupsData = allgroupsarrobjs.filter(function(grp) {
      return grp.parentId === groupid;
    });
    console.log(`selected subgroup:`, onlysubgroupsData);
    this.setState({ onlysubgroups: onlysubgroupsData });
  };

  onSubmitAddGroup = async (e) => {
    e.preventDefault();
    const { councilname, groups, newgroup } = this.state;

    let isValidcouncilname = isEmpty(councilname); //if it return true its mean object is empty in case of false not empty
    let isValidnewgroup = isEmpty(newgroup); //if it return true its mean object is empty in case of false not empty

    try {
      if (isValidcouncilname) {
        this.props.sendFlashMessage(
          "Please select organisation name !!",
          "alert-danger"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      } else if (isValidnewgroup) {
        this.props.sendFlashMessage(
          "Please enter new group name !!",
          "alert-danger"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      } else {
        const CreategroupsubData = {
          councilId: councilname.value,
          groupId: null,
          groupName: newgroup, //value comes from textfield
        };

        this.setState({ loading: true });

        let statusRes = await this.groupPostCall(CreategroupsubData);

        if (statusRes) {
          await this.handlecouncilnameChange(councilname);
          this.props.sendFlashMessage(
            "Organisation Group created!!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ newgroup: "", loading: false });
        }
      }
    } catch (error) {
      console.log(error);
      this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);

      this.setState({ loading: false });
    }
  };

  onSubmitAddSubGroup = async (e) => {
    e.preventDefault();
    const { councilname, groups, newsubgroup } = this.state;

    let isValidcouncilname = isEmpty(councilname); //if it return true its mean object is empty in case of false not empty
    let isValidgroups = isEmpty(groups);
    let isValidsubnewgroup = isEmpty(newsubgroup);

    try {
      if (isValidcouncilname) {
        this.props.sendFlashMessage(
          "Please select organisation name !!",
          "alert-danger"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      } else if (isValidgroups) {
        this.props.sendFlashMessage("Please select group !!", "alert-danger");
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      } else if (isValidsubnewgroup) {
        this.props.sendFlashMessage(
          "Please enter new sub group name !!",
          "alert-danger"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      } else {
        const CreategroupsubData = {
          councilId: councilname.value,
          groupId: groups.id, //if groups obj is empty then assign null otherwise group id
          groupName: newsubgroup, //value comes from textfield
        };

        console.log(CreategroupsubData);
        this.setState({ loading: true });

        let statusRes = await this.groupPostCall(CreategroupsubData);

        if (statusRes) {
          await this.handlecouncilnameChange(councilname);
          this.handlegroupsChange(groups);
          this.props.sendFlashMessage(
            "Organisation subgroup created!!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ newsubgroup: "", loading: false });
        }
      }
    } catch (error) {
      console.log(error);
      this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);

      this.setState({ loading: false });
    }
  };

  groupPostCall = async (CreategroupsubData) => {
    const { councilname } = this.state;
    try {
      let resdata = await axios.post(
        `${API_CONSTANTS.COUNCIL.ADD_GROUPS}/${councilname.value}/groups`,
        CreategroupsubData
      );
      return resdata;
    } catch (error) {
      console.log(error);
    }
  };

  handleRemoveGroup = (e, data) => {
    e.preventDefault();
    console.log("will remove group id", data.id);
    this.props.sendFlashMessage("Feature is coming soon", "alert-info");
    setTimeout(() => {
      this.props.clearcurrentFlashMessage();
    }, 2000);
  };

  handleRemoveSubGroup = (e, data) => {
    e.preventDefault();
    console.log("will remove sub group id", data.id);
    this.props.sendFlashMessage("Feature is coming soon", "alert-info");
    setTimeout(() => {
      this.props.clearcurrentFlashMessage();
    }, 2000);
  };

  handlesetsubgroup = (groupid) => {
    var onlysubgroupsData = this.state.allgroupsarrobjs.filter(function(grp) {
      return grp.parentId === groupid;
    });

    console.log("id is : " + groupid);

    console.log("only subgroups is : ");
    console.log(onlysubgroupsData);
    this.setState({ onlysubgroups: onlysubgroupsData });
  };

  render() {
    //const { isAuthenticated, user } = this.props.auth;
    //const { errors } = this.state;

    const {
      councilname,
      groups,
      onlygroups,
      newgroup,
      onlysubgroups,
      newsubgroup,
      allcouncilarrobjs,
      groupssubgroups,
      loading,
    } = this.state;

    let councilnameoptions;
    let allcouncilarrobjsloading;
    if (allcouncilarrobjs === null) {
      allcouncilarrobjsloading = "";
    } else {
      if (allcouncilarrobjs && Object.keys(allcouncilarrobjs).length > 0) {
        councilnameoptions = allcouncilarrobjs.map((w) => ({
          label: w.name,
          value: w.id,
        }));
      }
    }

    let actualonlygroups;
    let allgroupsarrobjsloading;
    if (onlygroups === null) {
      allgroupsarrobjsloading = "";
    } else {
      if (onlygroups && Object.keys(onlygroups).length > 0) {
        //TotalonlygroupsItem = Object.keys(onlygroups).length;
        // Select options for onlygroups
        actualonlygroups = onlygroups.map((w) => ({
          label: w.name,
          value: w.id,
        }));
      }
    }

    /*
        let actualallsubgroupopt
        let allonlysubgroupsloading
        if (onlysubgroups === null ) {
          allonlysubgroupsloading = "";
        } else {
          if (onlysubgroups && Object.keys(onlysubgroups).length > 0) {
              // Select options for allgroupsarrobjs
            actualallsubgroupopt = onlysubgroups.map(w => ({
              label: w.name,
              value: w.id
            }));
     
          }
        }
        */

    let GroupsListCont;
    if (onlygroups === null) {
      GroupsListCont = "";
    } else {
      if (onlygroups && Object.keys(onlygroups).length > 0) {
        let GroupsListData = onlygroups.map((data, index) => {
          return (
            <span
              class={`text__licence ${
                groups !== null && groups.id == data.id ? "active" : ""
              }`}
              key={index}
              onClick={(e) => this.handlegroupsChange(data)}
            >
              {data.name}
              <a
                href="javascript:void(0)"
                class="icon-cross clos-icon"
                onClick={(e) => this.handleRemoveGroup(e, data)}
              ></a>
            </span>
          );
        });

        GroupsListCont = GroupsListData;
      }
    }

    let SubgroupsListCont;
    if (onlysubgroups === null) {
      SubgroupsListCont = "";
    } else {
      if (onlysubgroups && Object.keys(onlysubgroups).length > 0) {
        let subgroupsListData = onlysubgroups.map((data, index) => {
          return (
            <span class={`text__licence`} key={index}>
              {data.name}
              <a
                href="javascript:void(0)"
                class="icon-cross clos-icon"
                onClick={(e) => this.handleRemoveSubGroup(e, data)}
              ></a>
            </span>
          );
        });

        SubgroupsListCont = subgroupsListData;
      }
    }

    /**
     * The options array should contain objects.
     * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
     */

    return (
      <section class="wrapper">
        <Header />
        {/*<Sidebar activeStatus={"addGroupSubGroup"} />*/}
        <section class="">
          <Flashmessages />
          <div class="container-fluid">
            <div class="spacetb25">
              <nav class="breadcrumb__list">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    {" "}
                    <Link to="/organisation">Organisation</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Add Group-Subgroup
                  </li>
                </ol>
              </nav>
              <div class="row">
                <div class="col-xl-8 offset-xl-2">
                  <div class="card card__custom">
                    <div class="card-header">
                      <h5 class="card-title">
                        {councilname && councilname.label
                          ? councilname.label
                          : ""}
                      </h5>
                      {/*} <Link
                        to="/edit-group-subgroup"
                        class="btn btn-primary btn-sm with-icons-btn"
                      >
                        <span class="icon-edit"></span>
                        Edit
                       </Link>*/}
                    </div>
                    <div class="card-body">
                      {/*} <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group textbox__label">
                            <label>Organisation Name</label>
                            {allcouncilarrobjsloading ? (
                              <span>Organisation Loading...</span>
                            ) : (
                              <Select
                                value={councilname}
                                onChange={this.handlecouncilnameChange}
                                options={councilnameoptions}
                              />
                            )}
                          </div>
                        </div>
                            </div>*/}

                      {councilname && (
                        <div class="">
                          <h5 class="mg-b-17">
                            <strong>Groups</strong>
                          </h5>
                          <div class="select__licence">{GroupsListCont}</div>

                          <div class="search__add--group">
                            <input
                              type="text"
                              class="form-control"
                              onChange={this.onChange}
                              placeholder="Enter Group Name"
                              name="newgroup"
                              value={newgroup}
                            />

                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              onClick={this.onSubmitAddGroup}
                            >
                              Add Group
                            </button>
                          </div>
                        </div>
                      )}

                      {councilname && groups !== null && (
                        <div class="group__section">
                          <h5 class="mg-b-17">
                            <strong>Sub Groups</strong>
                          </h5>
                          <div class="select__licence">{SubgroupsListCont}</div>
                          <div class="search__add--group">
                            <input
                              type="text"
                              class="form-control"
                              onChange={this.onChange}
                              placeholder="Enter Sub Group Name"
                              name="newsubgroup"
                              value={newsubgroup}
                            />

                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              onClick={this.onSubmitAddSubGroup}
                            >
                              Add Sub Group
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Loader loadStatus={loading} />
      </section>
    );
  }
}

Addgroupsubgroup.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors, //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(Addgroupsubgroup));
