import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";

import matchSorter from "match-sorter";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import SessionStrorageService from "../../shared/utils/SessionStrorageService";
import Header from "../layout/Header";
import Sidebar from "../layout/sidebar";

import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Flashmessages from "../flashMessagesComp/flashMessages";

import Pagination from "../common/Pagination";

import "./organisation.css";

import DummyOrgData from "./dummyorg";
import Spinner from "../dashboard/spinners/Spinner";
import Loader from "../common/GlobalLoader";

//const rawData = makeData();

// SessionStrorageService
const sessionStrorageService = SessionStrorageService.getService();

class Organisation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      loading: false,
      pages: null,
      setorganisationloading: true,
      filtered: [],
      sorted: [],
    };

    this.setLatestOrganisation = this.setLatestOrganisation.bind(this);

    this.reactContactTable = React.createRef();
  }

  componentDidMount() {
    // const currentreactcontactTable = this.reactContactTable.current;
    // if(currentreactcontactTable){
    //   const page = currentreactcontactTable.state.page;
    //   const pageSize = currentreactcontactTable.state.pageSize;
    //   console.log("from compdidmount page is : "+page)
    //   console.log("from compdidmount pageSize is : "+pageSize)
    //   const currentrctstatusobj={
    //     page:page,
    //     pageSize:pageSize
    //   }
    //   this.setLatestOrganisation(currentrctstatusobj)
    // }
    this._handleInitOrgFilter();
    this._handleGetCouncilUsers();

    const orgFilters = this._handleGetOrgFilter();

    if (orgFilters) {
      let fObj = JSON.parse(orgFilters);
      this.onFilteredChangeCustom(fObj.councilName, "councilName");
      this.onFilteredChangeCustom(fObj.lastName, "lastName");
      this.onFilteredChangeCustom(fObj.country, "country");
    }
  }

  _handleGetCouncilUsers = () => {
    this.setState({ setorganisationloading: true });
    //for getting all councils
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_ALL_COUNCILS_USERS}`)
      .then((res) => {
        this.setState({ setorganisationloading: false });
        this.setState({ organisationdtall: res.data });
        console.log("councils are below : ");
        console.log(res.data);
      })
      .catch((error) => {
        this.setState({ setorganisationloading: false });
        console.log("council get error is : " + error);
      });
  };

  onEditOrgUserClick = (id) => {
    console.log("organisation id is : " + id);
    this.props.sendFlashMessage("Feature is coming soon !!", "alert-warning");
    setTimeout(() => {
      this.props.clearcurrentFlashMessage();
    }, 2000);
  };

  onDeleteOrgUserClick(id) {
    console.log("organisation id is : " + id);
    this.props.sendFlashMessage("Feature is coming soon !!", "alert-warning");
    setTimeout(() => {
      this.props.clearcurrentFlashMessage();
    }, 2000);
    //     axios.delete(`/api/v1/contacts/${id}`).then(res => {
    //     console.log("Delete Successfulyy")
    //  //   this.setLatestOrganisation()
    //     alert("Organisation Delete Successfulyy!!")
    //    }).catch(err=>{
    //      console.log("error is : "+err)
    //    //  alert("Something went wrong!!")
    //    })
  }

  setLatestOrganisation(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `setorganisationloading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ setorganisationloading: true });

    console.log("pageSize value is : " + state.pageSize); //10 data per page as we defined
    console.log("page value is : " + state.page); //current request page //at starting its 0 value for page
    // console.log("sorted value is : "+state.sorted)
    //console.log("filtered value is : "+state.filtered)

    const requestData = (pageSize, page, sorted, filtered) => {
      // console.log("Fetch data: sorted -> ", JSON.stringify(sorted));
      return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        //  let filteredData = rawData; //array of objects (random generated local fake data)

        var filteredData = DummyOrgData;
        var caltotalPages = DummyOrgData.length / 10;
        const fres = {
          rows: filteredData,
          pages: caltotalPages,
        };

        setTimeout(() => resolve(fres), 500);

        //   axios.get(`/api/v1/contacts?page=${page}&size=${pageSize}&registeredType="Yes"`).then(res => {

        //    var filteredData = res.data.content
        //    const fres = {
        //   rows:filteredData,
        //   pages:res.data.totalPages
        //  };

        // setTimeout(() => resolve(fres), 500);

        //  }).catch(err=>{

        //     this.setState({ setorganisationloading: false });
        //    console.log("organisation request data is not getting !!")
        //    console.log("organisation request error is : "+err)
        //   // alert("Something went wrong!!")
        //  })
      });
    };

    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(state.pageSize, state.page, state.sorted, state.filtered).then(
      (res) => {
        // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
        if (res) {
          this.setState({
            organisationdtall: res.rows,
            pages: res.pages,
            setorganisationloading: false,
          });
        } else {
          console.log("organisation request data is not getting !!");
        }
      }
    );
  }

  _handlecomingsoon = () => {
    alert("Feature is coming soon !!");
  };

  checkhasdownloadedvalue = (value) => {
    if (value == null || value === "No" || value === "opted out") {
      return false;
    } else {
      return true;
    }
  };

  _handleSetOrgLastFilters = (accessor, value) => {
    // this.onFilteredChangeCustom("lest", "councilName");
    // this.onFilteredChangeCustom("s", "lastName");
    // this.onFilteredChangeCustom("e", "country");

    const orgFilters = this._handleGetOrgFilter();

    if (orgFilters) {
      let fObj = JSON.parse(orgFilters);
      fObj[accessor] = value;
      sessionStrorageService.setOrgFilters(JSON.stringify(fObj));
    }
  };

  _handleGetOrgFilter = () => {
    const orgFilters = sessionStrorageService.getOrgFilters();

    if (orgFilters) {
      return orgFilters;
    } else {
      return false;
    }
  };

  _handleInitOrgFilter = () => {
    const orgFilters = this._handleGetOrgFilter();

    if (orgFilters) {
      console.log("orgFilters is set", orgFilters);
    } else {
      console.log("orgFilters is init");
      let fdata = {
        councilName: "",
        lastName: "",
        country: "",
      };
      sessionStrorageService.setOrgFilters(JSON.stringify(fdata));
    }
  };

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    // console.log("accessor: " + accessor, "value: " + value);

    this._handleSetOrgLastFilters(accessor, value);

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };

  render() {
    const { organisationdtall, setorganisationloading } = this.state;

    let columns = [];

    columns = [
      {
        Header: "Organisation Name",
        accessor: "councilName",
        minWidth: 250,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["councilName"] }),
        filterAll: true,
        Cell: (row) => (
          <span>
            <center>{row.value}</center>
          </span>
        ),
      },
      {
        Header: "Organisation Code",
        accessor: (d) => d.lastName,
        id: "lastName",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["lastName"] }),
        filterAll: true,
        Cell: (row) => (
          <span>
            <center>{row.value}</center>
          </span>
        ),
      },
      {
        Header: "Country",
        accessor: (d) => d.country.name,
        id: "country",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["country"] }),
        filterAll: true,
        Cell: (row) => (
          <span>
            <center>{row.value}</center>
          </span>
        ),
      },
      {
        Header: "Edit",
        accessor: "id",
        minWidth: 50,
        filterable: false,
        Cell: (row) => (
          <span>
            <center>
              <Link
                to={`/edit-organisation/${row.value}`}
                className="btn btn-sm btn-dark"
                style={{
                  color: "#fff",
                }}
              >
                <i className="fa fa-edit" />
              </Link>
            </center>
          </span>
        ),
      },
      {
        Header: "Group/Subgroup",
        accessor: "id",
        minWidth: 80,
        filterable: false,
        Cell: (row) => (
          <span>
            <center>
              <Link
                to={`/add-group-subgroup/${row.value}`}
                className="btn btn-sm btn-dark"
                style={{
                  color: "#fff",
                }}
              >
                <i className="fa fa-edit" />
              </Link>
            </center>
          </span>
        ),
      },
      {
        Header: "Assign Licence",
        accessor: "id",
        minWidth: 80,
        filterable: false,
        Cell: (row) => (
          <span>
            <center>
              <Link
                to={`/assign-licences/${row.value}`}
                className="btn btn-sm btn-dark"
                style={{
                  color: "#fff",
                }}
              >
                <i className="fa fa-edit" />
              </Link>
            </center>
          </span>
        ),
      },
    ];

    return (
      <section class="wrapper">
        <Header />
        {/*} <Sidebar activeStatus={"organisation"} />*/}
        <section class="">
          <Flashmessages />
          <div class="container-fluid">
            <div class="spacetb25">
              <div class="text-right mg-b-30 d-lg-none">
                <div class="top-heads-btn">
                  <Link
                    to="/add-council-user"
                    class="btn btn-primary with-icons-btn"
                  >
                    <span class="icon-plus-fill"></span>Add Organisation
                  </Link>
                  <Link to="/articles" class="btn btn-primary with-icons-btn">
                    <span class="icon-plus-fill"></span>Content
                  </Link>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="card card__custom">
                    <div class="card-body">
                      <ReactTable
                        PaginationComponent={Pagination}
                        filterable
                        filtered={this.state.filtered}
                        onFilteredChange={(filtered, column, value) => {
                          this.onFilteredChangeCustom(
                            value,
                            column.id || column.accessor
                          );
                        }}
                        defaultFilterMethod={(filter, row) =>
                          String(row[filter.id]) === filter.value
                        }
                        ref={this.reactContactTable}
                        data={organisationdtall}
                        columns={columns}
                        defaultPageSize={10}
                        className="-striped -highlight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Loader loadStatus={setorganisationloading} />
        </section>
      </section>
    );
  }
}

Organisation.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors, //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(Organisation));
