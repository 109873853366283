
export const GET_USER_LOGIN_LOADING_START =
"GET_USER_LOGIN_LOADING_START";
export const GET_USER_LOGIN_LOADING_STOP =
"GET_USER_LOGIN_LOADING_STOP";

export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_USER_RESET_PASS_DATA =
  "SET_CURRENT_USER_RESET_PASS_DATA";

export const FLASH_MESSAGE = "FLASH_MESSAGE";
export const CLEAR_CURRENT_FLASH_MESSAGE = "CLEAR_CURRENT_FLASH_MESSAGE";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
 
export const GET_CHANGED_PASSWORD_LOADING_START =
  "GET_CHANGED_PASSWORD_LOADING_START";
export const GET_CHANGED_PASSWORD_LOADING_STOP =
  "GET_CHANGED_PASSWORD_LOADING_STOP";

export const GET_FORGOT_PASSWORD_LOADING_START =
  "GET_FORGOT_PASSWORD_LOADING_START";
export const GET_FORGOT_PASSWORD_LOADING_STOP =
  "GET_FORGOT_PASSWORD_LOADING_STOP";
 