import React, { useEffect } from "react";
import Select from "react-select";
import { PLATFORM_CONSTANTS } from "../../shared/utils/platform.constant";
import "./css/inclusionStyle.css";

const Topology = ({ history, ...props }) => {
  const { systemAssets, allTopologyList, _handleAllTopologyList } = props;
  useEffect(() => {
    console.log("all topology list updated");
  }, [allTopologyList]);

  const generateTaxanomyOptions = (taxonomies) => {
    let allTaxonomyOpt = taxonomies.map((w) => ({
      label: w.code,
      value: w.id,
    }));

    return allTaxonomyOpt;
  };

  const handleTaxanomyLevel1 = (e, key, data) => {
    let targetIndex = key;
    let selectedOption = e;

    resetTaxanomyLevel2(targetIndex);
    resetTaxanomyLeve3(targetIndex);

    console.log("selected Taxonomy Level-1 is : ", e, key, data);

    let taxonomyLevel2Data = systemAssets.taxonomies.filter(
      (itm) => itm.parent_id == selectedOption.value
    );
    console.log("taxonomy Level-2 is ", taxonomyLevel2Data);

    let array = [...allTopologyList]; //make copy of all TopologyList

    array[targetIndex].selectedTaxonomyLevel1 = selectedOption;
    array[targetIndex].allTaxonomyLevel2OptArr = taxonomyLevel2Data;

    _handleAllTopologyList(array);
  };

  const handleTaxanomyLevel2 = (e, key, data) => {
    let targetIndex = key;
    let selectedOption = e;
    resetTaxanomyLeve3(targetIndex);
    console.log("selected Taxonomy Level-2 is : ", e, key, data);

    let taxonomyLevel3Data = systemAssets.taxonomies.filter(
      (itm) => itm.parent_id == selectedOption.value
    );
    console.log("taxonomy Level-3 is ", taxonomyLevel3Data);

    let array = [...allTopologyList]; //make copy of all TopologyList

    array[targetIndex].selectedTaxonomyLevel2 = selectedOption;
    array[targetIndex].allTaxonomyLevel3OptArr = taxonomyLevel3Data;

    _handleAllTopologyList(array);
  };

  const handleTaxanomyLevel3 = (e, key, data) => {
    let targetIndex = key;
    let selectedOption = e;

    console.log("selected Taxonomy Level-3 is : ", e, key, data);
    let array = [...allTopologyList];

    array[targetIndex].selectedTaxonomyLevel3 = selectedOption;

    _handleAllTopologyList(array);
  };

  const resetTaxanomyLevel2 = (targetIndex) => {
    let array = [...allTopologyList];

    array[targetIndex].selectedTaxonomyLevel2 = null;
    array[targetIndex].allTaxonomyLevel2OptArr = [];

    _handleAllTopologyList(array);
  };

  const resetTaxanomyLeve3 = (targetIndex) => {
    let array = [...allTopologyList];

    array[targetIndex].selectedTaxonomyLevel3 = null;
    array[targetIndex].allTaxonomyLevel3OptArr = [];

    _handleAllTopologyList(array);
  };

  const handleRemoveItem = (e, index) => {
    e.preventDefault();
    let array = [...allTopologyList];
    array.splice(index, 1);

    _handleAllTopologyList(array);
  };

  return (
    <div class="row">
      <div class="col-12">
        {allTopologyList && allTopologyList.length ? (
          allTopologyList.map((item, key) => (
            <div className="row" key={key}>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <h5 className="mg-b-10">
                  <strong>Role</strong>
                </h5>
                <div className="form-select mg-b-20">
                  <Select
                    value={item.selectedTaxonomyLevel1}
                    onChange={(e) => handleTaxanomyLevel1(e, key, item)}
                    options={generateTaxanomyOptions(
                      item.allTaxonomyLevel1OptArr
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <h5 className="mg-b-10">
                  <strong>Course Group</strong>
                </h5>
                <div className="form-select mg-b-20">
                  <Select
                    value={item.selectedTaxonomyLevel2}
                    onChange={(e) => handleTaxanomyLevel2(e, key, item)}
                    options={generateTaxanomyOptions(
                      item.allTaxonomyLevel2OptArr
                    )}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-3">
                <h5 className="mg-b-10">
                  <strong>Topic</strong>
                </h5>
                <div className="form-select mg-b-20">
                  <Select
                    value={item.selectedTaxonomyLevel3}
                    onChange={(e) => handleTaxanomyLevel3(e, key, item)}
                    options={generateTaxanomyOptions(
                      item.allTaxonomyLevel3OptArr
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1">
                <button
                  class="btn"
                  style={{ marginTop: 34 }}
                  onClick={(e) => handleRemoveItem(e, key)}
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Topology;
