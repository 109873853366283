import axios from "axios";
// import setAuthToken from "../utils/setAuthToken";
//import jwt_decode from "jwt-decode";
import fire from "../config/Fire";
import { API_CONSTANTS } from "../shared/constants/api.constants";

import {
  GET_USER_LOGIN_LOADING_START,
  GET_USER_LOGIN_LOADING_STOP,
  GET_ERRORS,
  SET_CURRENT_USER,
} from "./types";

import { sendFlashMessage, clearcurrentFlashMessage } from "./flashMessage";

// Login - Get User Token
export const loginUser = (userData, history) => (dispatch) => {
  if (userData.username === "wceadavid") {
    dispatch(setuserloginLoadingStart()); //here we dispatch function called setuserloginLoadingStart() which will set the loading state True before it actually does the request
    axios
      .post(`${API_CONSTANTS.AUTH.LOGIN}`, userData)
      .then(async (res) => {
        // Save to localStorage

        const token = res.data.token;
        //  console.log("our token response recieved!!", res.data)

        //let issuccess=false
        var fctoken;

        await fire
          .auth()
          .signInWithCustomToken(token)
          .then((u) => {
            // alert("Firebase Login Custom Token Success")

            fctoken = u.user.toJSON().stsTokenManager.accessToken;

            // console.log("custom token firebase res data below: ")
            // console.log(fctoken)

            // Set fctoken to local storage
            localStorage.setItem("jwtToken", fctoken);

            // issuccess=true
            dispatch(setuserloginLoadingStop());
            dispatch(
              sendFlashMessage("Login Successfully !!", "alert-success")
            );

            setTimeout(() => {
              dispatch(clearcurrentFlashMessage());
            }, 2000);

            // console.log("issuccess value is : "+issuccess)
            window.location.href = `organisation`;
          })
          .catch((error) => {
            dispatch(setuserloginLoadingStop());

            dispatch(sendFlashMessage(error.message, "alert-danger"));

            setTimeout(() => {
              dispatch(clearcurrentFlashMessage());
            }, 2000);
            //issuccess=false
            console.log(error);
            //  alert(error.message)
          });
      })
      .catch((err) => {
        dispatch(setuserloginLoadingStop());
        if (err.response.status === 401) {
          dispatch(
            sendFlashMessage(
              "Your credentials were not recoginized. Please try again",
              "alert-danger"
            )
          );

          setTimeout(() => {
            dispatch(clearcurrentFlashMessage());
          }, 2000);
          // alert("Your credentials were not recoginized. Please try again")
        } else {
          dispatch(
            sendFlashMessage(
              "There was an unexpected error while logging in.",
              "alert-danger"
            )
          );

          setTimeout(() => {
            dispatch(clearcurrentFlashMessage());
          }, 2000);
          // alert('There was an unexpected error while logging in.')
        }
        console.log("login error is : " + err);
      });
  } else {
    console.log("Your credentials were not recoginized. Please try again!!");
    dispatch(
      sendFlashMessage(
        "Your credentials were not recoginized. Please try again",
        "alert-danger"
      )
    );

    setTimeout(() => {
      dispatch(clearcurrentFlashMessage());
    }, 2000);
    // alert('There was an unexpected error while logging in.')
  }
};

// Login loading START
export const setuserloginLoadingStart = () => {
  return {
    type: GET_USER_LOGIN_LOADING_START, //here we dont send payload only lets the reducer know GET_USER_LOGIN_LOADING_START is loading
  };
};
// Login loading STOP
export const setuserloginLoadingStop = () => {
  return {
    type: GET_USER_LOGIN_LOADING_STOP, //here we dont send payload only lets the reducer know GET_USER_LOGIN_LOADING_STOP is loading
  };
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    //here we dispatch the reducer
    type: SET_CURRENT_USER,
    payload: decoded, //the decoded contains the actual user data from the token
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  let jwtToken = localStorage.getItem("jwtToken");
  //you are already logged out, if there is no token
  if (!jwtToken) {
    return;
  }

  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  // setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
