import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import Header from "../layout/Header";
import Flashmessages from "../flashMessagesComp/flashMessages";
import { API_CONSTANTS } from "../../shared/constants/api.constants";
import Loader from "../common/GlobalLoader";

import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";


class AddEditPublisherZones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publisher: "",
      zones: "",
      loading: false,
      isAddForm: props.data && props.data.add,
      id: this.props.match.params.id
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    if (!this.state.isAddForm && this.state.id) {
      this.loadPublisherZone()
    }
  }

  loadPublisherZone() {
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_PUBLISHER_ZONE(this.state.id)}`)
      .then((res) => {
        this.setState({
          publisher: res.data.publisher,
          zones: res.data.zones,
          id: res.data.id,
          loading: false
        })
        this.props.sendFlashMessage(
          "Publisher Zones fetched successfully !!",
          "alert-success"
        )
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000)
      })
      .catch((err) => {
        this.setState({ loading: false })
        this.props.sendFlashMessage(
          "Something went wrong !!",
          "alert-danger"
        )
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000)
      })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit(e) {
    e.preventDefault()
    this.validateForm()
    this.setState({ loading: true })
    if (this.state.isAddForm) {
      this.addPublisherZone()
    } else {
      this.updatePublisherZone()
    }
  }

  addPublisherZone() {
    this.setState({ loading: true })
    axios
      .post(`${API_CONSTANTS.COUNCIL.ADD_PUBLISHER_ZONES}`, {
        publisher: this.state.publisher,
        zones: this.state.zones
      })
      .then((res) => {
        this.setState({
          publisher: "",
          zones: "",
          loading: false
        })
        this.props.sendFlashMessage(
          "Publisher Zones Created successfully !!",
          "alert-success"
        )
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000)
      })
      .catch((err) => {
        this.setState({ loading: false })
        this.props.sendFlashMessage(
          "Something went wrong !!",
          "alert-danger"
        )
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000)
      })
  }

  updatePublisherZone() {
    this.setState({ loading: true })
    axios
      .put(`${API_CONSTANTS.COUNCIL.UPDATE_PUBLISHER_ZONE(this.state.id)}`, {
        publisher: this.state.publisher,
        zones: this.state.zones
      })
      .then((res) => {
        this.setState({
          loading: false
        })
        this.props.sendFlashMessage(
          "Publisher Zones Updated successfully !!",
          "alert-success"
        )
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000)
      })
      .catch((err) => {
        this.setState({ loading: false })
        this.props.sendFlashMessage(
          "Something went wrong !!",
          "alert-danger"
        )
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000)
      })
  }

  validateForm() {
    if (!this.state.publisher) {
      this.props.sendFlashMessage(
        "Please enter Publisher input",
        "alert-danger"
      )
    } else if (!this.state.zones) {
        this.props.sendFlashMessage(
          "Please enter Zone input",
          "alert-danger"
        )
    }

    setTimeout(() => {
      this.props.clearcurrentFlashMessage()
    }, 2000)
  }

  render() {
    return (
      <section class="wrapper">
        <Header />
        <section class="">
          <Flashmessages />
          <div class="container-fluid">
            <div class="spacetb25">
              <div class="row">
                <div class="col-xl-8 offset-xl-2">
                  <form onSubmit={this.onSubmit} encType="multipart/form-data">
                    <div class="card card__custom">
                      <div class="card-header">
                        <h5 class="card-title">*= Required Fields</h5>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`}>
                              <label>Publisher</label>
                              <input
                                type="text"
                                class="form-control"
                                onChange={this.onChange}
                                id="publisher"
                                placeholder="Enter Publisher name"
                                name="publisher"
                                value={this.state.publisher}
                                required
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class={`form-group textbox__label`} >
                              <label>Zone</label>
                              <input
                                type="zones"
                                className="form-control"
                                onChange={this.onChange}
                                id="zones"
                                placeholder="Enter Zone"
                                name="zones"
                                value={this.state.zones}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer text-center">
                        <button type="submit" class="btn btn-secondary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Loader loadStatus={this.state.loading} />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(AddEditPublisherZones))
