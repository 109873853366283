import React, { Component } from "react";
import "react-table/react-table.css";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
// import Select from "react-select";

import Loader from "../common/GlobalLoader";
import Header from "../layout/Header";
import Sidebar from "../layout/sidebar";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Flashmessages from "../flashMessagesComp/flashMessages";
import Progress from "../common/Progress";

class BulkuploadReconciliation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      allcouncilarrobjs: null,
      councilname: null,
      newreconciliationfile: "",
      csvwithgrouploading: false,
      uploadPercentage: 0,
      csvuploadsuccesstrackmsg: "",
      csvuploaderrors: null,
    };
  }

  componentDidMount() {
    //if isAuthenticated is not TRUE so redirect the user to /

    //signature response
    const csvreserrordata = [
      {
        errorMessages: [
          {
            type: "email",
            message: "not valid",
          },
          {
            type: "name",
            message: "cannot empty",
          },
        ],
        rowId: "1",
        rowData: "",
        step: "3",
        message: "Validation Errors",
      },
      {
        errorMessages: [
          {
            type: "email",
            message: "not valid",
          },
          {
            type: "name",
            message: "cannot empty",
          },
          {
            type: "mobile",
            message: "should not contain alphabets",
          },
        ],
        rowId: "2",
        rowData: "",
        step: "5",
        message: "Validation with DB Errors",
      },
    ];

    this.setState({ csvuploaderrors: csvreserrordata });

    this._getallcouncils();
  }

  _getallcouncils = () => {
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_ALL_COUNCILS}`)
      .then((res) => {
        this.setState({ allcouncilarrobjs: res.data });
      })
      .catch((err) => {
        console.log("error is : " + err);
      });
  };

  _handlecomingsoon = () => {
    alert("Feature is coming soon !!");
  };

  onChangecsvfile = (e) => {
    switch (e.target.name) {
      case "newreconciliationfile":
        this.setState({ newreconciliationfile: e.target.files[0] });
        break;
      default:
        this.setState({ newreconciliationfile: "no file" });
    }
  };

  handlecouncilnameChange = (councilname) => {
    this.setState({ councilname });
    console.log(`Option selected:`, councilname);
  };

  handleBulkUploadCsvError = (errorData) => {
    /*let jsonData=[{
    "error1":"message1",
    "error2":"message2",
  }]*/
    const fileData = JSON.stringify(errorData);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "bulkupload-csv-errors.txt";
    link.href = url;
    link.click();
  };

  onSubmitbycsv = async (e) => {
    e.preventDefault();

    const { newreconciliationfile } = this.state;
    if (newreconciliationfile) {
      console.log("selected csv flle is below :");
      console.log(newreconciliationfile);

      let newReconciliationData = new FormData();
      newReconciliationData.append("file", newreconciliationfile);

      this.setState({ csvwithgrouploading: true });

      this.setState({
        csvuploadsuccesstrackmsg: "Please wait file is uploading...",
      });

      axios
        .post(`${API_CONSTANTS.COUNCIL.UPLOAD_CSV}`, newReconciliationData, {
          onUploadProgress: (progressEvent) => {
            // console.log(progressEvent)
            this.setState({
              uploadPercentage: parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              ),
            });

            // Clear percentage
            setTimeout(() => this.setState({ uploadPercentage: 0 }), 2000);
          },
        })
        .then((res) => {
          this.setState({
            csvuploadsuccesstrackmsg: "Please wait file data is processing...",
          });

          let filename = res.data;
          console.log("filename received which is : " + filename);

          let data = {
            assetId: filename,
          };

          axios
            .post(`${API_CONSTANTS.COUNCIL.BATCH_PROCESS}`, data)
            .then(async (res) => {
              this.setState({
                csvuploadsuccesstrackmsg:
                  "Your csv upload success job id is : " + res.data,
              });

              //  let  jobidresdata =  await axios.get(`/p/batch/errors/contactuploads?jobId=${res.data}`)

              //  if(jobidresdata){
              //    console.log('jobidresdata',jobidresdata.data);
              //  }else{
              //     console.log('jobidresdata is not received')
              //  }

              this.setState({ csvwithgrouploading: false });
              this.setState({
                newreconciliationfile: "",
                councilname: null,
              });

              this.props.sendFlashMessage(
                "The reconciliation upload request completed successfully !!",
                "alert-success"
              );
              setTimeout(() => {
                this.props.clearcurrentFlashMessage();
              }, 2000);
            })
            .catch((err) => {
              let errorData = err;
              this.setState({ csvwithgrouploading: false });
              this.setState({
                newreconciliationfile: "",
                csvuploadsuccesstrackmsg: "",
              });

              //this.setState({csvuploaderrors:err.data})

              /*  let jsonData=[{
            "error1":"message1",
            "error2":"message2",
          }]*/
              if (err.response.status === 504) {
                this.handleBulkUploadCsvError(errorData);
                console.log("error is : " + err);
                this.props.sendFlashMessage(
                  "Upload request has timed out with no response. Please try again or contact support !!",
                  "alert-danger"
                );
                setTimeout(() => {
                  this.props.clearcurrentFlashMessage();
                }, 2000);

                //  alert("upload request has timed out with no response. Please try again or contact support")
              } else {
                this.handleBulkUploadCsvError(errorData);
                console.log("error is : " + err);

                this.props.sendFlashMessage(
                  "The bulk upload request completed with errors, please see the downloaded file for more details !!",
                  "alert-danger"
                );
                setTimeout(() => {
                  this.props.clearcurrentFlashMessage();
                }, 2000);

                // alert('The bulk upload request completed with errors, please see the downloaded file for more details')
              }
            });
        })
        .catch((err) => {
          let errorData = err;
          this.setState({ csvwithgrouploading: false });
          this.setState({
            newreconciliationfile: "",
            councilname: null,
            csvuploadsuccesstrackmsg: "",
          });

          //this.setState({csvuploaderrors:err.data})
          if (err.response.status === 504) {
            this.handleBulkUploadCsvError(errorData);
            console.log("error is : " + err);

            this.props.sendFlashMessage(
              "upload request has timed out with no response. Please try again or contact support",
              "alert-danger"
            );
            setTimeout(() => {
              this.props.clearcurrentFlashMessage();
            }, 2000);

            // alert("upload request has timed out with no response. Please try again or contact support")
          } else {
            this.handleBulkUploadCsvError(errorData);
            console.log("error is : " + err);

            this.props.sendFlashMessage(
              "The bulk upload request completed with errors, please see the downloaded file for more details",
              "alert-danger"
            );
            setTimeout(() => {
              this.props.clearcurrentFlashMessage();
            }, 2000);

            //  alert('The bulk upload request completed with errors, please see the downloaded file for more details')
          }
        });
    } else {
      this.props.sendFlashMessage(
        "first select the csv file of new reconciliation!!",
        "alert-danger"
      );
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);

      //alert("first select the csv file of new reconciliation!!");
    }
  };

  render() {
    const { csvwithgrouploading, csvuploadsuccesstrackmsg } = this.state;

    // let councilnameoptions;
    // let allcouncilarrobjsloading;
    // if (allcouncilarrobjs === null) {
    //   allcouncilarrobjsloading = "";
    // } else {
    //   if (allcouncilarrobjs && Object.keys(allcouncilarrobjs).length > 0) {
    //     councilnameoptions = allcouncilarrobjs.map(w => ({
    //       label: w.name,
    //       value: w.id
    //     }));
    //   }
    // }

    // const { user, changedpassloading } = this.props.auth;
    /*
    let csvuploaderrorsData
    if(csvuploaderrors !==null){
       csvuploaderrorsData = csvuploaderrors.map((data,index)=>{
        return <div className="row" style={{backgroundColor:"yellow",margin:5}}>
        <div className="col-md-12" style={{padding:10,backgroundColor:"#24434F",color:"#fff"}}>
                        
        Row Id :<span>{data.rowId}</span> ~ 
        common message :<span style={{color:"red"}}> {data.message}</span>
   
        </div>
                  {data.errorMessages.map((errData,index)=>{
                          return   <div className="col-md-4" style={{backgroundColor:"#e7e720",color:"black",border:"5px solid yellow"}}>
                                         Field : {errData.type} <br />
                                         Error : <span style={{color:"red"}}>{errData.message}</span>
                           </div>
                        })}
                        <br />
                      
           <br />
        </div>
      })
   
    }
  */

    return (
      <section class="wrapper">
        <Header />
        <Sidebar activeStatus={"reconciliationUpload"} />
        <section class="contentCntr">
          <Flashmessages />
          <div class="container-fluid">
            <div class="spacetb25">
              <nav class="breadcrumb__list">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <Link to="/organisation">Organisation</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Bulk Upload Reconciliation
                  </li>
                </ol>
              </nav>
              <div class="row">
                <div class="col-12">
                  <div class="card card__custom">
                    {csvuploadsuccesstrackmsg && (
                      <div
                        className="alert alert-info alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.uploadPercentage > 0 && (
                          <Progress percentage={this.state.uploadPercentage} />
                        )}
                        <strong>Info : </strong> {csvuploadsuccesstrackmsg}
                      </div>
                    )}
                    <div class="card-body">
                      <form
                        onSubmit={this.onSubmitbycsv}
                        encType="multipart/form-data"
                      >
                        <div class="upload__files">
                          <div class="row">
                            <div class="col-sm-6 form-group">
                              <input
                                type="file"
                                id="exampleFormControlFile1"
                                name="newreconciliationfile"
                                onChange={this.onChangecsvfile}
                              />
                            </div>
                            <div class="col-sm-6 form-group">
                              <button
                                type="submit"
                                class="btn btn-success minwidth190"
                              >
                                Upload
                              </button>
                            </div>
                          </div>
                          <div
                            class="alert alert-warning custom__warning mg-t-20"
                            role="alert"
                          >
                            <i
                              class="fa fa-exclamation-triangle warning__icon"
                              aria-hidden="true"
                            ></i>
                            <p>
                              <strong>Warning:</strong> Please try to upload a
                              csv file with records below 10000 otherwise the
                              upload might time out. If you need to upload more
                              than 10000 records, break them into two files or
                              more of 10000 records each
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*csvuploaderrors !=null &&   <div className="container-fluid" style={{maxHeight:300,overflowY:"auto",margin:10}}>
          
                           <p>Csv Upload Errors Display</p>
                          
                           {csvuploaderrorsData && csvuploaderrorsData}
                          </div>*/}
          </div>
        </section>

        <Loader loadStatus={csvwithgrouploading} />
      </section>
    );
  }
}

BulkuploadReconciliation.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors, //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(BulkuploadReconciliation));
