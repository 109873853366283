import React, { Component } from "react";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import $ from "jquery";

class sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartstockdtall: [],
      cartitemlength: "",
      companylogo: "",
      minqtyreqnotify: null,
    };
  }

  componentDidMount() {}

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser(); //here we call the logoutUser action
  }

  handleSidebarToggle = () => {
    $(function() {
      $(".navBox").removeClass("opened");
      $(".tab-overlay").removeClass("show-overlay");
      $("body").removeClass("tab-hidden");
    });
  };

  render() {
    const { activeStatus } = this.props;
    //const { isAuthenticated, user } = this.props.auth;
    //const cartstockdtall = this.state.cartstockdtall;

    return (
      <div class="navBox">
        <div class={`tab-overlay`} onClick={this.handleSidebarToggle}></div>

        <div class="nav__inner">
          <div class="nav__menu">
            <ul>
              <li class={`${activeStatus === "organisation" && "active"}`}>
                <Link to="/organisation">
                  <span class="icon-grid nav__icons"></span>Organisation
                </Link>
              </li>
              {/*
              <li class={`${activeStatus === "addGroupSubGroup" && "active"}`}>
                <Link to="/add-group-subgroup">
                  <span class="icon-circle nav__icons"></span>Group/Subgroup
                </Link>
              </li>
              <li class={`${activeStatus === "assignLicence" && "active"}`}>
                <Link to="/assign-licences">
                  <span class="icon-circle nav__icons"></span>Assign Licences
                </Link>
              </li>
              <li
                class={`${activeStatus === "reconciliationUpload" && "active"}`}
              >
                <Link to="/bulk-upload-reconciliation">
                  <span class="icon-circle nav__icons"></span>Reconciliation
                </Link>
              </li>*/}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logoutUser,
})(sidebar);
