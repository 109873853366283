import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import Flashmessages from "../flashMessagesComp/flashMessages";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Loader from "../common/GlobalLoader";

class Charges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chargeObj: {},
      errors: {},
      chargesDataButton: "Add Charge",
      chargesList: [],
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
  }

  componentDidMount() {
    this.getCharges()
  }

  getNewCharge() {
    return {
      chargeCode: '',
      chargeDescription: '',
      chargePercentage: '',
      chargeAmount: '',
      chargeCurrency: '',
      chargeOrder: '',
      chargeType: ''
    }
  }

  getCharges() {
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_CHARGES}`)
      .then((res) => {
        this.setState({ chargesList: res.data })
      })
      .catch((err) => {
        console.log("CHARGES API error: " + err);
      });
  }

  handleInputChange = (event) => {
    const state = this.state.chargeObj;
    state[event.target.name] = event.target.value;
    this.setState({ chargeObj: state });
  }

  editCharge = (e, chargeId) => {
    let charge = this.state.chargesList.find((charge) => charge.id == chargeId)

    if (charge && charge.id) {
      let data = {
        id: charge.id,
        chargeCode: charge.chargeCode,
        chargeDescription: charge.chargeDescription,
        chargePercentage: charge.chargePercentage,
        chargeAmount: charge.chargeAmount,
        chargeCurrency: charge.chargeCurrency,
        chargeOrder: charge.chargeOrder,
        chargeType: charge.chargeType
      }

      this.setState({ chargeObj: data, chargesDataButton: 'Edit Charge' })
    }
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation()

    this.setState({ editorgloading: true });

    let chargeObj = this.state.chargeObj

    if (chargeObj.id) { // EDIT
      axios
        .put(
          `${API_CONSTANTS.COUNCIL.EDIT_CHARGE(chargeObj.id)}`,
          chargeObj
        )
        .then((res) => {
          this.props.sendFlashMessage(
            "Charge updated successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ editorgloading: false })
          this.getCharges()
        })
        .catch((err) => {
          this.setState({ editorgloading: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        })
    } else { // NEW
      axios
        .post(
          `${API_CONSTANTS.COUNCIL.ADD_CHARGE}`,
          chargeObj
        )
        .then((res) => {
          this.props.sendFlashMessage(
            "Rates created successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ editorgloading: false })
          this.getCharges()
        })
        .catch((err) => {
          this.setState({ editorgloading: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        })
    }
  }

  onCancelClick = (e) => {
    this.setState({ chargeObj: this.getNewCharge(),
    chargesDataButton: 'Add Charge'
   })
  }

  render() {
    const {
      chargeObj,
      editorgloading,
      chargesList,
      chargesDataButton
    } = this.state

    return (
      <section class="wrapper">
        <Header />
        <section class="">
          <Flashmessages />
          <div class="container-fluid">
            <div class="spacetb25">
              <nav class="breadcrumb__list">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    {" "}
                    <Link to="/charges">Charges</Link>
                  </li>
                </ol>
              </nav>
              <div class="row">
                <div class="col-xl-8 offset-xl-2">
                  <form onSubmit={this.onSubmit} encType="multipart/form-data">
                    <div class="card card__custom">
                      <div class="card-header">
                        <h5 class="card-title">*= Required Fields</h5>
                      </div>
                      <div class="card-body">
                        <div class="show-advertisement pd-t-20 mg-t-20">    
                            <h5 class="mg-b-20 text-center"><strong>Charges</strong></h5>
                            <ul class="list-advertisement list-adds">
                                <li>
                                    <div class="row">
                                        <div class="col-sm-4 text-left text-sm-right">
                                            <label class="text-label pd-t-5">Code</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" class="form-control" placeholder="" value={chargeObj.chargeCode} name = "chargeCode"  onChange = {this.handleInputChange}/>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-sm-4 text-left text-sm-right">
                                            <label class="text-label pd-t-5">Description</label>
                                        </div>
                                        <div class="col-sm-6">                                                        
                                            <textarea class="form-control" rows="4" value={chargeObj.chargeDescription} name = "chargeDescription" onChange = {this.handleInputChange}></textarea>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-sm-4 text-left text-sm-right">
                                            <label class="text-label pd-t-5">Percentage</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" class="form-control" placeholder="" value={chargeObj.chargePercentage} name = "chargePercentage" onChange = {this.handleInputChange} />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-sm-4 text-left text-sm-right">
                                            <label class="text-label pd-t-5">Amount</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" class="form-control" placeholder="" value={chargeObj.chargeAmount} name = "chargeAmount" onChange = {this.handleInputChange} />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-sm-4 text-left text-sm-right">
                                            <label class="text-label pd-t-5">Currency</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" class="form-control" placeholder="" value={chargeObj.chargeCurrency} name = "chargeCurrency" onChange = {this.handleInputChange} />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-sm-4 text-left text-sm-right">
                                            <label class="text-label pd-t-5">Order</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" class="form-control" placeholder="" value={chargeObj.chargeOrder} name = "chargeOrder" onChange = {this.handleInputChange} />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row">
                                        <div class="col-sm-4 text-left text-sm-right">
                                            <label class="text-label pd-t-5">Type</label>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" class="form-control" placeholder="" value={chargeObj.chargeType} name = "chargeType" onChange = {this.handleInputChange} />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="card-footer text-center">
                          <button type = "button" style= {{ marginRight: '16px' }} class="btn btn-secondary" onClick={ this.onCancelClick }>
                            Cancel
                          </button>
                          <button type="submit" class="btn btn-secondary">
                            {chargesDataButton}
                          </button>
                        </div>
                        <div class="table-box">
                            <div class="table-responsive">
                                <table class="table" style={{ minWidth: "900px" }}>
                                    <thead>
                                        <tr>
                                            <th style= {{ width: "13%" }}>Id</th>
                                            <th style= {{ width: "13%" }}>Code</th>
                                            <th style= {{ width: "13%" }}>Percent</th>
                                            <th style= {{ width: "13%" }}>Amount</th>
                                            <th style= {{ width: "13%" }}>Currency</th>
                                            <th style= {{ width: "13%" }}>Order</th>
                                            <th style= {{ width: "13%" }}>Type</th>
                                            <th class="text-center" style= {{ width: "9%" }}>Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                      {
                                          chargesList.map((charge, i) => (
                                              <tr key = { i }>                                                
                                                <td>
                                                    { charge.id }
                                                </td>
                                                <td>
                                                    { charge.chargeCode }
                                                </td>
                                                <td>
                                                    { charge.chargePercentage }
                                                </td>
                                                <td>
                                                    { charge.chargeAmount }
                                                </td>
                                                <td>
                                                    { charge.chargeCurrency }
                                                </td>
                                                <td>
                                                    { charge.chargeOrder }
                                                </td>                                                            
                                                <td>
                                                    { charge.chargeType }
                                                </td>                         
                                                <td class="text-center">
                                                    <a href="javascript:void(0)" class="btn btn-primary text-edit" onClick={ (e) => this.editCharge(e, charge.id) }>
                                                        <span class="icon-edit"></span>
                                                    </a>
                                                </td>
                                            </tr>
                                          ))
                                      }
                                    </tbody>
                                </table>
                            </div>                                        
                        </div>  
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Loader loadStatus={editorgloading} />
      </section>
    );
  }
}

Charges.propTypes = {
  auth: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(Charges));
