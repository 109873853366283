import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";

import {
  // sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";

import "./flashMessages.css";

class flashMessages extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
    };
  }
  componentDidMount() {}

  onclearflashmessageClick() {
    this.props.clearcurrentFlashMessage();
  }

  onhandleCheckAlertStatus = (className) => {
    if (className === "alert-success") {
      return "bg-success";
    } else if (className === "alert-danger") {
      return "bg-danger";
    } else if (className === "alert-info") {
      return "bg-info";
    }
  };

  render() {
    const { message, className } = this.props.flashMessage;

    return message && className ? (
      <div
        className={`p-3 ${this.onhandleCheckAlertStatus(
          className
        )} text-white message__box`}
      >
        {/*} <span
           onClick={this.onclearflashmessageClick.bind(this)}
           className="close"
           data-dismiss="alert"
           aria-label="close"
           style={{color:"darkblue"}}
         >
           &times;
        </span>*/}
        {message}
      </div>
    ) : (
      <div />
    );
  }
}

flashMessages.propTypes = {
  auth: PropTypes.object.isRequired, //auth is also property of this component
  flashMessage: PropTypes.object.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  //here map the auth (state.auth) from the root reducer (from store.js) where in combineReducer auth : authReducer similarly for errors
  auth: state.auth,
  errors: state.errors,
  flashMessage: state.flashMessage,
});

export default connect(mapStateToProps, {
  loginUser,
  clearcurrentFlashMessage,
})(flashMessages);
