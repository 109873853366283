import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";

import ArticleList from "./ArticleList";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Flashmessages from "../flashMessagesComp/flashMessages";

import { PLATFORM_CONSTANTS } from "../../shared/utils/platform.constant";

import Pagination from "./Pagination";
import ProgressBar from "../common/ProgressBar";

//const rawData = makeData();

class AAOSOR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      platform: PLATFORM_CONSTANTS.PLATFORM.AAOSOR,
      start: 0,
      currentPage: 1,
      articlePerPage: 5,
      errors: {},
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  componentDidUpdate() {
    const { allAAOSORArticles } = this.props;
    console.log("componentDidUpdate called");
    console.log("AAOSOR allAAOSORArticles", allAAOSORArticles);
  }

  // Change page
  paginate = async (e, pageNumber) => {
    e.preventDefault();
    const {
      allAAOSORArticles,
      _checkArticleTypeSelectStatus,
      getCurrentArticles,
    } = this.props;
    const { articlePerPage } = this.state;

    //set current page
    this.setState({ currentPage: pageNumber });

    // Get current articles
    const indexOfLastArticle = pageNumber * articlePerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlePerPage;
    const currentArticles = allAAOSORArticles.slice(
      indexOfFirstArticle,
      indexOfLastArticle
    );

    if (currentArticles.length === 0) {
      let getStatusOfArticleType = await _checkArticleTypeSelectStatus();
      let query = await this._queryBuilder(getStatusOfArticleType);

      console.log("final query", query);

      getCurrentArticles(query);
    }
  };

  _queryBuilder = async (getStatusOfArticleType) => {
    const { platform, start, currentPage, articlePerPage } = this.state;

    const { searchKeyword, searchTitle, wt } = this.props;

    let typeSubQuery = getStatusOfArticleType.typesQuery;

    let queryRes;

    if (platform && searchKeyword && searchTitle && typeSubQuery) {
      console.log("query type 1");
      queryRes = `${platform}/extapi/search?q=R_ABS:${searchKeyword}&R_TI:${searchTitle}&${typeSubQuery}&start=${start}&page=${currentPage}&rows=${articlePerPage}&wt=${wt}`;
    } else if (platform && searchKeyword && typeSubQuery) {
      console.log("query type 2");
      queryRes = `${platform}/extapi/search?q=R_ABS:${searchKeyword}&${typeSubQuery}&start=${start}&page=${currentPage}&rows=${articlePerPage}&wt=${wt}`;
    } else if (platform && searchTitle && typeSubQuery) {
      console.log("query type 3");
      queryRes = `${platform}/extapi/search?q=R_TI:${searchTitle}&${typeSubQuery}&start=${start}&page=${currentPage}&rows=${articlePerPage}&wt=${wt}`;
    } else if (platform && typeSubQuery) {
      console.log("query type 4");
      queryRes = `${platform}/extapi/search?q=${typeSubQuery}&start=${start}&page=${currentPage}&rows=${articlePerPage}&wt=${wt}`;
    } else if (platform && searchKeyword) {
      console.log("query type 5");
      queryRes = `${platform}/extapi/search?q=R_ABS:${searchKeyword}&start=${start}&page=${currentPage}&rows=${articlePerPage}&wt=${wt}`;
    } else if (platform && searchTitle) {
      console.log("query type 6");
      queryRes = `${platform}/extapi/search?q=R_TI:${searchTitle}&start=${start}&page=${currentPage}&rows=${articlePerPage}&wt=${wt}`;
    }

    return [{ platform: platform, query: queryRes }];
  };

  resetAAOSORPaginationConfig = () => {
    this.setState({ currentPage: 1 });
  };

  checkSelectedPlatformInclusionArticle = (e) => {
    e.preventDefault();

    const { platform } = this.state;

    const { allAAOSORArticles, handleIncludeSelectedArticles } = this.props;

    let status = this.articleInclusionValidation();

    if (status) {
      let articleCurrentDois = [];

      for (let i = 0; i < allAAOSORArticles.length; i++) {
        if (allAAOSORArticles[i].selected) {
          articleCurrentDois.push({
            doi: allAAOSORArticles[i].currentDoi,
            platform: allAAOSORArticles[i].platform,
            articleMeta: allAAOSORArticles[i].articleMeta,
            journalMeta: allAAOSORArticles[i].journalMeta,
          });
        }
      }

      handleIncludeSelectedArticles(articleCurrentDois, platform);
    }
  };

  checkSelectedPlatformRejectionArticle = (e) => {
    e.preventDefault();

    const { platform } = this.state;

    const { allAAOSORArticles, handleRejectSelectedArticles } = this.props;

    let status = this.articleRejectionValidation();

    if (status) {
      let articleCurrentDois = [];

      for (let i = 0; i < allAAOSORArticles.length; i++) {
        if (allAAOSORArticles[i].selected) {
          articleCurrentDois.push({
            doi: allAAOSORArticles[i].currentDoi,
            platform: allAAOSORArticles[i].platform,
            articleMeta: allAAOSORArticles[i].articleMeta,
            journalMeta: allAAOSORArticles[i].journalMeta,
          });
        }
      }

      handleRejectSelectedArticles(articleCurrentDois, platform);
    }
  };

  articleInclusionValidation = () => {
    const { allAAOSORArticles, _handleFlashMsg } = this.props;
    let atleastOneSelectedStatus = false;
    let onlyValidSelectedStatus = true;

    for (let i = 0; i < allAAOSORArticles.length; i++) {
      if (allAAOSORArticles[i].selected) {
        //atleast selected one article
        atleastOneSelectedStatus = true;
        break;
      }
    }

    for (let i = 0; i < allAAOSORArticles.length; i++) {
      if (
        allAAOSORArticles[i].status ===
          PLATFORM_CONSTANTS.ARTICLE_STATUS.Included &&
        allAAOSORArticles[i].selected
      ) {
        //if found already included article
        let error = `You cannot perform operation on already included article doi : ${allAAOSORArticles[i].currentDoi} for inclusion process.`;

        console.log(error);
        _handleFlashMsg(error, "error");
        onlyValidSelectedStatus = false;
        break;
      }
    }

    if (atleastOneSelectedStatus && onlyValidSelectedStatus) {
      return true;
    } else {
      // _handleFlashMsg("Please Select Atleast 1 Article", "error");
      return false;
    }
  };

  articleRejectionValidation = () => {
    const { allAAOSORArticles, _handleFlashMsg } = this.props;
    let atleastOneSelectedStatus = false;
    let onlyValidSelectedStatus = true;

    for (let i = 0; i < allAAOSORArticles.length; i++) {
      if (allAAOSORArticles[i].selected) {
        atleastOneSelectedStatus = true;
        break;
      }
    }

    for (let i = 0; i < allAAOSORArticles.length; i++) {
      if (
        allAAOSORArticles[i].status ===
          PLATFORM_CONSTANTS.ARTICLE_STATUS.Rejected &&
        allAAOSORArticles[i].selected
      ) {
        //if found already Rejected article

        let error = `You cannot perform operation on already Rejected article doi : ${allAAOSORArticles[i].currentDoi} for Rejection process`;

        console.log(error);
        _handleFlashMsg(error, "error");

        onlyValidSelectedStatus = false;
        break;
      }
    }

    if (atleastOneSelectedStatus && onlyValidSelectedStatus) {
      return true;
    } else {
      // _handleFlashMsg("Please Select Atleast 1 Article", "error");
      return false;
    }
  };

  render() {
    const {
      allAAOSORArticles,
      totalAAOSORPages,
      aaosormultiselectarr,
      handlePlatformArticleSelection,
      handleIncludeSelectedArticles,
      handleOverlaySelection,
      articleAAOSORLoading,
    } = this.props;

    const { start, currentPage, articlePerPage } = this.state;

    let allArticleCont;
    let paginationCont;

    if (allAAOSORArticles && allAAOSORArticles.length > 0) {
      // Get current articles
      const indexOfLastArticle = currentPage * articlePerPage;
      const indexOfFirstArticle = indexOfLastArticle - articlePerPage;
      const currentArticles = allAAOSORArticles.slice(
        indexOfFirstArticle,
        indexOfLastArticle
      );
      const totalArticles = totalAAOSORPages * articlePerPage;

      console.log("indexOfLastArticle", indexOfLastArticle);
      console.log("indexOfFirstArticle", indexOfFirstArticle);
      console.log("totalArticles", totalArticles);
      console.log("currentArticles", currentArticles);

      paginationCont = (
        <Pagination
          currentPage={currentPage}
          indexOfFirstArticle={indexOfFirstArticle}
          indexOfLastArticle={indexOfLastArticle}
          articlePerPage={articlePerPage}
          totalArticles={totalArticles}
          totalPages={totalAAOSORPages}
          paginate={this.paginate}
          articleLoading={articleAAOSORLoading}
        />
      );

      allArticleCont = (
        <div>
          {articleAAOSORLoading ? (
            ""
          ) : (
            <div class="text-right pd-t-20 pd-b-20">
              <button
                type="button"
                class="btn btn-success"
                onClick={(e) => this.checkSelectedPlatformInclusionArticle(e)}
                style={{ marginRight: 10 }}
              >
                Include
              </button>
              <button
                type="button"
                class="btn btn-danger"
                onClick={(e) => this.checkSelectedPlatformRejectionArticle(e)}
              >
                Reject
              </button>
            </div>
          )}
          <ArticleList
            allArticles={allAAOSORArticles}
            currentArticles={currentArticles}
            multiselectarr={aaosormultiselectarr}
            handleArticleSelection={handlePlatformArticleSelection}
            handleOverlaySelection={handleOverlaySelection}
            loadStatus={articleAAOSORLoading}
          />
        </div>
      );
    }

    return (
      <div>
        <br />

        <ProgressBar loadStatus={articleAAOSORLoading} />

        {allArticleCont ? (
          allArticleCont
        ) : (
          <div className="text-center" style={{ padding: 100 }}>
            <img
              className="rounded mx-auto d-block"
              src="https://localwala.in/site_assets/images/no_data_found.png"
              alt="no data"
            />
          </div>
        )}

        {paginationCont}
      </div>
    );
  }
}

AAOSOR.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors, //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(AAOSOR));
