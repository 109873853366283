import React, { useEffect } from "react";
import { PLATFORM_CONSTANTS } from "../../shared/utils/platform.constant";

const ArticleList = ({
  allArticles,
  currentArticles,
  multiselectarr,
  handleArticleSelection,
  handleOverlaySelection,
  loadStatus,
}) => {
  useEffect(() => {
    console.log("article list updated");
  }, [currentArticles]);

  const platformImg = (currentPlatform) => {
    if (currentPlatform) {
      if (currentPlatform === PLATFORM_CONSTANTS.PLATFORM.F1000Research) {
        return "/assets/images/f1000research.jpg";
      } else if (currentPlatform === PLATFORM_CONSTANTS.PLATFORM.WOR) {
        return "/assets/images/wellcome.jpg";
      } else if (currentPlatform === PLATFORM_CONSTANTS.PLATFORM.AAOSOR) {
        return "/assets/images/aas.jpg";
      } else if (currentPlatform === PLATFORM_CONSTANTS.PLATFORM.GOR) {
        return "/assets/images/gos.jpg";
      }
    }
  };

  const contributorGroup = (contribGroup) => {
    let allAuthor = contribGroup.join(",");
    return allAuthor;
  };

  const handleStatusBgCard = (status) => {
    if (status === PLATFORM_CONSTANTS.ARTICLE_STATUS.Included) {
      return "#7ddac0";
    } else if (status === PLATFORM_CONSTANTS.ARTICLE_STATUS.Rejected) {
      return "#c3c3c3";
    } else if (status === PLATFORM_CONSTANTS.ARTICLE_STATUS.Generated) {
      return "goldenrod";
    } else {
      return "white";
    }
  };

  const enableOperation = (status) => {
    if (status === 3) {
      return true;
    } else {
      return false;
    }
  };

  const checkSelectedPlatformOverlay = (currentDoi, platform) => {
    const array = [...allArticles]; //make copy of all article

    let foundIndex = array.findIndex(
      (element) => element.currentDoi == currentDoi
    );

    array[foundIndex].blurEffect = !array[foundIndex].blurEffect;

    console.log("final array is : ", array);

    handleOverlaySelection(platform, array);
  };

  return (
    <div class="row">
      <div class="col-12">
        {currentArticles.map((item, key) => (
          <div
            className={`card card__custom mg-b-20 mg-b-md-30`}
            key={key}
            style={{ backgroundColor: handleStatusBgCard(item.status) }}
          >
            {item.status === 2 && item.blurEffect ? (
              <div class="cards-overlay2">
                <h4 class="semi-bold">{item.articleMeta.title}</h4>
                {loadStatus ? (
                  ""
                ) : (
                  <button
                    type="button"
                    class="btn btn-sm btn-primary rounded"
                    onClick={() =>
                      checkSelectedPlatformOverlay(
                        item.currentDoi,
                        item.platform
                      )
                    }
                  >
                    View
                  </button>
                )}
                <span class="badge bg-danger labels-texts">Rejected</span>
              </div>
            ) : (
              <div>
                <div className="card-body">
                  <div className="card__content">
                    <div className="row">
                      <div className="left__col">
                        <input
                          type="checkbox"
                          name="article"
                          class="tick-checkbox"
                          checked={item.selected}
                          value={multiselectarr[key]}
                          onChange={(e) =>
                            handleArticleSelection(
                              item.currentDoi,
                              item.platform
                            )
                          }
                          disabled={enableOperation(item.status)}
                        />

                        <div className="card__imgbox mg-b-15">
                          <img
                            alt="Platform"
                            src={platformImg(item.platform)}
                          />
                        </div>
                        <a
                          href={`${item.platform}/articles/${item.articleMeta.volume}-${item.articleMeta.elocation_id}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="btn btn-primary w-100 rounded d-none d-md-block"
                        >
                          More Info
                        </a>
                      </div>

                      <div className="right__col">
                        <h4 className="card-title semi-bold mg-b-0">
                          {item.articleMeta.title} {item.articleMeta.pubStatus}
                        </h4>
                        <h5 className="author-name fontSize-18">
                          {contributorGroup(item.articleMeta.contribGroup)}
                        </h5>
                        <ul className="author-details d-flex flex-wrap">
                          <li>
                            <span>
                              <strong>Duration:</strong> 1h
                            </span>
                          </li>
                          <li>
                            <span>
                              <strong>Ref.</strong> 195319
                            </span>
                          </li>
                          <li>
                            <span>
                              <strong>Location.</strong>{" "}
                              {item.journalMeta.publisherLoc}
                            </span>
                          </li>
                        </ul>
                        <p className="mg-t-10">
                          {item.articleMeta.introduction}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <p className="fontSize-16 gray-200">
                    <strong>Article Type:</strong>

                    <span className="badge badge-success">
                      {item.articleMeta.articleType}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleList;
