export const PLATFORM_CONSTANTS = {
  PLATFORM: {
    F1000Research: "https://f1000research.com",
    WOR: "https://wellcomeopenresearch.org",
    AAOSOR: "https://aasopenresearch.org",
    GOR: "https://gatesopenresearch.org",
  },
  ARTICLE_STATUS: {
    Included: 1,
    Rejected: 2,
    Generated: 3,
  },
  articleSortingOptions: [
    {
      label: "All",
      value: 0,
    },
    {
      label: "Included",
      value: 1,
    },
    {
      label: "Rejected",
      value: 2,
    },
    {
      label: "Generated",
      value: 3,
    },
  ],
};
