import React from "react";

const ArticleTypeModal = ({
  articleSearchStr,
  _handleOnChange,
  finalArticleTypeCont,
  handleresetSelectedArticleType,
  handleArticleTypes,
}) => {
  return (
    <div
      className="modal new__modal fade"
      id="articleTypeMultiSelectModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="articleTypeMultiSelectModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close closed__btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="search__modal">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  name="articleSearchStr"
                  placeholder="Search"
                  value={articleSearchStr}
                  onChange={_handleOnChange}
                />
                <span className="icon-search search-icon"></span>
              </div>
              <div className="search__list">
                <ul>{finalArticleTypeCont && finalArticleTypeCont}</ul>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-start">
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              aria-label="Close"
            >
              Save changes
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => {
                var resetArticleTypeSelectedData = handleresetSelectedArticleType();

                handleArticleTypes(resetArticleTypeSelectedData);
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleTypeModal;
