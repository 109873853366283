import React, { Component } from "react";
import axios from "axios";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import Select from "react-select";

import isEmpty from "../../validation/is-empty";
import Spinner from "../dashboard/spinners/Spinner";
import Loader from "../common/GlobalLoader";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Flashmessages from "../flashMessagesComp/flashMessages";

class AssignLicences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      councilname: null,
      allcouncilarrobjs: null,
      Licencegrouplist: null,
      PrevLicenceSelectedlist: null,
      FinalSelectedLicence: [],
      allgroupsarrobjs: null,
      onlygroups: null,
      groupoptions: null,
      groups: null,
      onlysubgroups: null,
      subgroups: null,
      multiselectarr: [],
      finalmultiselectarr: [],
      licenceSearchStr: "",
      role: "wceaadmin",
      files: [],
      errors: {},
      selectedOption: null,
      loading: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this._handleGetCouncils();
    this._handleGetLicences();
    /*var onlygroupsData =  this.state.allgroupsarrobjs.filter(function(grp) {
      return grp.parentId === null;
    });
    
    console.log(onlygroupsData)
    this.setState({onlygroups:onlygroupsData})*/
  }

  _handleGetCouncils = () => {
    this.setState({ loading: true });
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_ALL_COUNCILS}`)
      .then((res) => {
        this.setState({ allcouncilarrobjs: res.data, loading: false });
        this.handlePreSelectedCouncil();
      })
      .catch((err) => {
        this.setState({ loading: true });
        console.log("error is : " + err);
        //   alert("Something went wrong!!")
      });
  };

  _handleGetLicences = async () => {
    try {
      let response = await fetch(
        `https://courses.wcea.education/contentapi/bundles`
      );
      let responseJson = await response.json();

      let allLicences = responseJson;
      for (var i = 0; i < allLicences.length; i++) {
        //  allLicences[1]['selected'] = true; //for testing purpose
        allLicences[i]["selected"] = false; //add new key/value for checkbox purpose
      }
      console.log(allLicences);

      this.setState({
        Licencegrouplist: allLicences,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handlePreSelectedCouncil = () => {
    const { allcouncilarrobjs } = this.state;
    let id = this.props.match.params.id;

    var councilData = allcouncilarrobjs.filter(function(grp) {
      return grp.id == id;
    });
    let councilPreSelected = {
      label: councilData[0].name,
      value: councilData[0].id,
    };

    this.handlecouncilnameChange(councilPreSelected);
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async onSubmit(e) {
    e.preventDefault();
    const {
      councilname,
      Licencegrouplist,
      FinalSelectedLicence,
      subgroups,
    } = this.state;

    let subgroupId = subgroups.value;
    // get subgroup object by its id
    var Subgroup = this.state.allgroupsarrobjs.filter(function(grp) {
      return grp.id == subgroupId;
    });

    //get selected subgroup licences if exist
    let existLicences = Subgroup[0].licences;

    // console.log("existLicences", existLicences);

    // console.log("work on FinalSelectedLicence start!!");

    for (var i = 0; i < Licencegrouplist.length; i++) {
      if (existLicences.length > 0) {
        let currentLicence = Licencegrouplist[i].Code;

        let licenceexist = await existLicences.find(
          //checking selected licence is already exist or not
          (obj) => obj.licenceCode == currentLicence
        );

        if (licenceexist) {
          //existing licences
          if (Licencegrouplist[i].selected === true) {
            //if selected again
            //select only selected true licences

            let CreateLicenceData = {
              councilUserId: councilname.value,
              subgroupId: subgroups.value,
              licenceCode: Licencegrouplist[i].Code,
              removeLicense: false,
            };

            await FinalSelectedLicence.push(CreateLicenceData);
          } else {
            //if not selected again
            let CreateLicenceData = {
              councilUserId: councilname.value,
              subgroupId: subgroups.value,
              licenceCode: Licencegrouplist[i].Code,
              removeLicense: true,
            };

            await FinalSelectedLicence.push(CreateLicenceData);
          }
        } else {
          if (Licencegrouplist[i].selected === true) {
            //selected licence is not your last licences

            console.log(
              "add new licence to existing licences array!!",
              currentLicence
            );

            //if selected again
            //select only selected true licences

            let CreateLicenceData = {
              councilUserId: councilname.value,
              subgroupId: subgroups.value,
              licenceCode: Licencegrouplist[i].Code,
              removeLicense: false,
            };

            await FinalSelectedLicence.push(CreateLicenceData);
          }
        }
      } else {
        console.log("No Existing Licences of current subgroup !!");

        if (Licencegrouplist[i].selected === true) {
          console.log("Fresh Licences Assignment !!");

          //if selected again
          //select only selected true licences

          let CreateLicenceData = {
            councilUserId: councilname.value,
            subgroupId: subgroups.value,
            licenceCode: Licencegrouplist[i].Code,
            removeLicense: false,
          };

          await FinalSelectedLicence.push(CreateLicenceData);
        }
      }
    }

    // console.log("work on FinalSelectedLicence end!!");
    // console.log("final Data is below : ");

    // console.log(FinalSelectedLicence);

    let isValidcouncilname = isEmpty(councilname); //if it return true its mean object is empty in case of false not empty
    //console.log("councilname validate value is : " + isValidcouncilname);

    let isValidsubgroups = isEmpty(subgroups); //if it return true its mean object is empty in case of false not empty
    //console.log("subgroups validate value is : " + isValidsubgroups);

    if (isValidcouncilname === true) {
      this.props.sendFlashMessage(
        "Please Select Organisation Name !!",
        "alert-danger"
      );
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);

      // alert("Please Select Organisation Name !!")
    } else if (isValidsubgroups === true) {
      this.props.sendFlashMessage("Please Select Subgroups !!", "alert-danger");
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);

      //alert("Please Select Subgroups !!")
    } else if (FinalSelectedLicence.length <= 0) {
      this.props.sendFlashMessage(
        "Please Select atleast one licence !!",
        "alert-danger"
      );
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);

      //alert("Please Select atleast one licence !!")
    }

    if (
      !isValidcouncilname &&
      !isValidsubgroups &&
      FinalSelectedLicence.length > 0
    ) {
      console.log("all validation Success");

      this.setState({ loading: true });
      axios
        .post(
          `${API_CONSTANTS.COUNCIL.ASSIGN_LICENCE}/${councilname.value}/groups/${subgroups.value}/licence`,
          FinalSelectedLicence
        )
        .then(async (res) => {
          // var resetLicenceSelectedData = this.handleresetSelectedLicences();

          // this.setState({ Licencegrouplist: resetLicenceSelectedData });

          let resdata = await this._handleGetAllGroups(councilname);

          if (resdata) {
            this.setState({ allgroupsarrobjs: resdata.subgroups });

            this.props.sendFlashMessage(
              "Organisation Licence Assigned successfully !!",
              "alert-success"
            );
            setTimeout(() => {
              this.props.clearcurrentFlashMessage();
            }, 2000);

            this.setState({
              loading: false,
              FinalSelectedLicence: [],
              licenceSearchStr: "",
            });
          }
        })
        .catch((err) => {
          this.setState({ loading: false, FinalSelectedLicence: [] });

          console.log("error is : " + err);
          this.props.sendFlashMessage(
            "Something went wrong !!",
            "alert-danger"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);

          //  alert("Something went wrong!!")
        });
    }
  }

  _checkSelectedAssignLicences = (checkselected) => {
    if (checkselected === true) {
      // console.log(checkselected)
      // console.log("licence code already selected : "+data.Code)
      return true;
    } else {
      // console.log(checkselected)
      // console.log("licence code is not selected : "+data.Code)
      return false;
    }

    // const {finalmultiselectarr} =this.state
    // for(var i=0;i<finalmultiselectarr.length;i++){
    //   if(finalmultiselectarr[i]._id===data._id&&finalmultiselectarr[i].Code===data.Code){

    //  // if(finalmultiselectarr&&finalmultiselectarr[i].Code===data.Code){
    //     console.log("licence code already selected : "+data.Code)
    //    return true
    //   }else{
    //     console.log("licence code is not selected : "+data.Code)
    //     return false
    //   }
    // }
  };

  // _handlemultiselectgroup=(data)=>{

  //   const {finalmultiselectarr} =this.state

  //   //here we make multiselected array of user id

  //   //first we check inside array if user id is found then we remove from the array
  //   //otherwise we will push the user id in array
  //   console.log("selected licence data id is : "+data.id)
  //   //here we first check inside array of object if in any object
  // //there id found so we will splice object otherwise we insert new object with id and name
  //   let idfound=true
  //   for(var i=0;i<finalmultiselectarr.length;i++){
  //     if(finalmultiselectarr[i]._id===data._id&&finalmultiselectarr[i].Code===data.Code){
  //       console.log("id found inside array of object")

  //       finalmultiselectarr.splice(i,1)

  //       idfound=false
  //     }
  //   }

  //   if(idfound){
  //   console.log("new entry")

  //   let newentry={
  //     _id:data._id,
  //     Code:data.Code,
  //     Package:data.Package
  //   }
  //   finalmultiselectarr.unshift(newentry)

  //   }

  // }

  // Callback~

  getFiles(file) {
    this.setState({ councillogo: file });
  }

  handlecouncilnameChange = async (councilname) => {
    const { Licencegrouplist, groups } = this.state;

    //this.setState({ groups: null, subgroups: null, onlysubgroups: null });
    this.setState({ councilname });
    // console.log(`Option selected:`, councilname);

    //// for every group selection we reset licences where selected set to false ///
    var resetLicenceSelectedData = this.handleresetSelectedLicences();

    this.setState({ Licencegrouplist: resetLicenceSelectedData });

    let resdata = await this._handleGetAllGroups(councilname);

    if (resdata) {
      this.setState({ allgroupsarrobjs: resdata.subgroups });

      var onlygroupsData = this.state.allgroupsarrobjs.filter(function(grp) {
        return grp.parentId === null;
      });

      // console.log(onlygroupsData);
      this.setState({ onlygroups: onlygroupsData });

      // this.setState({ groups: null, subgroups: null, onlysubgroups: null });
      //if (groups === null) this.handlegroupsChange(onlygroupsData[0]);
    }
  };

  _handleGetAllGroups = async (councilname) => {
    try {
      let response = await axios(
        `${API_CONSTANTS.COUNCIL.GET_GROUPS}/${councilname.value}/groups/sub`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  handleresetSelectedLicences = () => {
    const { Licencegrouplist } = this.state;
    //here we selected to false for every licences
    const prevarrayreset = Licencegrouplist.slice(); //make copy of all current licences

    for (var rl = 0; rl < prevarrayreset.length; rl++) {
      prevarrayreset[rl]["selected"] = false; //reset selected to false for each licences
    }
    return prevarrayreset;
  };

  handlesubgroupsChange = (subgroups) => {
    const { Licencegrouplist, PrevLicenceSelectedlist } = this.state;

    var resetLicenceSelectedData = this.handleresetSelectedLicences();

    // const prevarrayreset = Licencegrouplist.slice(); //make copy of all current licences

    // for(var rl=0;rl<prevarrayreset.length;rl++){
    //   prevarrayreset[rl]['selected'] = false; //add new key/value for checkbox purpose
    // }

    this.setState({ Licencegrouplist: resetLicenceSelectedData });

    this.setState({ subgroups });
    let subgroupId = subgroups.value;
    // console.log(`Option selected:`, subgroups);
    // console.log("subgroupId is : " + subgroupId);

    // get subgroup object by its id
    var Subgroup = this.state.allgroupsarrobjs.filter(function(grp) {
      return grp.id == subgroupId;
    });

    //get selected subgroup licences if exist
    let existLicences = Subgroup[0].licences;

    // console.log("exist licence are below :");
    // console.log(existLicences);

    const array = Licencegrouplist.slice(); //make copy of all current licences
    const processedLicenceCodes = new Set();

    existLicences.forEach(existLicence => {
        // Find the corresponding licence in allLicences array
        const correspondingLicence = array.find(licence => licence.Code == existLicence.licenceCode);
        if (!correspondingLicence && !processedLicenceCodes.has(existLicence.licenceCode)) {
              const newLicence = {
              _id: existLicence.id,
              Code: existLicence.licenceCode,
              Package: existLicence.licenceCode,
              Industry: "",
              Accredited: "",
              Credits: "",
              "Number of Courses": null,
              "Special Country": "",
              "Price ROW": "",
              "Price LMIC": "",
              Distribution: "",
              "Cover image": "",
              "Portal URL / Buy Now": "",
              "URL / Individual Courses": "",
              Description: "",
              Courses: []
          };
          array.push(newLicence);
        }
        processedLicenceCodes.add(existLicence.licenceCode);
    });


    for (var i = 0; i < existLicences.length; i++) {
      for (var j = 0; j < array.length; j++) {
        if (existLicences[i].licenceCode == array[j].Code) {
          array[j].selected = !array[j].selected; //if exist licence code is found in all licences theninvert selected key of targeted licence eg (false => true)
        }
      }
    }

    // console.log("final licences array is after subgroup selection below: ");
    // console.log(array);
    this.setState({ Licencegrouplist: array, PrevLicenceSelectedlist: array });
  };

  handleLicenceSelection = (e, id) => {
    const { Licencegrouplist } = this.state;

    //now we have updated all selected or non selected licences
    const array = Licencegrouplist.slice(); //make copy of all licences

    let foundIndex = array.findIndex((element) => element._id == id);

    array[foundIndex].selected = !array[foundIndex].selected; //change the targeted licences object key selected set (true/false) acc to prev value

    this.setState({ Licencegrouplist: array });
  };

  handlegroupsChange = (groups) => {
    const { Licencegrouplist } = this.state;

    this.setState({ groups });

    console.log(`Option selected:`, groups);

    //// for every group selection we reset licences where selected set to false ///
    var resetLicenceSelectedData = this.handleresetSelectedLicences();

    this.setState({ Licencegrouplist: resetLicenceSelectedData });

    ///////////////////////////////////////////////////////////////////////////////////

    let groupid = groups.value;
    var onlysubgroupsData = this.state.allgroupsarrobjs.filter(function(grp) {
      return grp.parentId == groupid;
    });

    console.log("id is : " + groupid);

    console.log("only subgroups is : ");
    console.log(onlysubgroupsData);
    this.setState({ onlysubgroups: onlysubgroupsData });

    this.setState({ subgroups: null });
    /*
      var onlysubgroupsData =  this.state.allgroupsarrobjs.filter(function(grp) {
        return grp.parentId !== null
      });


      this.setState({onlysubgroups:onlysubgroupsData})
  */
  };

  handleRemoveLicence = (e, id) => {
    e.preventDefault();

    this.handleLicenceSelection(e, id);
    this.onSubmit(e);
  };

  render() {
    const {
      councilname,
      Licencegrouplist,
      PrevLicenceSelectedlist,
      allcouncilarrobjs,
      groups,
      onlygroups,
      subgroups,
      onlysubgroups,
      licenceSearchStr,
      loading,
    } = this.state;

    let councilnameoptions;
    let allcouncilarrobjsloading;
    if (allcouncilarrobjs === null) {
      allcouncilarrobjsloading = "";
    } else {
      if (allcouncilarrobjs && Object.keys(allcouncilarrobjs).length > 0) {
        councilnameoptions = allcouncilarrobjs.map((w) => ({
          label: w.name,
          value: w.id,
        }));
      }
    }

    let actualonlygroups;
    let allgroupsarrobjsloading;
    if (onlygroups === null) {
      allgroupsarrobjsloading = "";
    } else {
      if (onlygroups && Object.keys(onlygroups).length > 0) {
        //TotalonlygroupsItem = Object.keys(onlygroups).length;
        // Select options for onlygroups
        actualonlygroups = onlygroups.map((w) => ({
          label: w.name,
          value: w.id,
        }));
      }
    }

    let actualallsubgroupopt;
    let allonlysubgroupsloading;
    if (onlysubgroups === null) {
      allonlysubgroupsloading = "";
    } else {
      if (onlysubgroups && Object.keys(onlysubgroups).length > 0) {
        //TotalallgroupsarrobjsItem = Object.keys(allgroupsarrobjs).length;
        // Select options for allgroupsarrobjs
        actualallsubgroupopt = onlysubgroups.map((w) => ({
          label: w.name,
          value: w.id,
        }));
      }
    }

    let finalLicencegroupCont;

    let filteredLicence = Licencegrouplist && Licencegrouplist;
    licenceSearchStr &&
      (filteredLicence = filteredLicence.filter(
        (data) =>
          data.Package &&
          data.Package.toString().startsWith(licenceSearchStr.toString())
      ));

    if (Licencegrouplist === null) {
      finalLicencegroupCont = <Spinner />;
    } else {
      let LicencegroupData = filteredLicence.map((data, index) => {
        return (
          <li key={index}>
            <label>
              {data.Package}
              <span className="custom-checkbox">
                <input
                  type="checkbox"
                  name="group2"
                  checked={this._checkSelectedAssignLicences(data.selected)}
                  value={this.state.multiselectarr[index]}
                  onChange={(e) => this.handleLicenceSelection(e, data._id)}
                />
                <span className="checkbox-check"></span>
              </span>
            </label>
          </li>
        );
      });

      finalLicencegroupCont = LicencegroupData;
    }

    let PrevLicenceSelectedlistCont;
    if (PrevLicenceSelectedlist === null) {
      PrevLicenceSelectedlistCont = <Spinner />;
    } else {
      let PrevLicenceSelectedData = PrevLicenceSelectedlist.map(
        (data, index) => {
          if (data.selected) {
            return (
              <span className="text__licence" key={index}>
                {data.Package}{" "}
                <a
                  href="javascript:void(0)"
                  className="icon-cross clos-icon"
                  onClick={(e) => this.handleRemoveLicence(e, data._id)}
                ></a>{" "}
              </span>
            );
          }
        }
      );

      PrevLicenceSelectedlistCont = PrevLicenceSelectedData;
    }

    /**
     * The options array should contain objects.
     * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
     */

    return (
      <section class="wrapper">
        <Header />
        {/*<Sidebar activeStatus={"assignLicence"} />*/}
        <section class="">
          <Flashmessages />
          <div class="container-fluid">
            <div class="spacetb25">
              <nav class="breadcrumb__list">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    {" "}
                    <Link to="/organisation">Organisation</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Assign Licences
                  </li>
                </ol>
              </nav>

              <div class="row">
                <div class="col-xl-8 offset-xl-2">
                  <div class="card card__custom">
                    <div class="card-header">
                      <h5 class="card-title">
                        {councilname && councilname.label
                          ? councilname.label
                          : ""}
                      </h5>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        {/*} <div class="col-sm-6">
                          <div class="form-group textbox__label">
                            <label>Select Council Name</label>
                            {allcouncilarrobjsloading ? (
                              <p>Organisation Loading...</p>
                            ) : (
                              <Select
                                value={councilname}
                                onChange={this.handlecouncilnameChange}
                                options={councilnameoptions}
                              />
                            )}
                          </div>
                            </div>*/}
                        <div class="col-sm-6">
                          <div class="form-group textbox__label">
                            <label>Group</label>
                            {councilname &&
                            onlygroups &&
                            Object.keys(onlygroups).length > 0 ? (
                              allgroupsarrobjsloading ? (
                                <p>Groups Loading...</p>
                              ) : (
                                <Select
                                  value={groups}
                                  onChange={this.handlegroupsChange}
                                  options={actualonlygroups}
                                />
                              )
                            ) : (
                              <div>No Data</div>
                            )}
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="form-group textbox__label">
                            <label>Select Subgroups</label>
                            {councilname &&
                            onlygroups &&
                            Object.keys(onlygroups).length > 0 ? (
                              allonlysubgroupsloading ? (
                                <p>Subgroup Loading...</p>
                              ) : (
                                <Select
                                  value={subgroups}
                                  onChange={this.handlesubgroupsChange}
                                  options={actualallsubgroupopt}
                                />
                              )
                            ) : (
                              <div>No Data</div>
                            )}
                          </div>
                        </div>
                      </div>
                      {councilname && subgroups && (
                        <div class="mg-t-10">
                          <h5 class="mg-b-10">
                            <strong>Select Licence</strong>
                          </h5>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <a
                                  href="!#"
                                  class="add-licence-select"
                                  data-toggle="modal"
                                  data-target="#exampleModal"
                                >
                                  Search and add licence
                                  <span class="icon-plus addicons"></span>
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="select__licence">
                            {PrevLicenceSelectedlistCont &&
                              PrevLicenceSelectedlistCont}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal new__modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close closed__btn"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="search__modal">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control"
                      name="licenceSearchStr"
                      placeholder="Search"
                      value={licenceSearchStr}
                      onChange={this.onChange}
                    />
                    <span class="icon-search search-icon"></span>
                  </div>
                  <div className="search__list">
                    <ul>{finalLicencegroupCont && finalLicencegroupCont}</ul>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-start">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.onSubmit}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Save changes
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    var resetLicenceSelectedData = this.handleresetSelectedLicences();

                    this.setState({
                      Licencegrouplist: resetLicenceSelectedData,
                    });
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        <Loader loadStatus={loading} />
      </section>
    );
  }
}

AssignLicences.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors, //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(AssignLicences));
